import { Box, Button, InputBase, Skeleton, Stack, Typography } from "@mui/material"
import AdminSidebar from "./adminSideBar"
import Header1 from "./header1"
import MessageBar from "../modules/messageBar"
import { formStyle } from "../modules/profile";
import { useState } from "react";
import { valid } from "semver";
import CustomAxios from "../utils/CustomAxios";
import { useDispatch } from "react-redux";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import { TranslateText } from "../utils/googletranslator";
const CampaingMsgBlast = ()=>{
    const dispatch = useDispatch()
    const [Inputvalues,setInputValues] = useState({
        templateName:"",
        media_link:""
    })
    const [errors,setErrors] = useState({})
    const [isLoading,setIsloading] = useState(false)
    const validateform = ()=>{
        let newError = {}
        let isvalid = true
        if(!Inputvalues.templateName || (Inputvalues.templateName && (Inputvalues.templateName.length >512 ))){
            newError['templateName'] = "Please enter valid template name"
            isvalid = false
        }
        if(Inputvalues.media_link && (!Inputvalues.media_link.startsWith("https://"))) {
            newError['media_link'] = "Please enter valid link"
            isvalid = false
        }
        else{
            if(Inputvalues.media_link){
                fetch(Inputvalues.media_link).then((res)=>{
                    if(!res.ok){
                        newError['media_link'] = "Link is broken or not accessible."
                        isvalid = false
                    }
                })
            }
        }
        setErrors(newError)
        return isvalid
    }
    const handlesubmit = (e)=>{
        e.preventDefault()
        let no_error = validateform()
        if(no_error){
            setIsloading(true)
            let payload = {
                "templateName":Inputvalues.templateName,
            }
            if(Inputvalues.media_link){
                payload['media_link'] = Inputvalues.media_link
            }
            CustomAxios.post("blastCampaignMsg/",payload).then((res)=>{
                let data = res.data
                if(data.error){
                    dispatch(updateSnackBarMessage(data.error))
                    dispatch(updateSnackBarSeverity('error'))
                }
                else{
                    dispatch(updateSnackBarMessage(data))
                    dispatch(updateSnackBarSeverity('success'))
                    setErrors({})
                    setInputValues({"templateName":"","media_link":""})
                }
                dispatch(updateSnackBarOpen(true))
                setIsloading(false)
            })
        }

        
    }

    return(
        <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 0px)" }}>
            <MessageBar/>
            <Header1/>
            <Box sx={{ display: "flex", paddingTop: "144px", mx:"24px", gap:"24px" }}>
                <AdminSidebar/>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: '24px', background: "white", gap: "30px"}}>
                    <Stack sx={{textAlign:"left",width:"100%"}}>
                        <TranslateText sx={{ fontSize: "20px",lineHeight:"28px",fontWeight:700 }}>
                            Message Blast
                        </TranslateText>
                    </Stack>
                    <Stack sx={{width:"100%"}}>
                        <TranslateText sx={formStyle.label} color="grey.700" >Template Name <span component="span" color="error">*</span></TranslateText>
                        <InputBase sx={formStyle.inputBase} value={Inputvalues.templateName} onChange={(e)=>setInputValues({...Inputvalues,templateName:e.target.value.replace(/\s+/g, '')})} />
                        {errors.templateName && <TranslateText sx={{color:"error.light"}}>{errors.templateName}</TranslateText>}
                    </Stack>
                    <Stack sx={{width:"100%"}}>
                        <TranslateText sx={formStyle.label} color="grey.700" >Media Link </TranslateText>
                        <InputBase sx={formStyle.inputBase} value={Inputvalues.media_link} onChange={(e)=>setInputValues({...Inputvalues,media_link:e.target.value})} />
                        {errors.media_link && <TranslateText sx={{color:"error.light"}}>{errors.media_link}</TranslateText>}
                    </Stack>
                    <Stack sx={{width:"100%",alignItems:"flex-end"}}>
                        {
                            isLoading?
                            <Skeleton sx={{height:"64px"}}>
                                <Button variant="contained" sx={{textTransform:"capitalize"}} >Blast</Button>
                            </Skeleton>
                            :<Button variant="contained" sx={{textTransform:"capitalize"}} onClick={(e)=>{handlesubmit(e)}} >Blast</Button>
                        }
                    </Stack>
                    
                </Box>
            </Box>

        </Box>
    )
}


export default CampaingMsgBlast