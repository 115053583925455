import { styles } from "./product_list"
import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { TranslateText } from "../utils/googletranslator"
import { useEffect, useState } from "react"
import CustomAxios from "../utils/CustomAxios"
import { updateBPIntialState } from "../redux/bundleProduct"
import { updateSnackBarMessage, updateSnackBarSeverity, updateSnackBarOpen } from "../redux/snackbar";
import { updateCart } from "../redux/products"
import { CurrencyRupee } from "@mui/icons-material"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import IndianNumFormat from "./numberFormat"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import { updateCartSideBar } from "../redux/active_bar"
import { CalculateProductPrice } from "../utils/price"


const BundleProductsListUser = ({searchText})=>{

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const bundles = useSelector(state=>state.bundles.bundles)
    const cart = useSelector(state => state.product?.cart)
    const id = useSelector(state => state.user.id)
    const member=useSelector(state=>state.user.member)
    const authtoken = localStorage.getItem("authtoken")
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const is320px = useMediaQuery({ maxWidth: 320 });
    const is1650px = useMediaQuery({ minWidth: 1650 });
    const is2560px = useMediaQuery({ minWidth: 2560 });
    const [filteredBundles,setFilteredBundles] = useState();
    const [bundleTag,setBundleTag] = useState({})
    const [cartBundles, setCartBundles] = useState({});
    const [isLoading,setIsLoading] = useState(false)

    const getBundles = ()=>{
        CustomAxios.get('bundles/').then((response)=>{
            let res = response.data
            setFilteredBundles(res)
            dispatch(updateBPIntialState({key:"bundles",value:res}))
        }).catch((err)=>{

        })
    }

    const cartCheck = (p_obj) => {
        // // console.log(id)
        // // console.log(cart)
        let id = p_obj?.product?.id
        let p_qty = p_obj?.product_quantity
        // cart.filter((item) => {console.log(item.product?.id , id , item.quantity , p_qty)})
        const filt = cart.filter(item => item && item.product?.id == id && item.quantity >= p_qty)
        // console.log("---------filt-----",filt,id)
        // filt[0].quantity
        if (filt.length > 0) {
            return filt[0].quantity

        }
        else {
            return false
        }

    }

    const cartCheckProducts = (products)=>{
        // console.log("---------start check------",products?.length)
        let check = products?.map((p_obj)=>{return cartCheck(p_obj)})
        // console.log("--------before set check --------",check)
        check = Math.min(...check)
        // console.log("--------after set check --------",check)
        // console.log("---------return check------")
        return check
    }
    
    const addBundleCart = async(bundle, qty, e)=>{
        e.stopPropagation();
        let products = bundle.products
        let bundleCheck = CheckBundleStock(bundle,true)
        if(bundleCheck?.length === 0){

            let cart_items = await Promise.all(products?.map(async(p_obj)=>{
                // console.log("------adding to cart----",p_obj.product,qty,e)
                let item = p_obj.product
                let itemCCheck = cartCheck(p_obj)
                // console.log("-----itemCCheck------",item,itemCCheck,typeof itemCCheck === "boolean",item && typeof itemCCheck === "boolean")
                if(item && typeof itemCCheck === "boolean"){
                    return {
                        product: item,
                        quantity: qty,
                        price: authtoken&&member=='member' ? item.selling_price : item.price, // Add price or selling price based on auth token
                        originalPrice: item.price // Store original price as well
                    }
                }
                else if(item && typeof itemCCheck === "number"){
                    // await updateQuantity(bundle,true,e)
                    let dummy_cart = []
                    if(cart?.length>=1){
                        dummy_cart = [...cart]
                    }
                    else{

                        dummy_cart = JSON.parse(localStorage.getItem('cart')) || []
                    }
                    let index;
                    let modified_cart = dummy_cart?.map((e,e_id)=>{
                        let obj = {...e}
                        let p_id = obj.products || obj.product?.id
                        if( p_id === p_obj?.product_id){
                            index = e_id
                            obj.quantity = obj.quantity + 1
                        }
                        return obj
                    })
                    
                    // let index = dummy_cart.findIndex(e=>e.products === p_obj?.product_id)
                    // console.log("-------------",modified_cart[index])
                    // dummy_cart[index].quantity = dummy_cart[index].quantity + 1 
                    // console.log("---------- else if ------------",p_obj,cart,dummy_cart,index)
                    return modified_cart[index]
                }
            }))
            let dummyCB = {...cartBundles}
            dummyCB[bundle.id] = {qty:qty}
            setCartBundles(dummyCB)

            cart_items = cart_items?.filter(e=>e)

            let updated_cart = new Map()

            let old_cart_items = cart ? [...cart] : JSON.parse(localStorage.getItem('cart'))

            old_cart_items.map((item)=>updated_cart.set(item?.product?.id,item))

            cart_items.forEach((item)=>{
                if(updated_cart.has(item?.product?.id)){
                    const ex_obj = {...updated_cart.get(item?.product?.id)}
                    ex_obj.quantity = item.quantity
                    updated_cart.set(item?.product?.id,ex_obj)
                }
                else{
                    updated_cart.set(item?.product?.id,item)
                }
            })

            updated_cart = Array.from(updated_cart.values())

            dispatch(updateCart(updated_cart))
            // let dummy_loading = {...isLoading}
            // dummy_loading[bundle.id] = true
            setIsLoading(true)
            localStorage.setItem("cart", JSON.stringify(updated_cart))
            // console.log("------------1.2----------",updated_cart)
            if (authtoken) {
                await handleCart(updated_cart)
                await FetchUserCart()
                
            }
            // dummy_loading[bundle.id] = false
            setIsLoading(false)
            dispatch(updateSnackBarMessage(`Successfully Added ${bundle?.name || ""} to Cart!`))
            dispatch(updateSnackBarSeverity("success"))

            dispatch(updateSnackBarOpen(true))
            dispatch(updateCartSideBar(true))
        }
        else{
            dispatch(updateSnackBarMessage(`Just ${bundleCheck[0].qty} of ${bundle.name} bundle(s) left! We'll have more in stock for you very soon.`))
            dispatch(updateSnackBarSeverity("error"))
            dispatch(updateSnackBarOpen(true))
        }

    }

    const updateQuantity = async (bundle, condition, event) => {
        // console.log(cart,condition)
        event.stopPropagation();
        let b_products = bundle.products
        let bundle_id = bundle.id
        let productUpdate=false
        let products = [...cart]
        let newCart = []
        let dispatch_cart = []
        let bundleCheck = CheckBundleStock(bundle,condition)
        let dummyCB = {...cartBundles}
        if(bundleCheck?.length === 0){

            for(const[b_index,p_obj] of b_products.entries()){
    
                let object = p_obj.product
                // console.log(object,condition)
                for(const [index,item] of products.entries()){
                    // console.log("--------index-----",index)
                    // console.log(condition ,item, item.product?.id , object.id , item.product.inventry_details?.available_quantity>item.quantity)
                    if (condition && item.product?.id == object.id && item.product.inventry_details?.available_quantity>item.quantity) {
                        let updated = { ...item }
                        productUpdate=true
                        updated.quantity += 1
                        // console.log("if condition")
                        if (item.id) {
                            await CustomAxios.patch(`cart/${item.id}/`, {
                                quantity: updated.quantity,
                                totalprice: updated.quantity * (parseFloat(updated.price) + Math.round(updated?.price * (updated.product?.tax / 100)))
                            })
                            // console.log("--------in if cond-------",item.id)
                            newCart.push(updated)
                            // return updated
                        }
                        else {
                            newCart.push(updated)
                            // return updated
        
                        }
        
                    }
                    else if (!condition && item.product?.id == object.id ) {
                        // console.log("?????????????,negative conditions",!condition)
                        productUpdate=true
                        let updated = { ...item }
                        updated.quantity -= 1
        
                        if (item.id && updated.quantity >= 1) {
                            await CustomAxios.patch(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/${item.id}/`, {
                                quantity: updated.quantity,
                                totalprice: updated.quantity * (parseFloat(updated.price) + Math.round(updated?.price * (updated.product?.tax / 100)))
                            })
                            newCart.push(updated)
                            // return updated
                        }
                        else if (item.id && updated.quantity < 1) {
                            await CustomAxios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/${item.id}/`)
                            newCart.push(updated)
                            // return updated
                        }
                        else {
                            newCart.push(updated)
                            // return updated
        
                        }
        
        
                    }
                    else if (item.product?.id == object.id){
                        // console.log("------else if 2nd---------")
                        // console.log( item.product.inventry_details?.available_quantity, item.quantity)
                        if(condition && item.product.inventry_details?.available_quantity<=item.quantity) {
                            productUpdate=false
                        } 
                        else{
                            productUpdate=true
                        }
                        newCart.push(item)
                        // return item
                       
        
                    }
                    else{
                        newCart.push(item)
                        // return item
                    }
                    
                }
                // console.log("----------new cart----------",newCart)
                products = [...newCart]
                if (productUpdate){
                    dispatch_cart = newCart.filter((item) => !item?.quantity < 1)
                    // dispatch(updateCart(newCart.filter((item) => !item?.quantity < 1)))
                    // localStorage.setItem("cart", JSON.stringify(newCart.filter((item) => !item?.quantity < 1)))
                    // console.log("clipboard open")
                    dispatch(updateSnackBarMessage("Successfully Updated the Cart!"))
                    dispatch(updateSnackBarSeverity("success"))
            
                    dispatch(updateSnackBarOpen(true))
                }
                else{
                    dispatch_cart = newCart.filter((item) => !item?.quantity < 1)
                    // dispatch(updateCart(newCart.filter((item) => !item?.quantity < 1)))
                    // localStorage.setItem("cart", JSON.stringify(newCart.filter((item) => !item?.quantity < 1)))
                    // console.log("clipboard open")
                    dispatch(updateSnackBarMessage(`You are exceeding the available stock!. You can add only ${object.inventry_details?.available_quantity} quantity to your cart.`))
                    dispatch(updateSnackBarSeverity("error"))
            
                    dispatch(updateSnackBarOpen(true))
                }
                newCart = []
            }

            if(condition){

                dummyCB[bundle_id].qty = dummyCB[bundle_id].qty + 1
            }
            else{
                if(dummyCB[bundle_id].qty === 1){
                    delete dummyCB[bundle_id]
                }
                else{

                    dummyCB[bundle_id].qty = dummyCB[bundle_id].qty - 1
                }
            }
            setCartBundles(dummyCB)
    
            dispatch(updateCart(dispatch_cart))
            localStorage.setItem("cart", JSON.stringify(dispatch_cart))
            // console.log(newCart)
            // setProduct(products)
        }
        else{
            dispatch(updateSnackBarMessage(`Just ${bundleCheck[0].qty} of ${bundle.name} bundle(s) left! We'll have more in stock for you very soon.`))
            dispatch(updateSnackBarSeverity("error"))
            dispatch(updateSnackBarOpen(true))
        }
        

    }

    const FetchUserCart = async()=>{
        const response = await CustomAxios.get(`usercart/${id}`,)
        dispatch(updateCart(response.data))
        localStorage.setItem("cart", JSON.stringify(response.data))
    }

    const handleCart =  async(cart_items) => {
        await Promise.all(cart_items?.map(async(p_item)=>{
            let item =p_item.product 
            let quantity = p_item.quantity
            if(!p_item?.id){

                await CustomAxios.post(`cart/`, {
                    products: item.id,
                    quantity: quantity,
                    user: id, created_by: id,
                    price:member=='member'? item.selling_price:item.price,
                    totalprice: quantity * (parseFloat(member=='member'? item.selling_price:item.price) + Math.round(member=='member'? item.selling_price:item.price * (item.tax / 100))),
                    updated_by: id
                })
            }
            else{

                await CustomAxios.patch(`cart/${p_item.id}/`, {
                    quantity: p_item.quantity,
                    totalprice: p_item.quantity * (parseFloat(p_item.price) + Math.round(p_item?.price * (p_item.product?.tax / 100)))
                })
                
            }

            // console.log("-----cart api completed------",item.id)
        }))
        // console.log("Handle Cart Exit----------")
        


    }

    const CheckBundleStock = (bundle,condition)=>{
        let b_products = bundle.products
        let isOOS = []
        let dummy_cart = []
        if(cart?.length >=1){
            dummy_cart = [...cart]
        }
        else{
            try {
                dummy_cart = JSON.parse(localStorage.getItem('cart'))
            } catch (error) {
                dummy_cart = []
            }
        }
        if(dummy_cart?.length>=1){
            isOOS = b_products?.map((e)=>{
                let cart_qty = dummy_cart.filter((c_e)=>c_e.product?.id === e?.product?.id)?.[0]?.quantity || 0
                // console.log("--------------cart_qty--------",cart_qty,condition)
                if(condition){
                    cart_qty = cart_qty + 1
                }
                else{
                    cart_qty = cart_qty - 1
                }
                // console.log("--------------cart_qty--------",cart_qty)
                return cart_qty <= e.product?.inventry_details?.available_quantity ?{"qty":cart_qty - 1,"isOOS":false} :{"qty":cart_qty - 1,"isOOS":true}
            })
        }
        // console.log("-----------isOOS-------",isOOS,isOOS.filter(e=>e.isOOS))
        return isOOS.filter(e=>e.isOOS)
    }

    useEffect(()=>{
        getBundles()
    },[])

    useEffect(()=>{
        if(searchText && searchText?.trim()){
            let f_b = bundles?.filter((e)=> e.status && e?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) )
            setFilteredBundles(f_b)
        }
        else{
            setFilteredBundles(bundles)
        }
    },[searchText])

    useEffect(() => {
        let dummy_description = {}
        bundles?.map((bundle)=>{

            const htmlString = bundle?.description
    
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');
        
            // Extract data
            const paragraphs = Array.from(doc.querySelectorAll('p'));
        
            const contentDetails = paragraphs[0]?.textContent.trim() || '';
            const category = paragraphs[1]?.textContent.replace('Category:', '').trim() || '';
    
            let tag_strong_regex = "<strong[^>]*>Tag(?:.*?)</strong>"
            const tagEle = paragraphs?.find(p => p?.innerHTML?.match(tag_strong_regex));
            const tag = tagEle?.innerText?.split("Tag:")?.[1]?.trim() || '';

            

            dummy_description[bundle.id] = tag
        })
        setBundleTag(dummy_description)
    },[bundles])


    const calculateTotalPrice = (products)=>{
        let price = products?.reduce((acc,cv)=>{
            // let p_price = parseFloat(cv?.product?.price)+( parseFloat(cv?.product?.price) * parseFloat(cv?.product?.tax/100) )
            let p_price = CalculateProductPrice({authtoken : authtoken,member:"nonmember",item:cv})
            return acc + parseFloat(p_price)
        },0)
        // console.log("----------products----------",products,price)
        return price ? price.toFixed(2) : 0
    }

    return(
        <Stack sx={{ width:"100%"}}>
            {/* <Typography sx={{fontFamily:"Poppins",fontWeight:600,fontSize:"24px",lineHeight:"36px",color:"#000000"}}>Nurture Your Mind with Mayi IQ Ayurvedic Bundle</Typography> */}
            <Grid container columns={12}  sx={{justifyContent:"flex-start"}}>
                {
                    filteredBundles && filteredBundles?.map((bundle)=>(
                        bundle.status && <Grid item key={bundle.slug_name} xs={12} sm={6} lg={is2560px?3:is1650px?3:4}  sx={{ alignSelf: "stretch",display:"flex",justifyContent:"space-between",p:{xs:"12px 6px",sm:"12px 16px",lg:"16px 32px"}}}>

                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width:"calc(100%-16px)", gap: "24px",p:{xs:"16px",lg:is2560px?"32px":"16px"},border: "1.2px solid #000000",background:"#FFFFFF",borderRadius:"8px",boxShadow:"3px 2px 4px #0005" }}>
                                
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center", background: '', cursor:"pointer"}} onClick={()=>{dispatch(updateBPIntialState({key:"bundle",value:bundle}));navigate(`/bundle_details/${bundle.id}`)}}>
                                        {
                                            bundle.cover_details?.original ? 
                                            <Stack sx={{ position:"relative", width: "inherit" }}>
                                                <Box component="img" sx={{ width:"inherit",height:is320px?"auto":"inherit" }} src={bundle.cover_details?.original} />
                                                <Stack sx={{position:"absolute",left:"-0.7%",top:"15px",width:"86px",height:"25px",borderTopRightRadius:"8px",borderBottomRightRadius:"8px",p:"4px 8px",border:"1px solid #FFFFFF",borderBottom:"0px",background:"linear-gradient(90deg, #0040E3 13.1%, #00FF80 100%)","boxShadow": "0px 2px 2px 0px #00000026"}}>
                                                    <TranslateText sx={{fontWeight:700,fontSize:"12px",color:"#FFFFFF"}}>Bundle</TranslateText>
                                                </Stack>
                                            </Stack>
                                            : 
                                            <Grid container columns={12} spacing={'16px'} sx={{position:"relative",justifyContent:"center",alignContent:"center",border: {xs:"none",md:"1px solid #D6DAE2"},width:"100%",m:0}}>
                                                {
                                                    bundle?.products?.map((p_obj,p_obj_id)=>{
                                                        return isMobile?
                                                        p_obj_id < 1 &&<Grid item key={p_obj?.id} md={bundle?.products?.length === 3 ? p_obj_id ===3 ?12:6:6} sx={{textAlign:"center",position:"relative",p:"0px !important"}}>
                                                            {/* {console.log("----image-------",p_obj)} */}
                                                            <Box component="img" sx={{ width:{xs:"calc(100% - 16px)",lg:is2560px?"calc(100% - 32px)":"calc(100% - 16px)"}}} src={p_obj?.product?.cover_details?.original} />
                                                            <Stack sx={{position:"absolute",left:"-0.7%",top:"15px",width:"86px",height:"25px",borderTopRightRadius:"8px",borderBottomRightRadius:"8px",p:"4px 8px",border:"1px solid #FFFFFF",borderBottom:"0px",background:"linear-gradient(90deg, #0040E3 13.1%, #00FF80 100%)","boxShadow": "0px 2px 2px 0px #00000026"}}>
                                                                <TranslateText sx={{fontWeight:700,fontSize:"12px",color:"#FFFFFF"}}>Bundle</TranslateText>
                                                            </Stack>
                                                        </Grid>
                                                        :
                                                        p_obj_id <=3 &&<Grid item key={p_obj?.id} md={bundle?.products?.length === 3 ? p_obj_id ===3 ?12:6:6} sx={{textAlign:"center"}}>
                                                            {/* {console.log("----image-------",p_obj)} */}
                                                            <Box component="img" sx={{ height: "100%",maxWidth:{xs:"calc(100% - 16px)",lg:is2560px?"calc(100% - 32px)":"calc(100% - 16px)"} }} src={p_obj?.product?.cover_details?.original} />
                                                        </Grid>
                                                    })
                                                }
                                                {!isMobile && <Stack sx={{position:"absolute",left:"-0.7%",top:"15px",width:"86px",height:"25px",borderTopRightRadius:"8px",borderBottomRightRadius:"8px",p:"4px 8px",border:"1px solid #FFFFFF",borderBottom:"0px",background:"linear-gradient(90deg, #0040E3 13.1%, #00FF80 100%)","boxShadow": "0px 2px 2px 0px #00000026"}}>
                                                    <TranslateText sx={{fontWeight:700,fontSize:"12px",color:"#FFFFFF"}}>Bundle</TranslateText>
                                                </Stack>}
                                            </Grid>
                                        }
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                        <TranslateText sx={styles.productHeading}>{bundle.name}</TranslateText>
                                        <TranslateText sx={styles.productContent}>{bundleTag[bundle?.id] || "Health bundle"}</TranslateText>
                                        <TranslateText sx={styles.productSize}>Items: {String(bundle.products_count)} Products</TranslateText>
                                        {/* <TranslateText sx={styles.productContent}>{bundle.description}</TranslateText> */}
                                        {/* <TranslateText sx={styles.productSize}>Size: {bundle.unit}</TranslateText> */}
                                    </Box>
                                </Box>

                                <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                    <Box sx={{ display: "flex", gap: "12px" }}>
                                        <Stack direction={'row'} sx={{alignItems:"center"}}>
                                            <CurrencyRupee sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6", marginTop: "-1px" }} />
                                            {/* {authtoken && member === "member"? */}
                                            <Typography sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6px" }}>{
                                                // (parseFloat(bundle.price)+( parseFloat(bundle.price) * parseFloat(12/100) )).toFixed(2)
                                                CalculateProductPrice({authtoken:authtoken,member:member,item:{product:{selling_price:bundle.price,tax:12}},lineStrike:false})
                                                }</Typography>
                                            {/* :
                                            <Typography sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6px" }}>
                                                {calculateTotalPrice(bundle.products)}
                                            </Typography>
                                            } */}
                                        </Stack>
                                        
                                            {/* <div style={{ display: "flex", alignItems: "center" }}>
                                                {authtoken&&member=='member' ? <CurrencyRupee sx={{ fontSize: "12px", fontWeight: "400", color: "error.light", marginTop: "-1px" }} />:<CurrencyRupee sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6", marginTop: "-1px" }} />}
                                                    {authtoken&&member=='member' ? (
                                                    <>
                                                    <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "error.light", textDecoration: "line-through" }}>
                                                    {(parseFloat(product.price) + Math.round(product?.price * (product?.tax/100) )).toFixed(2)}
                                                        </Typography>
                                                    </>
                                                    ) : 
                                                    <>
                                                <Typography sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6px" }}>  {(parseFloat(product.price) + Math.round(product?.price * (product?.tax/100) )).toFixed(2)}</Typography>
                                                    </>
                                                    }
                                                </div>
                                                {authtoken&&member=='member' && (
                                                    <Box sx={{ display: "flex", gap: "12px",flexDirection:"row" }}>
                                                    <Divider orientation="vertical" flexItem />
                                                    <Box sx={{ display: "flex"}}>
                                                    <CurrencyRupee sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6", marginTop: "-1px" }} />
                                                    <Typography sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6px" }}>{ (parseInt( member=='member'?product.selling_price:product.price) + Math.round(parseInt(member=='member'?product.selling_price:product.price) * (parseInt(product.tax)/100))).toFixed(2) }</Typography>
                                                    </Box>
                                                    </Box>
                                                )} */}
                                    </Box>
                                    {/* {cartBundles[bundle.id] && cartBundles[bundle.id] !==0 && bundle.out_of_stock === false ?   <Button key={bundle.slug_name} onClick={(e) => addBundleCart(bundle, 1, e)} variant="contained" sx={{ width: "100%", bgcolor: "black", height: "36px", padding: "8px 12px", textTransform: "none", "&:hover": { bgcolor: "black", color: "white" } ,display:"flex",gap:"20px"}}>
                                                        <Typography onClick={(e) => updateQuantity(bundle, false, e)} sx={{ fontSize: "14px", fontWeight: "600", cursor: "pointer",padding:"0px 8px" }}>
                                                        <RemoveIcon sx={{fontSize:"15px",color:"#fff"}} />
                                                        </Typography>

                                                        <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                                            {
                                                                cartBundles[bundle.id]?.qty
                                                            // cartCheckProducts(bundle.products)
                                                            }
                                                        </Typography>
                                                        <Typography  onClick={(e) => updateQuantity(bundle, true, e)} sx={{ fontSize: "14px", fontWeight: "600", cursor: "pointer",padding:"0px 8px" }}>
                                                            <AddIcon  sx={{fontSize:"15px",color:"#fff"}} />
                                                        </Typography>

                                                    </Button> : */}
                                                    {!bundle.out_of_stock?
                                    <Button variant="contained" disabled={isLoading} sx={{ width: "100%", bgcolor: "black", height: "36px", padding: "8px 12px", textTransform: "none", "&:hover": { bgcolor: "black", color: "white" } }} onClick={(e) => addBundleCart(bundle, 1, e)}>
                                        <ShoppingCartOutlinedIcon sx={{ marginRight: "10px" }} />
                                        <TranslateText sx={{fontSize:"14px"}}> Add to Cart</TranslateText> 
                                    </Button>:<Box><TranslateText> Out Of Stock</TranslateText></Box>}
                                </Box>
                            
                            </Box>

                        </Grid>
                    ))
                }
            </Grid> 
        </Stack>
    )
}

export default BundleProductsListUser