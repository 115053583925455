import { Modal,Drawer, } from "@mui/material"
import OtpVerifyOption from "./otp_verify_option"
import { useSelector } from "react-redux"
import { updateOtpVerifyPopup } from "../redux/user_reducer"
import { useDispatch } from "react-redux"
import { useMediaQuery } from "react-responsive"
import { useEffect,useState } from "react"


const OTP_popup=()=>{

    const dispatch = useDispatch()
    const open=useSelector(state=>state.user.OtpVerifyPopup)
    const handleClose = () => {
        dispatch(updateOtpVerifyPopup(false))
        // setErrors({})
        // setIsVerified(true)
        // setOpen(false);
    }

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });


    const isMobile = useMediaQuery({ maxWidth: 600 });
    const toggleDrawer = (anchor, open) => {
        // console.log("????????????changed")
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //   return;
        // }
        
        setState({ ...state, [anchor]: open });
      };
      useEffect(()=>{
        if (open){
            toggleDrawer('bottom', true)
        }
        else{
            toggleDrawer('bottom', false)
        }
      },[open])
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
return <>
{isLaptop?<Modal open={open}
// onClose={handleClose}
aria-labelledby="child-modal-title"
aria-describedby="child-modal-description"
>
    <OtpVerifyOption/>
</Modal>:
<>
     {[ 'bottom'].map((anchor) => {
        // <React.Fragment key={anchor}>
           {/* <Button onClick={}>{anchor}</Button> */}
           {/* <InputBase value={selectedVoucher?selectedVoucher.voucher.name:""} sx={[formStyle.inputBase,{cursor:"pointer",textTransform:"capitalize"}]} onClick={toggleDrawer(anchor, true)} placeholder="Apply Coupon"  endAdornment={selectedVoucher? <HighlightOffIcon sx={{color:"#DA342E"}} onClick={()=>{setOpenCoupon(false);setSelectedVoucher()}} />  : <AddIcon sx={{color:"#1099F4"}} onClick={()=>toggleDrawer(anchor, true)} />}></InputBase> */}
          return <Drawer
            anchor={anchor}
            open={state[anchor]}
            // onClose={toggleDrawer(anchor, false)}
          >
            {/* {list(anchor)} */}
            {/* {formType=='login'?<LoginForm/>:<SignupForm/>} */}
            {/* hello to all */}
            <OtpVerifyOption/>
          </Drawer>
        // </React.Fragment>
     } )}</>


}
</>

}

export default OTP_popup