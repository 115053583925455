import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    bundles:[],
    bundle:{}
}



let Bundleproduct = createSlice({
    name: "Bundleproduct",
    initialState: initialState,
    reducers: {
        updateBPIntialState:(state, { type, payload }) => {
            state[payload.key] = payload.value
        },
    }
})

export let { 
    updateBPIntialState
} = Bundleproduct.actions
  
  
  
export default Bundleproduct.reducer;