import axios from "axios";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateLanguage } from "../redux/user_reducer";
// import 
export const TranslateText =({children,sx,style,component,to,target,onClick,color} ) => {
const language=useSelector(state=>state.user.language)
const [astric,setAstric]=useState(false)
const [quot,setQuot]=useState(false)
const dispatch=useDispatch()
// console.log("children text",children,sx,language)
// console.log("?????????????????????????????????????????????")
let [translatedText,setTranslatedText]=useState("")
let storedlan=localStorage.getItem('language')
let [lan,setLan]=useState('en')
// useEffect(()=>{
//   if (language==storedlan){
//       setLan(language)
//   }
//   else if (storedlan){
//     setLan(storedlan)
//     dispatch(updateLanguage(storedlan))
   
// }

// },[language,storedlan])

useEffect(()=>{
    if (language!='en'){
    // console.log(children)
        let text=React.Children.toArray(children).map((child) => {
          
         if( typeof (child) === 'string' ) {
          return child 
         }
         else{
          // console.log("?????????????????????????",child, child.props?.children)
          // if (child?.props && Object.keys(child?.props).includes('style')){
          //   axios.post(
          //     "https://translation.googleapis.com/language/translate/v2?key=AIzaSyCMcZ_oG9M6xnr5TLOopvBXw4YC0m61Uw0",
          //     {
          //       q: child.props?.children,
          //       target: language,
          //       source:"en"
          //     },
          //     // {
          //     //   headers: {
          //     //     `,
          //     //   },
          //     // }
          //   ).then(({data})=>setTranslatedText(data.data.translations[0].translatedText)).catch(err=>console.log(err))

          // }
          return  child.props?.children
          
         
         } 
        })
        .filter(Boolean).join(' ')
        const regexastric = /\*/
        const regexQuot=/['"]/g
        // console.log(text)
        if (regexastric.test(text)){
          text=text.split("*").join(" ")
          setAstric(true)
        }
       

        axios.post(
            "https://translation.googleapis.com/language/translate/v2?key=AIzaSyBQ0RfJ--ThYL6pKyZUsn2G2D09pEEV7dk",
            {
              q: text,
              target: language,
              source:"en"
            },
            // {
            //   headers: {
            //     `,
            //   },
            // }
          ).then(({data})=>setTranslatedText(data.data.translations[0].translatedText)).catch(err=>console.log(err))
        
    }
    else{
      let text=React.Children.toArray(children).map((child) => {
          
        if( typeof (child) === 'string' ) {
         return child 
        }
        else{
     
         return  child.props?.children
         
        
        } 
       })
       .filter(Boolean).join(' ')
       const regexastric = /\*/
        const regexQuot=/['"]/g
        // console.log(text)
        if (regexastric.test(text)){
          text=text.split("*").join(" ")
          setAstric(true)
        }
       setTranslatedText(text)
    }
   
},[language,children])

// console.log(children)

return <Typography target={target} color={color} component={component}  to={to} onClick={onClick} sx={sx} style={style}>{language=='en'?translatedText.replace(/\*/g, ''):translatedText.replace(/&quot;/g,'"').replace("&amp;",'&') }{astric&&<span style={{color:"rgba(255, 122, 122, 1)"}} color='error'>*</span>}</Typography>
{/*  */}

  
};

export const PlaceholderTranslater =({child} ) => {
  // console.log("?????????????????????????????????????????????",child)
  const language=localStorage.getItem('language')
  const [astric,setAstric]=useState(false)
  // console.log("children text",children,sx,language)
  let [translatedText,setTranslatedText]=useState("")

  // console.log("?????????????????????????????",child)
  useEffect(()=>{
      if (language!='en'){
      // console.log(text)
          let text=React.Children.toArray(child).map((child) => {
            let trans=''
            let transText=""
           if( typeof (child) === 'string' ) {
            trans=child 
           }
           else{
            // console.log("?????????????????????????",child.props?.children)
            trans=  child.props?.children
            
           
           } 
           axios.post(
            "https://translation.googleapis.com/language/translate/v2?key=AIzaSyCMcZ_oG9M6xnr5TLOopvBXw4YC0m61Uw0",
            {
              q: trans,
              target: language,
              source:"en"
            },
          ).then(({data})=> transText=data.data.translations[0].translatedText).catch(err=>console.log(err)) 
          return transText
          })
          .filter(Boolean).join(' ')
          const regex = /\*/
          // console.log(text)
          if (regex.test(text)){
            text=text.split("*").join(" ")
            setAstric(true)
          }
          setTranslatedText(text)
           
      }
  },[language,child])
  // console.log(translatedText,child)
  return language!='en'?translatedText:child 
  };

// export const TranslateSpanText =({children,style,} ) => {
//     const language=localStorage.getItem('language')
//     // console.log("children text",children,language)
//     let [translatedText,setTranslatedText]=useState("")
    
//     useEffect(()=>{
//         if (language!='en'){
//         // console.log(children)
//             let text=React.Children.toArray(children).map((child) => (typeof child === 'string' ? child : null))
//             .filter(Boolean).join(' ')
//             // console.log(text)
          
//                 // console.log(children)
           
//             axios.post(
//                 "https://translation.googleapis.com/language/translate/v2?key=AIzaSyCMcZ_oG9M6xnr5TLOopvBXw4YC0m61Uw0",
//                 {
//                   q: text,
//                   target: 'ta',
//                   source:"en"
//                 },
//                 // {
//                 //   headers: {
//                 //     `,
//                 //   },
//                 // }
//               ).then(({data})=>setTranslatedText(data.data.translations[0].translatedText)).catch(err=>console.log(err))
            
//         }
       
//     },[language])
    
    
    
//     return <span style={style}>{language!='en'?translatedText:children}</span>
    
      
//     };
    
