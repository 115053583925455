import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Button, Paper, Typography, Tooltip, Menu, MenuItem, Grid,Modal, Select, Divider, Stack } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import WalletIcon from "../assets/dashboad/Wallet.svg"
import PlusIcon from "../assets/dashboad/Vector.svg"
import SalaryIcon from "../assets/dashboad/Salary.svg"
import AtmIcon  from "../assets/dashboad/Atm.svg"
import ThreeDotIcon from "../assets/dashboad/Threedot.svg"
import RecievedIcon from "../assets/dashboad/Received.svg"
import OrgIcon from "../assets/dashboad/Organization chart.svg"
import MeetIcon from "../assets/dashboad/Meeting.svg"
import InfoIcon from "../assets/dashboad/info.svg"
import WageIcon from "../assets/dashboad/Wage.svg"
import AmbIcon from "../assets/dashboad/Ripple.svg"
import GoldAmbIcon from "../assets/dashboad/Gold.svg"
import DiaIcon from "../assets/dashboad/Diamond.svg"
import CrownIcon from "../assets/dashboad/Crown.svg"
import LinkIcon from '@mui/icons-material/Link';
import Layout from "../layout"
import SideBarList from "../components/list"
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import CustomAxios from '../utils/CustomAxios';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    ArcElement,
    Tooltip as Ctooltip,
    Legend,
    BarElement,
    RadialLinearScale} from 'chart.js'
import { TranslateText } from "../utils/googletranslator"; 
import { Bar,Line } from 'react-chartjs-2';
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import MessageBar from "../modules/messageBar";
import { updateAddressModal, updateUserName,updateAccumulated, updateBio, updateAddress,updateEmail,updateMobile,updateProfile, updateOtpCheck,updateGetBank,updateWithdrawRequest,updateWhatsapp, updateMembership, updateIsProfileReloaded,  updateMemberResponse } from "../redux/user_reducer"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Info,ChevronLeft, ChevronRight, } from "@mui/icons-material";
import { updateOrdersAndLevels } from "../redux/products";
import GoalStatus from "../components/goal";
ChartJS.register(
    BarElement,
    RadialLinearScale,
    ArcElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Ctooltip,
    Legend
);
const Dashboard = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const isXLaptop = useMediaQuery({ query: '(min-width: 1600px)' });
    const isTabletHori = useMediaQuery({ query: '(min-width: 1200px)' });
    const isTablet= useMediaQuery({ maxWidth: 900,minWidth:600 });
    const isProfileReloaded=useSelector(state=>state.user.isProfileReloaded)
    const transferReferalClose=useSelector(state=>state.user.tranferReferralclose) 
    const [open, setOpen] =useState(false);
    const [userid, setUserId] = useState('')
    const [imageurl, setImageUrl] = useState('')
    const [userreponse, setUserResponse] = useState({})
    const [city, setCity] = useState('')
    const [packagedetails, setPackageDetails] = useState([])
    const [country, setCountry] = useState('')
    const dispatch = useDispatch()
    let id = parseInt(useSelector(state => state.user.id))
    let profile = useSelector(state => state.user.profile)
    let res = useSelector(state => state.user.member_response)
    const accumulate = useSelector(state => state.user.accumulated)
    let commissions = useSelector(state => state.product?.OrdersAndLevels)
    const [team_member,setTeamMemeber]=useState([])
    const [subMember,setSubMember]=useState()
    const [teamGrowth,setTeamGrowth]=useState()
    const [teamChart,setTeamChart]=useState()
    const [commissionChart,setCommissionChart]=useState()
    const [data, setData] = useState({ labels: [], datasets: [] });
    const [treeBoard,setTreeBoard]=useState([])
    const [imagestatus,setImageStatus]=useState(true)
    const [secondLevel,setSecondLevel]=useState(true)
    const [restrictClose,setRestrictClose]=useState(false)
    // // console.log(res)
    // const [performance,]
    const [teamPerformance,setTeamPerformance]=useState()
    const [loopCall,setLoopCall]=useState(true)
    const [performanceChart,setPerformanceChart]=useState()
    const formStyle = {
        inputBase: { border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" },
        label: { marginBottom: "12px", fontWeight: 700, fontSize: "16px" },
        textBox: { border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", fontSize: "16px", padding: "14px", color: "grey.700" },
        button: { marginBottom:"5px", textTransform: "none", marginTop:isLaptop?"24px":"0px", marginRight:isLaptop?"20px":"0px", borderRadius:"5px",fontWeight: 600, fontSize: "14px", maxWidth: "300px", alignSelf:isLaptop?"flex-end":"", marginTop:isLaptop?"":"15px" },
    }
    let  first_name = useSelector(state => state.user.first_name)
    let  last_name = useSelector(state => state.user.last_name)
    const packagesRef = useRef(null)
    const [activeSlide, setActiveSlide] = useState(0);
    let sliderRef = useRef(null);

    const [isMCTransferClicked,setIsMCTransferClicked] = useState(false)
    const [isMETransferClicked,setIsMETransferClicked] = useState(false)
    const [ReUsername,setRecUsername] = useState(null)
    const [Transferamt,setTransferAmt] = useState(0.00)
    const [transferModalOpen,setTransferModalOpen] = useState(false)
    const [transferReferalModal,setTransferReferalModal] = useState(false)


    const packageSliderSettings = {
        infinite : false,
        
        slidesToScroll : isTablet ? 1 :isTabletHori? 1 : isLaptop ? 2 : isXLaptop? 3 : 5 ,
        slidesToShow : isTablet ? 1 : isTabletHori? 1 :isLaptop ? 2 : isXLaptop ? 3 : 5 ,   
        // slidesToShow : isTablet ? 1 : isLaptop ? 2 : isXLaptop? 3 : 5,
        variableWidth : true,
        swipe:true,
        tochMove:true,
        swipeToSlide:true,
        afterChange: (slide) => setActiveSlide(slide),
        

    }
    useEffect(()=>{
        setUserResponse(res)
    },[res])

    useEffect(()=>{
        setPackageDetails(userreponse?.package_details)
    }
,[userreponse])
useEffect(()=>{
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

},[])
useEffect(()=>{
    // console.log(userreponse,Object.keys(userreponse).length)
    if (Object.keys(userreponse).length>0){
        let user_tree={member:{},members:[],team_count:0}
        user_tree.member=userreponse?.user
        user_tree.team_count=userreponse?.team_size
           user_tree.members=userreponse.team_member
           // console.log(user_tree)
           setTreeBoard([user_tree])
            // setTeamMemeber(userreponse.team_member)
    }
   
// user_tree['']


},[userreponse])
    const package_details=[{
        package_type:"Package D",
        package_amount:"1120",
        package_status:"Incomplete",
        package_remaining_amount:"1120"
    },
    {
        package_type:"Package C",
        package_amount:"3360",
        package_status:"Incomplete",
        package_remaining_amount:"3360"
    },
    {
        package_type:"Package B",
        package_amount:"11200",
        package_status:"Incomplete",
        package_remaining_amount:"11200"
    },
    {
        package_type:"Package A",
        package_amount:"56000",
        package_status:"Incomplete",
        package_remaining_amount:"56000"
    },
    {
        package_type:"Package AA",
        package_amount:"112000",
        package_status:"Incomplete",
        package_remaining_amount:"112000"
    },
]

    useEffect(() => {
        //// console.log(id)
        setUserId(id)
    }, [id])
    useEffect(() => {

        fetchuserdetails(userid)
    }, [userid,isProfileReloaded])
    const fetchuserdetails = async (userid) => {
        // console.log("id", id)
        const payload = {
            user_id: id
        }
        await CustomAxios.post(`get_details/`, payload).then(response => {
            // console.log(response.data)
            setUserId(response.data.user.id)
            setUserResponse(response.data)
            dispatch(updateUserName(response.data.user.username))
            dispatch(updateMemberResponse(response.data))
            setImageUrl(response.data.att?.original)
            setLoopCall(true)
            dispatch(updateProfile(response.data.att?.original))
            dispatch(updateOrdersAndLevels(response.data.team_orders))
            dispatch(updateAccumulated(response.data.accumulated))
            dispatch(updateWhatsapp(response.data.user.whatsapp))
            dispatch(updateGetBank(response.data.bankdetails))
            dispatch(updateWithdrawRequest(response.data.withdrawRequest))
            dispatch(updateMembership(response.data.user.member))
            dispatch(updateIsProfileReloaded(false))
            if (response.data?.add.length>0){
                setCity(response.data.add[0].town_city)
                setCountry(response.data.add[0].country)
            }
        }
        ).catch(error => {
            // console.log(error)
        })
    }
    const capitalize = (str) => {
        return str?.split(' ').map(word => word?.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      };

      const formatdate = (date) => {
        const newdate = new Date(date);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[newdate.getMonth()];
        const year = newdate.getFullYear()
        const formatdate =newdate.getDate()+" " + month + " " + year
        return formatdate
    }
    const handleCopyurl = () =>{
        const url = process.env.REACT_APP_URL+`signup/?code=${userreponse?.account?.referral_code}`
        navigator.clipboard.writeText(url)
        .then(() => {
            dispatch(updateSnackBarMessage("URL copied to clipboard!"))
            dispatch(updateSnackBarSeverity("success"))
          })
          .catch((error) => {
            dispatch(updateSnackBarMessage(error))
            dispatch(updateSnackBarSeverity("error"))
          });
          dispatch(updateSnackBarOpen(true))
    }
    const handleClose = () => {
        dispatch(updateAddressModal(false))
        setOpen(false);
    };
    const addCurrencyAndCommafy = (num) => {
        // // console.log(num)

        let str = parseFloat(num).toFixed(2).toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        return "₹ " + str.join('.');

    }

    const calculateWidth = (detail) =>{
        let width

        if (parseFloat(detail.package_remaining_amount)>0 && parseFloat(detail.package_remaining_amount)<=parseFloat(detail.package_amount)){
        const widthcal = parseInt(detail.package_amount)-parseInt(detail.package_remaining_amount)
        width = (315/detail.package_amount) * widthcal + "px"}
        else{
        width = "315px"
        }
        return width  
    }

    const calculatePV = (detail) => {
        //const avlamt = parseInt(detail.package_amount)-parseInt(detail.package_remaining_amount)
        const calculatedpv = parseFloat(detail.commision_amount) * 0.6
        return "(" + calculatedpv.toFixed(2) + "PV" + ")"
    }

    

    const getMemberLevel = (pv, type) =>{
        // // console.log(pv)
        let memberLevel, memberPercent, memberremamt, memberremwidth, memprevlevel
        if (parseFloat(pv)<89000.00){
            memberLevel="Member"
            memberPercent=(parseFloat(pv)/89000.00) * 100
            memberremamt=89000.00 - parseFloat(pv)
            memberremwidth = (348/89000.0) * parseFloat(pv) + "px"
            memprevlevel="Ambassador"
        }
        if (parseFloat(pv)>=89000.00&&parseFloat(pv)<178000.00){
            // console.log("here")
                memberLevel="Ambassador"
                memberPercent = (parseFloat(pv)/178000.00) * 100
                memberremamt=178000.00 - parseFloat(pv)
                memberremwidth = (348/178000.0) * parseFloat(pv) + "px"
                memprevlevel="Gold Ambassador"
        }
        if (parseFloat(pv)>=178000.00&&parseFloat(pv)<355000.00){
            memberLevel="Gold Ambassador"
            memberPercent = (parseFloat(pv)/355000.00) * 100
            memberremamt=355000.00 - parseFloat(pv)
            memberremwidth = (348/355000.0) * parseFloat(pv) + "px"
            memprevlevel="Double Ambassador"
            
        }
        if (parseFloat(pv)>=355000.00&&parseFloat(pv)<709000.00){
            memberLevel="Double Ambassador"
            memberPercent = (parseFloat(pv)/709000.00) * 100
            memberremamt=709000.00 - parseFloat(pv)
            memberremwidth = (348/709000.0) * parseFloat(pv) + "px"
            memprevlevel="Diamond Ambassador"
        }
        if (parseFloat(pv)>=709000.00&&parseFloat(pv)<900000.00){
            memberLevel="Diamond Ambassador"
            memberPercent = (parseFloat(pv)/900000.00) * 100
            memberremamt=900000.00 - parseFloat(pv)
            memberremwidth = (348/709000.0) * parseFloat(pv) + "px"
            memprevlevel="Double Diamond Ambassador"
        }
        if (parseFloat(pv)>=900000.00&&parseFloat(pv)<1770000.00){
            memberLevel="Double Diamond Ambassador"
            memberPercent = (parseFloat(pv)/1770000.00) * 100
            memberremamt=1770000.00 - parseFloat(pv)
            memberremwidth = (348/1770000.0) * parseFloat(pv) + "px"
            memprevlevel="Crown Ambassador"
        }
        if (parseFloat(pv)>=1770000.00&&parseFloat(pv)<3542000.00){
            memberLevel="Crown Ambassador"
            memberPercent = (parseFloat(pv)/3542000.00) * 100
            memberremamt=3542000.00 - parseFloat(pv)
            memberremwidth = (348/3542000.0) * parseFloat(pv) + "px"
            memprevlevel="Double Crown Ambassador"
        }
        if (parseFloat(pv)>=3542000.00&&parseFloat(pv)<8855000.00){
            memberLevel="Double Crown Ambassador"
            memberPercent = (parseFloat(pv)/8855000.00) * 100
            memberremamt=8855000.00 - parseFloat(pv)
            memberremwidth = (348/8855000.0) * parseFloat(pv) + "px"
            memprevlevel="Triple Crown Ambassador"
        }
        if (parseFloat(pv)>=8855000.00){
            memberLevel="Triple Crown Ambassador"
            memberPercent = 100
            memberremwidth = "348px"
            memprevlevel = ""
            
        }
        // // console.log(memberLevel)
        if (type=="level"){
        return memberLevel}
        if (type=="percent"){
        return memberPercent
        }
        if (type=="remamt"){
        return memberremamt
        }
        if (type=="remwidth"){
        return memberremwidth
        }
        if (type=="prevlevel"){
        return memprevlevel
        }
    }

    const selectPackageColor = (detail, where) => {
        let color
        if (detail.package_status=="Accomplished" && detail.package_type=="Package D" && where=="One"){
            color = "#00C561"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package D" && where=="Two"){
            color = "#008941"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package D" && where=="Three"){
            color = "#EAFFE9"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package C" && where=="One"){
            color = "#0096F0"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package C" && where=="Two"){
            color = "#007CC7"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package C" && where=="Three"){
            color = "#F8F9FF"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package B" && where=="One"){
            color = "#F06700"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package B" && where=="Two"){
            color = "#C75400"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package B" && where=="Three"){
            color = "#FFF8F6"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package A" && where=="One"){
            color = "#B200F0"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package A" && where=="Two"){
            color = "#8603B4"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package A" && where=="Three"){
            color = "#FBEFFF"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package AA" && where=="One"){
            color = "#0035F0"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package AA" && where=="Two"){
            color = "#04269C"
        }
        if (detail.package_status=="Accomplished" && detail.package_type=="Package AA" && where=="Three"){
            color = "#F3F6FF"
        }
        return color
    }
        // Sample data for both bar and line charts
        
        const wallet_data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June'],
            datasets: [
              {
                label: 'Transactions',
                data: [65, 59, 80, 81, 56, 55], // Example sales data for each month
                backgroundColor: '#53AEFF', // Background color for bars
                borderColor: '#53AEFF', // Border color for bars
                borderWidth: 1 // Border width for bars
              }
            ]
          };
          useEffect(()=>{
            // console.log(team_member)
            if (res?.teamGrowth?.length>0){
                // // console.log(team_member)
                
                  // console.log(withdraw_data)
            }
          },[res.teamGrowth])
          const withdraw_data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June'],
            datasets: [
              {
                label: 'Transactions',
                data: [65, 59, 80, 81, 56, 55], // Example sales data for each month
                backgroundColor: '#61FF92', // Background color for bars
                borderColor: '#61FF92', // Border color for bars
                borderWidth: 1 // Border width for bars
              }
            ]
          };
          useEffect(()=>{
            // // console.log(teamGrowth)
            if (teamGrowth){
                const chartData = {
                    labels: Object.keys(teamGrowth),
                    datasets: [
                      {
                        label: 'Team Growth',
                        data: Object.values(teamGrowth), // Example sales data for each month
                        backgroundColor: '#61FF92', // Background color for bars
                        borderColor: '#61FF92', // Border color for bars
                        borderWidth: 1 // Border width for bars
                      }
                    ]}
                setTeamChart(chartData)
                //   // console.log(withdraw_data)
            }
            

          },[teamGrowth])
          
  
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
        const lineChartData = {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label: 'Line Chart Data',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(75,192,192,1)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(75,192,192,1)',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: [30, 40, 50, 60, 70, 80, 90],
            },
          ],
        }
        function stringToColor(string) {
            let hash = 0;
            let i;
          
            /* eslint-disable no-bitwise */
            for (i = 0; i < string.length; i += 1) {
              hash = string.charCodeAt(i) + ((hash << 5) - hash);
            }
          
            let color = '#';
          
            for (i = 0; i < 3; i += 1) {
              const value = (hash >> (i * 8)) & 0xff;
              color += `00${value.toString(16)}`.slice(-2);
            }
            /* eslint-enable no-bitwise */
          
            return color;
          }
        function stringAvatar(name) {
            return {
              sx: {
                bgcolor: stringToColor(name),
                width:"72px",height:"72px",
                fontSize:"32px"
              },
              children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            };
          }
          function teamStringAvatar(name) {
            return {
              sx: {
                bgcolor: stringToColor(name),
                width:"72px",height:"72px",
                borderRadius:"0px"
              },
              children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            };
          }
          useEffect(()=>{
            // console.log(team_member.length,loopCall)
            if(loopCall){
                // console.log(item.id)
                // console.log(res)
                res.team_member?.map(item=>{
                    // console.log(item)
                    teamPreview(item.user_id)
            })
                setTeamMemeber([])
                // // console.log("continuing the loop")
                
                // teamPreview(79)

            }
            
          },[res])
          const teamPreview=async(id)=>{
            // console.log(id)
            // setOpen(true)
            setLoopCall(false)
           await CustomAxios.get(`team-member/${id}`).then((res)=>{
                // console.log(res)
                setTeamMemeber(prev =>[...prev, res.data])
                
            }).catch(error => {
                // console.log(error)
            })

          }
          useEffect(()=>{
            // console.log(userreponse)
            if (Object.keys(userreponse).length>0&&res?.team_growth){
                // console.log(res?.team_growth)
                let returnedData=getLastSixMonthsData(res?.team_growth)
                if (returnedData){
            returnedData=Object.keys(returnedData)?.sort((a, b) => {
                const [aMonth, aYear] = a.split(' ');
                const [bMonth, bYear] = b.split(' ');

                const aDate = new Date(`${aMonth} 1, ${aYear}`);
                const bDate = new Date(`${bMonth} 1, ${bYear}`);

                return aDate - bDate;
            }).reduce((sortedAcc, key) => {
                sortedAcc[key] = returnedData[key];
                return sortedAcc;
            }, {});;


            // console.log(returnedData)
            setTeamGrowth(returnedData)
            teamPerformanceValues(res.team_growth,0)
                
            }
            
        }
          },[userreponse])
          const teamPerformanceValues=(data,member)=>{
            // console.log(data)
            const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            const performer=data?.map(item=>{
                return{name:capitalize(item.first_name)+" "+capitalize(item.last_name),id:item.id}
            })
            setTeamPerformance(performer)
            const chartData = {
                labels: data[member]?.earning?.map(item=>month[item.created_on_at_month-1]) ,
                datasets: [
                  {
                    label:"Avg of Earnings",
                    data:  data[member]?.earning?.map(item=>item.total), // Example sales data for each month
                    backgroundColor: '#61FF92', // Background color for bars
                    borderColor: '#61FF92', // Border color for bars
                    borderWidth: 1 // Border width for bars
                  }
                ]}
                setPerformanceChart(chartData)

          }
        // console.log(team_member)
          const modalOpen=(id)=>{
           const member=team_member.filter(item=>item.member.id==id)
           // console.log(member)
           setSubMember(member[0])
          

          }
          const third_stage_member=async(id)=>{
            const present=treeBoard.filter(item=>item.member.id==id)
            if (present.length<=0&& treeBoard.length<10){
                const tree=team_member.filter(item=>item.member.id==id)
                // console.log(tree[0])
                setTeamMemeber([])
                setSecondLevel(false)
                 setTreeBoard(prev=>[...prev,tree[0]])
                //  console.log("id list")
                 await CustomAxios.get(`team-member/${id}`).then((res)=>{
                        // console.log(res)
                       
                        res.data?.members.map(item=>teamPreview(item.id))
                        // setOpen(true)
                    }).catch(error => {
                        // console.log(error)
                    })
            }           //
          }
          function getLastSixMonthsData(data) {
            const sixMonthsAgo = new Date();
            // console.log(data)
            sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
           const filtered= data?.filter(item => new Date(item?.date_joined) >= sixMonthsAgo);

            return filtered?.reduce((acc, obj) => {
                // console.log(obj)
                const date = new Date(obj.date_joined);
                const monthYear = date.toLocaleString('default',  { month: 'long', year: 'numeric' }); // Format: YYYY-MM
                // console.log(monthYear)
        
                if (!acc[monthYear]) {
                    acc[monthYear] = 0;
                }
        
                acc[monthYear]= 1 + parseInt(acc[monthYear]);
                return acc
            }, {});
        }
        const backWard=async(index,userid)=>{
            if (id==userid){
                setSecondLevel(true)
            }
            let team_tree=[...treeBoard] 
            team_tree=team_tree.slice(0,index+1)
            // console.log(team_tree)
            // const tree=team_member.filter(item=>item.member.id==id)
                // // console.log(tree[0])
                setTeamMemeber([])
                 setTreeBoard(team_tree)
                 await CustomAxios.get(`team-member/${userid}`).then((res)=>{
                        // // console.log(res)Nivetha@321
                       
                        res.data?.members.map(item=>teamPreview(item.id))
                        // setOpen(true)
                    }).catch(error => {
                        // console.log(error)
                    })


        }
        
    
        useEffect(() => {
            // Group data by levels
            const groupByLevel = (data) => {
              return data?.reduce((acc, item) => {
                const levelKey = `Level ${item.level}`;
                if (!acc[levelKey]) {
                  acc[levelKey] = { users: [], commission: 0 };
                }
                if (!acc[levelKey].users.includes(item.username)) {
                    acc[levelKey].users.push(item.username);
                }
                acc[levelKey].commission += +item.commissions || 0;
                return acc;
              }, {});
            };
        
            const groupedDataResult = groupByLevel(commissions);
            const sortedLabels = Object.keys(groupedDataResult)
            .sort((a, b) => {
                const numA = parseInt(a.replace('Level ', ''));
                const numB = parseInt(b.replace('Level ', ''));
                return numA - numB;
            });
            setData(groupedDataResult);
            const chartData = {
              labels: sortedLabels,
              datasets: [
                {
                  label: 'Levels',
                  data: sortedLabels.map(label => groupedDataResult[label].commission),
                  backgroundColor: '#61FF92',
                  borderColor: '#61FF92',
                  borderWidth: 1,
                  barThickness: 30, 
                },
              ],
            };
        
            setCommissionChart(chartData);
          }, [commissions]);
        
          const options = {
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const level = context.label;
                    const items = data[level] || { ids: [], commission: 0 };
                    const idsAndLevels = items.users.map((user,ind) => ` ${ind+1}.${user.charAt(0).toUpperCase() + user.slice(1).toLowerCase()}`);
                    return [`Earnings: ${items.commission.toFixed(2)}  `,'','Members :','',...idsAndLevels,''];
                  },
                },
              },
            },
            scales: {
                y: {
                    display: true,
                    beginAtZero: true,
                    ticks: {
                        stepSize: 20, 
                      },
                    title:{
                        text:"Earnings ---->",
                        display:true,
                        color:"grey",
                        fontSize:20,
                        letterSpacing:2.5,
                    },
              },
            },
          };
    

    

    // const handlePayment = async()=>{
    //     let bodyData = new FormData();
    //     bodyData.append("amount", parseFloat(490).toString());
    //     bodyData.append("user_id", userid);
    //     bodyData.append("transaction_fee",parseFloat(490*0.0195).toString())
        
    //     const data = await CustomAxios.post('initiate-add-funds/',bodyData).then((res)=>{return res.data})

    //     let cashfree;    
    //     cashfree = await load({
    //         mode: process.env.REACT_ENV == "production"?"production":"sandbox"
    //     });
    //     let checkoutOptions = {
    //         paymentSessionId: data.payment_session_id,
    //         redirectTarget: "_modal",
    //     };
    //     cashfree.checkout(checkoutOptions).then((result) => {
    //         if(result.error){
    //             // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
    //             console.log("User has closed the popup or there is some payment error, Check for Payment Status");
    //             console.log(result.error);
    //         }
    //         if(result.redirect){
    //             // This will be true when the payment redirection page couldnt be opened in the same window
    //             // This is an exceptional case only when the page is opened inside an inAppBrowser
    //             // In this case the customer will be redirected to return url once payment is completed
    //             console.log("Payment will be redirected");
    //         }
    //         if(result.paymentDetails){
    //             // This will be called whenever the payment is completed irrespective of transaction status
    //             console.log("Payment has been completed, Check for Payment Status");
    //             console.log(result);
    //             handlePaymentSuccess(data);

    //         }
    //     });
    // }

    const handlePaymentSuccess = async(response)=>{
        let bodyData = new FormData();
        bodyData.append("response", JSON.stringify(response));

        CustomAxios.post('add-funds/',bodyData).then((res)=>{
            if(res.status == 200){
                dispatch(updateSnackBarSeverity('success'))
                dispatch(updateSnackBarMessage(res.data.message))
                dispatch(updateSnackBarOpen(true))
            }
            else{
                dispatch(updateSnackBarSeverity('error'))
                dispatch(updateSnackBarMessage(res.data.message))
                dispatch(updateSnackBarOpen(true))
            }
        })
    }

    const handleTransfer = (val)=>{
        CustomAxios.post('transfer-funds/',{
            amount:Transferamt,
            recipient_username: val === 'me'?userreponse?.user.username:ReUsername,
            user_id:userid,
            tf:val
        }).then((res)=>{
            if(res.status == 200){

                dispatch(updateSnackBarSeverity('success'))
                dispatch(updateSnackBarMessage(res?.data?.message))
                dispatch(updateSnackBarOpen(true))
            }
            else{
                dispatch(updateSnackBarSeverity('error'))
                dispatch(updateSnackBarMessage(res.data.message))
                dispatch(updateSnackBarOpen(true))
            }
        }).catch((err)=>{
            // console.log(err.response)
            dispatch(updateSnackBarSeverity('error'))
            dispatch(updateSnackBarMessage(err?.response?.data?.message?.toString()))
            dispatch(updateSnackBarOpen(true))
        })
        
        setIsMCTransferClicked(false)
        setIsMETransferClicked(false)
        setTransferModalOpen(false)
        setTransferAmt(0.00)
        setRecUsername(null)
    }
const setTransferReferalModalHandle=(closeStatus)=>{
    // console.log(closeStatus,transferReferalClose)
    if(!closeStatus){
        setTransferReferalModal(false)
        // dispatch(updateTranferReferralclose(false))
        setRestrictClose(false)
    }
    else{
        setRestrictClose(true)
    }
   
}
    return (
        <Box sx={{ bgcolor: isMobile ? "#fff" : "grey.100",minHeight: "", display: "flex", alignItems: "start", justifyContent: "start" }}>
            <Layout />
            <MessageBar />

            <Box sx={{ alignSelf: "start", marginTop: "140px",height:'130%', marginBottom: "100px", justifySelf: "center", display: "flex", justifyContent: "center", width: "100%", gap: "36px" }}>
            {isTabletHori && <Paper sx={{ height: "max-content",width:"20%",position:"sticky",top:"140px" }} elevation={1}>
            {SideBarList('left')}
        </Paper>}
        <Box elevation={1} sx={{ width: {xs:"100%",md:"95%",lg:"65%"}, height:isLaptop?"175%":"450%", display: "flex", flexDirection: "column", gap:{ xs:"0px ",md:"36px"}, overflowY:"auto", "&::-webkit-scrollbar": {width: "0px",}, scrollbarWidth: "none", msOverflowStyle: "none", "::-webkit-scrollbar-track":{backgroundColor: "transparent",} }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: isLaptop?"24px":"0px", padding: "24px", width:"100%", height:isLaptop?"194px":"375px", justifyContent:isLaptop?"normal":"center", alignItems:isLaptop?"normal":"center"}} component={isLaptop ? Paper : Box}>
                <Box sx= {{ display: "flex", flexDirection:isLaptop?"row":"column", justifyContent:isLaptop?"space-between":"center",alignItems:isLaptop?"normal":"center", gap:isLaptop?"":"24px", }}>
                    <Box sx={{ display:"flex", flexDirection:"column", gap:"16px", alignItems:isLaptop?"normal":"center", justifyContent:isLaptop?"normal":"center", textAlign:isLaptop?"":"center"}}>
                    <Box sx={{ display:"flex", flexDirection:isLaptop?"row" :"column", gap:isLaptop?"0px":"20px", alignItems:isLaptop?"normal":"center"}}>
                    {(profile||imageurl)&&imagestatus ? <img src={profile||imageurl} style = {{width:"72px",height:"72px",objectFit:"cover",background:"#CBD5E1",border:"none", borderRadius:"36px"}} alt='Profile' onError={(e) => {setImageStatus(false)}}  />:<Avatar {...stringAvatar(capitalize(first_name)+" "+capitalize(last_name))}  />}
                    <Box sx={{ display:"flex", flexDirection:"column", marginLeft:isLaptop?"32px":"0px", gap:"3px", alignItems:isLaptop?"normal":"center"}}>
                    <Box sx={{ display:"flex", flexDirection:"row", gap:"3px", alignItems:isLaptop?"normal":"center" }}>
                    <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">{capitalize(first_name)} {capitalize(last_name)}</TranslateText>
                    {parseFloat(userreponse?.pv/0.6)>=89000.00 && <img src={parseFloat(userreponse?.pv/0.6)>=89000.00&&parseFloat(userreponse?.pv/0.6)<178000.00?AmbIcon:parseFloat(userreponse?.pv/0.6)>=178000.00&&parseFloat(userreponse?.pv/0.6)<355000.00?GoldAmbIcon:parseFloat(userreponse?.pv/0.6)>=355000.00&&parseFloat(userreponse?.pv/0.6)<709000.00?AmbIcon:parseFloat(userreponse?.pv/0.6)>=709000.00&&parseFloat(userreponse?.pv/0.6)<900000.00?DiaIcon:parseFloat(userreponse?.pv/0.6)>=900000.00&&parseFloat(userreponse?.pv/0.6)<1770000.00?DiaIcon:parseFloat(userreponse?.pv/0.6)>=1770000.00?CrownIcon:null} style={{width:"18px", height:"18px"}}/>}
                    {((parseFloat(userreponse?.pv/0.6)>=355000.00&&parseFloat(userreponse?.pv/0.6)<709000.00) || (parseFloat(userreponse?.pv/0.6)>=900000.00&&parseFloat(userreponse?.pv/0.6)<1770000.00) || (parseFloat(userreponse?.pv/0.6)>=3542000.00)) && <img src={parseFloat(userreponse?.pv/0.6)>=355000.00&&parseFloat(userreponse?.pv/0.6)<709000.00?AmbIcon:parseFloat(userreponse?.pv/0.6)>=900000.00&&parseFloat(userreponse?.pv/0.6)<1770000.00?DiaIcon:parseFloat(userreponse?.pv/0.6)>=3542000.00?CrownIcon:null} style={{width:"18px", height:"18px"}}/>}
                    {parseFloat(userreponse?.pv/0.6)>=8855000.00 && <img src={parseFloat(userreponse?.pv/0.6)>=8855000.00?CrownIcon:null} style={{width:"18px", height:"18px"}}/>}
                    </Box>
                    <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.700">User Name : { !userreponse?.user?.username? "-":userreponse?.user?.username}</TranslateText>
                    <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.700">Member ID : { !userreponse?.user?.mid? "-":userreponse?.user?.mid}</TranslateText>
                    {city!="" || country!="" ? <TranslateText sx={{ fontWeight: 400, fontSize: "10px" }} color="grey.700">Member since: { !userreponse?.user?.date_joined? "-":formatdate(userreponse?.user?.date_joined)} | {city}, {country}</TranslateText>:
                    <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.700">Member since: { !userreponse.user?.date_joined? "-":formatdate(userreponse?.user?.date_joined)} </TranslateText>}
                    </Box>
                    </Box>
                    <Button variant="contained" sx={formStyle.button} onClick={()=>handleCopyurl()}>
                    <IconButton sx={{color:"primary.contrastText"}}>
                    <LinkIcon />
                </IconButton>Sponsor Referral Link</Button>
                </Box>
                <Box sx={{ display:"flex", flexDirection:"column", height:"70px", gap:"4px", alignItems:"center" }}>
                    <Box sx={{ display:"flex", flexDirection:"column",  height:"34px", gap:"2px",width:"100%" }}>
                        <Box sx={{ display:"flex", flexDirection:"row", height:"20px", justifyContent:"space-between"}}>
                            <TranslateText sx={{ fontWeight: 700, fontSize: "14px", lineHeight:"20px"}} color="grey.900">{!userreponse?.pv?'--.--':getMemberLevel(userreponse?.pv/0.6, "level")}</TranslateText>
                            <TranslateText sx={{ fontWeight: 700, fontSize: "14px" , lineHeight:"16px" }} color="#00E471">{!userreponse?.pv?'--.--':getMemberLevel(userreponse?.pv/0.6, "percent")?.toFixed(2)}%</TranslateText>
                        </Box>
                    <Box sx={{ display:"flex", flexDirection:"column", width:"100%" ,height:"12px", gap:"10px", background:"#EDF1F9", borderRadius:"6px" }}>
                            <Box sx={{ display:"flex", flexDirection:"column", width:getMemberLevel(userreponse?.pv/0.6, "remwidth"), height:"12px", borderRadius:"6px", background:"#00E471"}}></Box>
                        </Box>
                    </Box>
                    {parseFloat(userreponse?.pv/0.6)>=8855000.00?<TranslateText sx={{ fontWeight: 400, fontSize: "11px" , lineHeight:"16px", height:"32px" }} color="grey.700">Congratulations </TranslateText>
                    :userreponse?.user?.referral_code!=undefined?<TranslateText sx={{ fontWeight: 400, fontSize: "12px" , lineHeight:"16px", height:"32px" }} color="grey.700">Enter Referral Code to activate your account as Member.</TranslateText>:<TranslateText sx={{ fontWeight: 400, fontSize: "12px" , lineHeight:"16px", height:"32px" }} color="grey.700">To become an {getMemberLevel(userreponse?.pv/0.6, "prevlevel")}, you need {!userreponse?.pv?"--.--":addCurrencyAndCommafy((getMemberLevel(userreponse?.pv/0.6, "remamt")))}.</TranslateText>}
                </Box>
                </Box>
                    </Box>
                    {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", padding: "24px", width:"100%", }} component={isLaptop ? Paper : Box}>
                    <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">Overview</TranslateText>
                    <Grid container  sx={{ display: "flex", flexDirection:{xs:"column",sm:"row"},alignItems:"center", justifyContent:"center", width:"100%", overflowX:"auto", "&::-webkit-scrollbar": {width: "0px",}, scrollbarWidth: "none", msOverflowStyle: "none", "::-webkit-scrollbar-track":{backgroundColor: "transparent",} }}>
                    {/* <Tooltip title="Coming Soon" arrow> 
                    <Grid item xs={12} sm={6} md={4} sx={{padding:"8px",width:"100%"}}>
                     <Grid  sx={{ display:"flex", flexDirection:"column", height:"88px", width:"100%",justifyContent:"center", borderRadius:"8px", border:"1px solid #5BB1FF", background:"#E9F1FF", padding:"12px 18px 12px 18px", gap:"24px",opacity:"1", cursor:"pointer"}} disabled={true}>
                            <Box sx={{ display:"flex", flexDirection:"row", height:"64px", gap:"24px", alignItems:"center"}} >
                                <img src={WalletIcon} style={{width:"32px", height:"32px"}}/>
                                <Box sx={{ display:"flex", flexDirection:"column", height:"64px", width:"100%", gap:"2px"}}>
                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px", lineHeight:"16px" }} color="grey.900">MAYI Pay</TranslateText>
                                <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">₹ {userreponse?.mc?.balance}</TranslateText>
                                <Box sx={{ display:"flex", flexDirection:"row", widht:"100%", height:"16px", gap:"4px", alignItems:"center",justifyContent:"space-between"}}>
                                    {/* <img src={PlusIcon} style={{ width:"9.33px", height:"9.33px"}}/> 
                                    <Button sx={{ fontWeight: 400, fontSize: "11px",color:"#007CC7",maxWidth:"unset",padding:0}} onClick={()=>handlePayment()}>Add Money</Button>
                                    <Button sx={{ fontWeight: 400, fontSize: "11px",color:"#007CC7",maxWidth:"unset",padding:0}} onClick={()=>{setIsMCTransferClicked(true);setTransferModalOpen(true)}}>Transfer</Button>
                                </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                        
                        </Tooltip>
                        <Grid item xs={12} sm={6} md={3} sx={{padding:"8px",width:"100%"}}>
                        <Grid  sx={{ display:"flex", flexDirection:"column", height:"88px",  width:"100%", borderRadius:"8px", border:"1px solid #00E471",  background:"#F5FFF2", padding:"12px 18px 12px 18px", gap:"24px",cursor:"pointer"}}>
                        <Box sx={{ display:"flex", flexDirection:"row", height:"64px", width:"100%", gap:"24px", alignItems:"center"}}>
                                <img src={SalaryIcon} style={{width:"32px", height:"32px"}}/>
                                <Box sx={{ display:"flex", flexDirection:"column", alignItems:"center", height:"64px", width:"100%", gap:"16px"}}>
                                    <Stack sx={{flexDirection:"column",alignItems:"flex-start",width:"100%"}}>
                                        <TranslateText sx={{ fontWeight: 400, fontSize: "12px", lineHeight:"16px" }} color="grey.900">MAYI Net Worth</TranslateText>
                                        <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900"> { !userreponse.pv? "--.--":addCurrencyAndCommafy( userreponse?.accumulated?.withdrawal_available + userreponse?.mc?.balance )}</TranslateText>
                                    </Stack>
                                {/* <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.900">{ !userreponse.pv? "--.--":"("+(parseFloat(userreponse?.pv)).toFixed(2)+" PV)"}</TranslateText> 
                                </Box>
                            </Box>
                        </Grid>
                        </Grid>
                        <Tooltip title="Coming Soon" arrow>
                        <Grid item xs={12} sm={6} md={3} sx={{padding:"8px",width:"100%"}}>
                        <Grid sx={{ display:"flex", flexDirection:"column", height:"88px", width:"100%",borderRadius:"8px", border:"1px solid #FF8D4F",  background:"#FFEDE6", padding:"12px 18px 12px 18px", gap:"24px", cursor:"pointer",opacity:"0.5"}} disabled={true}>
                        <Box sx={{ display:"flex", flexDirection:"row", height:"64px", gap:"24px", alignItems:"center"}}>
                                <img src={AtmIcon} style={{width:"32px", height:"32px"}}/>
                                <Box sx={{ display:"flex", flexDirection:"column", height:"64px", width:"100%", gap:"2px", justifyContent:"center"}}>
                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px", lineHeight:"16px" }} color="grey.900">MAYI Earnings</TranslateText>
                                <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">₹ {userreponse?.accumulated?.withdrawal_available}</TranslateText>
                                {/* <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.900">{"(" + 0.00 + " PV)"}</TranslateText> 
                                <Box sx={{ display:"flex", flexDirection:"row", widht:"100%", height:"16px", gap:"8px", alignItems:"center", justifyContent:"space-between"}}>
                                    <TranslateText sx={{ fontWeight: 400, fontSize: "11px"}} color="#007CC7">Withdraw</TranslateText>
                                    <Button sx={{ fontWeight: 400, fontSize: "11px",color:"#007CC7",maxWidth:"unset",padding:0}}  onClick={()=>{setIsMETransferClicked(true);setTransferModalOpen(true)}}>Transfer</Button>
                                </Box>
                                </Box>
                            </Box>
                        </Grid>
                        </Grid>
                        </Tooltip>
                    </Grid>
                    <Tooltip title="Coming Soon" arrow>
                    <Box sx={{ display:"flex", flexDirection:"column", width:"100%",maxWidth:"100%",  padding:"0px 0px 24px 0px", gap:"16px", opacity:"0.5", cursor:"pointer"}} disabled={true}>
                    <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">Transaction History</TranslateText>
                    <Box sx={{ display:"flex", flexDirection:{xs:"column",sm:"row"}, gap:"16px",alignItems:"center",justifyContent:"space-between"}}>
                    <Box sx={{ display:"flex", flexDirection:"column",width:{xs:"100%",sm:"50%"},   gap:"16px" }}>
                        <Box sx={{ display:"flex", flexDirection:"column",width:"100%" ,border:"1px solid lightgrey", borderRadius:"8px" }}>
                            <Box sx={{ display:"flex", flexDirection:"row", width:"100%",justifyContent:"space-between", padding:"16px", alignItems:"center", borderBottom:"1px solid lightgrey" }} >
                                <Box sx={{ display:"flex", flexDirection:"column", width:"50%", gap:"8px" }}>
                                <Box sx={{ display:"flex", flexDirection:"row", width:"80%", height:"19px", gap:"8px" }}>
                                <TranslateText sx={{ fontWeight: 700, fontSize: "16px", lineHeight:"16px" }} color="grey.900">MAYI-Wallet</TranslateText>
                                <img src={InfoIcon} style={{width:"16px", height:"16px"}}/>
                                </Box>
                                
                                </Box>
                            </Box>
                            
                            <Bar data={wallet_data} options={chartOptions} style={{ width:"100%", height:"250px"}}/>
                        </Box>
                    </Box>
                    <Box sx={{ display:"flex", flexDirection:"column",width:{xs:"100%",sm:"50%"},  gap:"16px" }}>
                        <Box sx={{ display:"flex", flexDirection:"column"  , border:"1px solid lightgrey", borderRadius:"8px" }}>
                            <Box sx={{ display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", padding:"16px", alignItems:"center", borderBottom:"1px solid lightgrey" }} >
                                <Box sx={{ display:"flex", flexDirection:"column", width:"50%", gap:"8px" }}>
                                <Box sx={{ display:"flex", flexDirection:"row", width:"80%", height:"19px", gap:"8px" }}>
                                <TranslateText sx={{ fontWeight: 700, fontSize: "16px", lineHeight:"16px" }} color="grey.900">Withdrawn</TranslateText>
                                <img src={InfoIcon} style={{width:"16px", height:"16px"}}/>
                                </Box>
                                
                                </Box>
                            </Box>
                            {withdraw_data&&
                            <Bar data={withdraw_data} options={chartOptions} style={{ width:"100%", height:"250px"}}/>}
                        </Box>
                    </Box>
                    </Box>
                    </Box>
                    </Tooltip>
                    </Box> */}
                    <GoalStatus />
                    {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", padding: "24px", width:"100%",}} component={isLaptop ? Paper : Box}>
                    <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">Packages</TranslateText>
                    <Stack gap={"24px"} sx={{flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                        {isTablet || isLaptop ?<Stack>
                            <Button disabled={ activeSlide === 0 ? true : false } sx={[{minWidth:"36px",border:activeSlide === 0 ? "1px solid #CBD5E1" : "1px solid #64748B", color: activeSlide === 0 ? "#CBD5E1" :"#64748B", borderRadius:"4px",p:"8px"}]} onClick={()=>{sliderRef.current?.slickPrev()}}><ChevronLeft /></Button>
                        </Stack>:null}
                        {isTablet || isLaptop ? <Stack sx={{width:"calc(100% - 132px)"}}>
                            <Slider ref={sliderRef} {...packageSliderSettings}  >
                                {packagedetails?.length>0 ? packagedetails.map((detail, index) => (
                                    <Box sx={{ display:"flex", flexDirection:"column", maxWidth:"60%",minWidth:"315px", height:"100%",mr:"24px" }}>
                                        <Box sx={{ display:"flex", flexDirection:"column",width:"315px", height:"220px", borderRadius:"8px 8px 0px 0px", border:"1px solid #E5E8F0", background:"#FFFFFF", gap:"12px", justifyContent:"center" }}>
                                            <Box sx={{ display:"flex", flexDirection:"row",width:"280px", height:"24px", justifyContent:"space-between" }}>
                                                <Box sx={{ display:"flex", flexDirection:"column", width:"96px", height:"24px", borderRadius:"0px 12px 12px 0px", padding:"4px 6px 4px 6px", gap:"10px", background:detail.package_status=="Accomplished"?selectPackageColor(detail, "One"):"#FFEDEA" }}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px", lineHeight:"16px" }} color={detail.package_status=="Accomplished"?"#FFFFFF":"#FF5449"}>{detail.package_status}</TranslateText>
                                                </Box>
                                                <Tooltip title="Coming Soon" arrow>
                                                <img src={ThreeDotIcon} style={{width:"3.33px", height:"13.33px", marginTop:"4px", cursor:"pointer"}}/>
                                                </Tooltip>
                                            </Box>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:"100%", height:"121px", gap:"10px", padding:"0px 16px 0px 16px" }}>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:"100%", height:"121px", gap:"8px" }}>
                                                <Box sx= {{ display:"flex", flexDirection:"column", width:"100%", height:"80px", gap:"0px"}}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "16px" }} color="grey.900">{detail.package_type} ({!detail.package_amount?"--.--":addCurrencyAndCommafy(detail.package_amount)})</TranslateText>
                                                <TranslateText sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{userreponse?.orders_pv? 
                                                    ( parseFloat(userreponse.orders_pv[index+1]) > ( parseInt(userreponse?.pk_info?.[index]?.price) * 0.6 ) ? 
                                                        ( parseInt(userreponse?.pk_info?.[index]?.price) * 0.6 ) : userreponse.orders_pv[index+1] )    + "/" + ( parseInt(userreponse?.pk_info?.[index]?.price) * 0.6 ) 
                                                    : 
                                                    "--/--"
                                                }</TranslateText>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.900">(Exclusive of Other Charges)</TranslateText>
                                                </Box>
                                                <Box sx= {{ display:"flex", flexDirection:"column", width:"100%", height:"80px", gap:"0px"}}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "16px" }} color="grey.900">Earnings</TranslateText>
                                                <Box>
                                                <TranslateText sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{!detail.package_amount?"--.--":addCurrencyAndCommafy(detail?.commision_amount)}</TranslateText>
                                                
                                                </Box>
                                                </Box>
                                            </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display:"flex", flexDirection:"column", width:"315px", height:"4px", gap:"10px", background:"#EDF1F9" }}>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:calculateWidth(detail), height:"4px", background:detail.package_status=="Accomplished"?selectPackageColor(detail, "One"):"#FF5449"}}></Box>
                                        </Box>
                                        <Box sx={{ display:"flex", flexDirection:"column",width:"315px", height:"40px", borderRadius:"0px 0px 8px 8px", justifyContent:"center", border:"1px solid #E5E8F0", padding:"12px",background:detail.package_status=="Accomplished"?selectPackageColor(detail, "Three"):"#FFF8F7"}}>
                                            {detail.package_status=="Accomplished"?
                                            <TranslateText sx={{ fontWeight: 400, fontSize: "11px", textAlign:"center" }} color={detail.package_status=="Accomplished"?selectPackageColor(detail, "Two"):"#DE3730"}>Ready for Withdraw</TranslateText>
                                            :<TranslateText sx={{ fontWeight: 400, fontSize: "11px", textAlign:"center" }} color="#DE3730">Activate Withdrawals with a Rs. {detail.package_remaining_amount} purchase of product</TranslateText>}
                                        </Box>
                                    </Box>
                                ))
                                :package_details.map((detail, index) => (
                                    <Box sx={{ display:"flex", flexDirection:"column", width:"100%", height:"100%" }}>
                                        <Box sx={{ display:"flex", flexDirection:"column", height:"220px", borderRadius:"8px 8px 0px 0px", border:"1px solid #E5E8F0", background:"#FFFFFF", gap:"12px", justifyContent:"center" }}>
                                            <Box sx={{ display:"flex", flexDirection:"row",height:"24px", justifyContent:"space-between" }}>
                                                <Box sx={{ display:"flex", flexDirection:"column", width:"96px", height:"24px", borderRadius:"0px 12px 12px 0px", padding:"4px 6px 4px 6px", gap:"10px", background:detail.package_status=="Accomplished"?selectPackageColor(detail, "One"):"#FFEDEA" }}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px", lineHeight:"16px" }} color={detail.package_status=="Accomplished"?"#FFFFFF":"#FF5449"}>{detail.package_status}</TranslateText>
                                                </Box>
                                                <Tooltip title="Coming Soon" arrow>
                                                <img src={ThreeDotIcon} style={{width:"3.33px", height:"13.33px", marginTop:"4px", cursor:"pointer"}}/>
                                                </Tooltip>
                                            </Box>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:"185px", height:"121px", gap:"10px", padding:"0px 16px 0px 16px" }}>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:"170px", height:"121px", gap:"8px" }}>
                                                <Box sx= {{ display:"flex", flexDirection:"column", width:"170px", height:"80px", gap:"0px"}}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "16px" }} color="grey.900">{detail.package_type}</TranslateText>
                                                <TranslateText sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{!detail.package_amount?"--.--":addCurrencyAndCommafy(detail.package_amount)}</TranslateText>
                                                </Box>
                                                <Box sx= {{ display:"flex", flexDirection:"column", width:"170px", height:"80px", gap:"0px"}}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "16px" }} color="grey.900">Earnings</TranslateText>
                                                <Box>
                                                <TranslateText sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{!detail.commision_amount?"--.--":addCurrencyAndCommafy(detail?.commision_amount)}</TranslateText>
                                                
                                                </Box>
                                                </Box>
                                            </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display:"flex", flexDirection:"column",  height:"4px", gap:"10px", background:"#EDF1F9" }}>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:calculateWidth(detail), height:"4px", background:detail.package_status=="Accomplished"?selectPackageColor(detail, "One"):"#FF5449"}}></Box>
                                        </Box>
                                        <Box sx={{ display:"flex", flexDirection:"column", height:"40px", borderRadius:"0px 0px 8px 8px", justifyContent:"center", border:"1px solid #E5E8F0", padding:"12px",background:detail.package_status=="Accomplished"?selectPackageColor(detail, "Three"):"#FFF8F7"}}>
                                            {detail.package_status=="Accomplished"?
                                            <TranslateText sx={{ fontWeight: 400, fontSize: "11px", textAlign:"center" }} color={detail.package_status=="Accomplished"?selectPackageColor(detail, "Two"):"#DE3730"}>Ready for Withdraw</TranslateText>
                                            :<TranslateText sx={{ fontWeight: 400, fontSize: "11px", textAlign:"center" }} color="#DE3730">Activate Withdrawals with a Rs. {detail.package_remaining_amount} purchase of product</TranslateText>}
                                        </Box>
                                    </Box>
                                ))
                                }
                            </Slider>
                            {/* <Box ref={packagesRef} sx={{ position:"relative" ,display: "flex", flexDirection:{xs:"column",sm:"row"}, width:"unset", alignItems:"center",gap:"24px", 
                            overflowX:"hidden", 
                            "&::-webkit-scrollbar":{display:"none"}, scrollBehavior:"smooth",
                            "&::-webkit-scrollbar": {width: "0px",height:"5px"}, WebkitScrollbarTrackColor: 'primary' , msOverflowStyle: "none", "::-webkit-scrollbar-track":{backgroundColor: "primary"}
                            }}>

                            </Box> 
                        </Stack>
                        :
                        <Stack sx={{width:"100%"}}>
                            <Box ref={packagesRef} sx={{ display: "flex", flexDirection:{xs:"column",sm:"row"}, width:"100%", alignItems:"center",gap:"24px", overflowX:"auto", "&::-webkit-scrollbar":{display:"none"}, scrollBehavior:"smooth"
                            // "&::-webkit-scrollbar": {width: "0px",height:"5px"}, WebkitScrollbarTrackColor: 'primary' , msOverflowStyle: "none", "::-webkit-scrollbar-track":{backgroundColor: "primary"}
                            }}>
                                {packagedetails?.length>0 ? packagedetails.map((detail, index) => (
                                    <Box sx={{ display:"flex", flexDirection:"column", maxWidth:"60%",width:"100%",minWidth:"315px", height:"100%" }}>
                                        <Box sx={{ display:"flex", flexDirection:"column",width:"315px", height:"220px", borderRadius:"8px 8px 0px 0px", border:"1px solid #E5E8F0", background:"#FFFFFF", gap:"12px", justifyContent:"center" }}>
                                            <Box sx={{ display:"flex", flexDirection:"row",width:"280px", height:"24px", justifyContent:"space-between" }}>
                                                <Box sx={{ display:"flex", flexDirection:"column", width:"96px", height:"24px", borderRadius:"0px 12px 12px 0px", padding:"4px 6px 4px 6px", gap:"10px", background:detail.package_status=="Accomplished"?selectPackageColor(detail, "One"):"#FFEDEA" }}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px", lineHeight:"16px" }} color={detail.package_status=="Accomplished"?"#FFFFFF":"#FF5449"}>{detail.package_status}</TranslateText>
                                                </Box>
                                                <Tooltip title="Coming Soon" arrow>
                                                <img src={ThreeDotIcon} style={{width:"3.33px", height:"13.33px", marginTop:"4px", cursor:"pointer"}}/>
                                                </Tooltip>
                                            </Box>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:"100%", height:"121px", gap:"10px", padding:"0px 16px 0px 16px" }}>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:"100%", height:"121px", gap:"8px" }}>
                                                <Box sx= {{ display:"flex", flexDirection:"column", width:"100%", height:"80px", gap:"0px"}}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "16px" }} color="grey.900">{detail.package_type} ({!detail.package_amount?"--.--":addCurrencyAndCommafy(detail.package_amount)})</TranslateText>
                                                <TranslateText sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{userreponse?.orders_pv? 
                                                    ( parseFloat(userreponse.orders_pv[index+1]) > ( parseInt(userreponse?.pk_info?.[index]?.price) * 0.6 ) ? 
                                                        ( parseInt(userreponse?.pk_info?.[index]?.price) * 0.6 ) : userreponse.orders_pv[index+1] )    + "/" + ( parseInt(userreponse?.pk_info?.[index]?.price) * 0.6 ) 
                                                    : 
                                                    "--/--"
                                                }</TranslateText>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.900">(Exclusive of Other Charges)</TranslateText>
                                                </Box>
                                                <Box sx= {{ display:"flex", flexDirection:"column", width:"100%", height:"80px", gap:"0px"}}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "16px" }} color="grey.900">Earnings</TranslateText>
                                                <Box>
                                                <TranslateText sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{!detail.package_amount?"--.--":addCurrencyAndCommafy(detail?.commision_amount)}</TranslateText>
                                                {/* <TranslateText sx={{ fontWeight: 400, fontSize: "14px" }} color="grey.900">{!detail.commision_amount?"--.--":calculatePV(detail)}</TranslateText> 
                                                </Box>
                                                </Box>
                                            </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display:"flex", flexDirection:"column", width:"315px", height:"4px", gap:"10px", background:"#EDF1F9" }}>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:calculateWidth(detail), height:"4px", background:detail.package_status=="Accomplished"?selectPackageColor(detail, "One"):"#FF5449"}}></Box>
                                        </Box>
                                        <Box sx={{ display:"flex", flexDirection:"column",width:"315px", height:"40px", borderRadius:"0px 0px 8px 8px", justifyContent:"center", border:"1px solid #E5E8F0", padding:"12px",background:detail.package_status=="Accomplished"?selectPackageColor(detail, "Three"):"#FFF8F7"}}>
                                            {detail.package_status=="Accomplished"?
                                            <TranslateText sx={{ fontWeight: 400, fontSize: "11px", textAlign:"center" }} color={detail.package_status=="Accomplished"?selectPackageColor(detail, "Two"):"#DE3730"}>Ready for Withdraw</TranslateText>
                                            :<TranslateText sx={{ fontWeight: 400, fontSize: "11px", textAlign:"center" }} color="#DE3730">Activate Withdrawals with a Rs. {detail.package_remaining_amount} purchase of product</TranslateText>}
                                        </Box>
                                    </Box>
                                )):package_details.map((detail, index) => (
                                    <Box sx={{ display:"flex", flexDirection:"column", width:"100%", height:"100%" }}>
                                        <Box sx={{ display:"flex", flexDirection:"column", height:"220px", borderRadius:"8px 8px 0px 0px", border:"1px solid #E5E8F0", background:"#FFFFFF", gap:"12px", justifyContent:"center" }}>
                                            <Box sx={{ display:"flex", flexDirection:"row",height:"24px", justifyContent:"space-between" }}>
                                                <Box sx={{ display:"flex", flexDirection:"column", width:"96px", height:"24px", borderRadius:"0px 12px 12px 0px", padding:"4px 6px 4px 6px", gap:"10px", background:detail.package_status=="Accomplished"?selectPackageColor(detail, "One"):"#FFEDEA" }}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px", lineHeight:"16px" }} color={detail.package_status=="Accomplished"?"#FFFFFF":"#FF5449"}>{detail.package_status}</TranslateText>
                                                </Box>
                                                <Tooltip title="Coming Soon" arrow>
                                                <img src={ThreeDotIcon} style={{width:"3.33px", height:"13.33px", marginTop:"4px", cursor:"pointer"}}/>
                                                </Tooltip>
                                            </Box>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:"185px", height:"121px", gap:"10px", padding:"0px 16px 0px 16px" }}>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:"170px", height:"121px", gap:"8px" }}>
                                                <Box sx= {{ display:"flex", flexDirection:"column", width:"170px", height:"80px", gap:"0px"}}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "16px" }} color="grey.900">{detail.package_type}</TranslateText>
                                                <TranslateText sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{!detail.package_amount?"--.--":addCurrencyAndCommafy(detail.package_amount)}</TranslateText>
                                                </Box>
                                                <Box sx= {{ display:"flex", flexDirection:"column", width:"170px", height:"80px", gap:"0px"}}>
                                                <TranslateText sx={{ fontWeight: 400, fontSize: "16px" }} color="grey.900">Earnings</TranslateText>
                                                <Box>
                                                <TranslateText sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{!detail.commision_amount?"--.--":addCurrencyAndCommafy(detail?.commision_amount)}</TranslateText>
                                                {/* <TranslateText sx={{ fontWeight: 400, fontSize: "14px" }} color="grey.900">{!detail.commision_amount?"--.--":calculatePV(detail)}</TranslateText> 
                                                </Box>
                                                </Box>
                                            </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display:"flex", flexDirection:"column",  height:"4px", gap:"10px", background:"#EDF1F9" }}>
                                            <Box sx={{ display:"flex", flexDirection:"column", width:calculateWidth(detail), height:"4px", background:detail.package_status=="Accomplished"?selectPackageColor(detail, "One"):"#FF5449"}}></Box>
                                        </Box>
                                        <Box sx={{ display:"flex", flexDirection:"column", height:"40px", borderRadius:"0px 0px 8px 8px", justifyContent:"center", border:"1px solid #E5E8F0", padding:"12px",background:detail.package_status=="Accomplished"?selectPackageColor(detail, "Three"):"#FFF8F7"}}>
                                            {detail.package_status=="Accomplished"?
                                            <TranslateText sx={{ fontWeight: 400, fontSize: "11px", textAlign:"center" }} color={detail.package_status=="Accomplished"?selectPackageColor(detail, "Two"):"#DE3730"}>Ready for Withdraw</TranslateText>
                                            :<TranslateText sx={{ fontWeight: 400, fontSize: "11px", textAlign:"center" }} color="#DE3730">Activate Withdrawals with a Rs. {detail.package_remaining_amount} purchase of product</TranslateText>}
                                        </Box>
                                    </Box>
                                ))}

                            </Box>
                        </Stack>
                        }
                        {isTablet || isLaptop ?<Stack>
                            <Button disabled={ isLaptop && activeSlide == 3 ? true :isXLaptop && activeSlide == 3 ? true : activeSlide == 5? true: false } sx={[ 
                                isLaptop && activeSlide == 3 ? {border:"1px solid #CBD5E1",color:"#CBD5E1"} :isXLaptop && activeSlide == 3 ? {border:"1px solid #CBD5E1",color:"#CBD5E1"} : activeSlide == 5? {border:"1px solid #CBD5E1",color:"#CBD5E1"}: {border:"1px solid #64748B",color:"#64748B"},
                                {minWidth:"36px", borderRadius:"4px",p:"8px"}]} onClick={()=>{sliderRef.current?.slickNext()}}
                                >
                                    <ChevronRight/>
                                </Button>
                        </Stack>:null}
                    </Stack>
                    </Box> */}
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", padding: "24px", width:"100%", }} component={isLaptop ? Paper : Box}>
                        <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">Orders and Team</TranslateText>
                        <Box sx={{ display:"flex", flexDirection:{sm:"row",xs:"column"},  alignItems:"center",gap:"24px", overflowX:"auto", "&::-webkit-scrollbar": {width: "0px",}, scrollbarWidth: "none", msOverflowStyle: "none", "::-webkit-scrollbar-track":{backgroundColor: "transparent",}  }}>
                            <Box sx={{ display:"flex", flexDirection:"row", minWidth:"300px",height:"66px", border:"1px solid #F0F4FC", background:"#FFFFFF", gap:"24px", alignItems:"center", padding:"12px 18px 12px 18px" }}>
                                <img src={RecievedIcon} style={{ width:"32px", height:"32px" }}/>
                                <Box sx={{ display:"flex", flexDirection:"column",  height:"42px", gap:"2px" }}>
                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.900">My orders</TranslateText>
                                <TranslateText sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{!userreponse?.order_Count?"--":userreponse?.order_Count}</TranslateText>
                                </Box>
                            </Box>
                            <Box sx={{ display:"flex", flexDirection:"row",  minWidth:"300px",  height:"66px", border:"1px solid #F0F4FC", background:"#FFFFFF", gap:"24px", alignItems:"center", padding:"12px 18px 12px 18px" }}>
                                <img src={OrgIcon} style={{ width:"32px", height:"32px" }}/>
                                <Box sx={{ display:"flex", flexDirection:"column",  height:"42px", gap:"2px" }}>
                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.900">Max Tier Level</TranslateText>
                                <Typography sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{userreponse?.level>9 ?9:userreponse?.level}/9</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display:"flex", flexDirection:"row",minWidth:"300px",  height:"66px", border:"1px solid #F0F4FC", background:"#FFFFFF", gap:"24px", alignItems:"center", padding:"12px 18px 12px 18px" }}>
                                <img src={MeetIcon} style={{ width:"32px", height:"32px" }}/>
                                <Box sx={{ display:"flex", flexDirection:"column", height:"42px", gap:"2px" }}>
                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.900">Team Size</TranslateText>
                                <Typography sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{!userreponse?.team_size?"--":userreponse?.team_size}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display:"flex", flexDirection:"row",  minWidth:"300px", height:"66px", border:"1px solid #F0F4FC", background:"#FFFFFF", gap:"24px", alignItems:"center", padding:"12px 18px 12px 18px" }}>
                                <img src={WageIcon} style={{ width:"32px", height:"32px" }}/>
                                <Box sx={{ display:"flex", flexDirection:"column", height:"42px", gap:"2px" }}>
                                <TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.900">Team Earnings</TranslateText>
                                <TranslateText sx={{ fontWeight: 600, fontSize: "16px" }} color="grey.900">{!userreponse?"--.--":addCurrencyAndCommafy(userreponse?.team_earnings/0.6)}</TranslateText>
                                </Box>
                            </Box>
                        </Box>
                        {/* <Tooltip title="Coming Soon" arrow> */}
                    <Box sx={{ display:"flex", flexDirection:"column", width:"100%",maxWidth:"100%", padding:"0px 0px 24px 0px", gap:"16px", opacity:"1", }} >
                    <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">Performance</TranslateText>
                    <Box sx={{ display:"flex", flexDirection:{xs:"column",sm:"row"}, gap:"16px",alignItems:"center",justifyContent:'space-between'}}>
                    <Box sx={{ display:"flex", flexDirection:"column",gap:"16px",width:{xs:"100%",sm:"50%"}, }}>
                        <Box sx={{ display:"flex", flexDirection:"column",width:"100%", border:"1px solid lightgrey", borderRadius:"8px" }}>
                            <Box sx={{ display:"flex", flexDirection:"row", width:"100%", height:"100px",justifyContent:"space-between", padding:"16px", alignItems:"center", borderBottom:"1px solid lightgrey" }} >
                                <Box sx={{ display:"flex", flexDirection:"row", width:"100%", gap:"8px",alignItems:"center",justifyContent:"space-between" }}>
                                <Box sx={{ display:"flex", flexDirection:"row",  height:"19px", gap:"8px" }}>
                                <TranslateText sx={{ fontWeight: 700, fontSize: "16px", lineHeight:"16px" }} color="grey.900">Top Performer</TranslateText>
                                {/* <img src={InfoIcon} style={{width:"16px", height:"16px"}}/> */}
                                </Box>
                                <Select defaultValue={0} sx={{height:"40px"}} onChange={(e)=>teamPerformanceValues(res.team_growth,e.target.value)}>
                                    {teamPerformance?.map((item,index)=><MenuItem value={index}>{item.name}</MenuItem>)}
                                
    

                                </Select>
                                </Box>
                            </Box>
                            {performanceChart?
                            <Line data={performanceChart} options={chartOptions} style={{ width:"100%", height:"250px"}}/>:<Line data={withdraw_data} options={chartOptions} style={{ width:"100%", height:"250px"}}/>}
                        </Box>
                    </Box>
                    <Box sx={{ display:"flex", flexDirection:"column", gap:"16px",width:{xs:"100%",sm:"50%"}, }}>
                        <Box sx={{ display:"flex", flexDirection:"column", border:"1px solid lightgrey", borderRadius:"8px" }}>
                            <Box sx={{ display:"flex", flexDirection:"row", width:"100%", height:"100px",justifyContent:"space-between", padding:"16px", alignItems:"center", borderBottom:"1px solid lightgrey" }} >
                                <Box sx={{ display:"flex", flexDirection:"column", width:"100%", gap:"8px" }}>
                                <Box sx={{ display:"flex", flexDirection:"row", width:"80%", height:"19px", gap:"8px" }}>
                                <TranslateText sx={{ fontWeight: 700, fontSize: "16px", lineHeight:"16px" }} color="grey.900">Team Growth</TranslateText>
                                {/* <img src={InfoIcon} style={{width:"16px", height:"16px"}}/> */}
                                
                                </Box>
                                {/* hello */}
                                </Box>
                            </Box>
                            {teamChart?
                            <Line data={teamChart} options={chartOptions} style={{ width:"100%", height:"250px"}}/>:<Line data={withdraw_data} options={chartOptions} style={{ width:"100%", height:"250px"}}/>}
                        </Box>
                    </Box>
                    </Box>
                    
                    </Box>
                   {commissions.length>0 &&  <Box>
                    <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">Earnings History</TranslateText>
                    {commissionChart&&
                        <Bar data={commissionChart} options={options} style={{ width:"100%", height:"250px"}}/>}
                    </Box>}
                    {/* </Tooltip> */}
                    <Box sx={{ display:"flex", flexDirection:"column", maxWidth:"100%",  padding:"0px 0px 24px 0px", gap:"16px", opacity:"1", }} >
                    <Box sx={{display:"flex", justifyContent:"space-between"}}>
                        <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">Team Members</TranslateText>         
                    </Box>
                    <Box sx={{display:"flex",flexDirection:"column",gap:"20px",alignItems:"center"}} >
                        <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",}}>
                           
                       
                        {
                            treeBoard.map((item,index)=>{

                            
                                // // console.log(item)
                                return(<><Box sx={{display:"flex",gap:"10px",alignItems:"center",cursor:"pointer"}} onClick={()=>backWard(index,item?.member?.id)}>
                        <Avatar {...teamStringAvatar(capitalize(item?.member?.first_name)+" "+capitalize(item?.member?.last_name))}  />
                        <Box>
                        <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">{capitalize(item?.member?.first_name)} {capitalize(item?.member?.last_name)}</TranslateText>
                        {index!=0 && <Box sx={{display:"flex"}}><TranslateText sx={{ fontSize: "12px" }} color="grey.700">Level: </TranslateText><Typography TranslateText sx={{ fontSize: "12px" }} color="grey.700"> {index}</Typography></Box>}
                        <Box sx={{display:"flex"}}><TranslateText sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.700">Members:</TranslateText><Typography sx={{ fontWeight: 400, fontSize: "12px" }} color="grey.700"> {item?.members?.length}</Typography></Box>
                        </Box>
                        </Box> </>)
 } )
                        }
                         </Box>
                        <Grid container sx={{display:"flex",justifyContent:"center",alignSelf:"center",}}>
                        {team_member?.map(item=> {
                            // // console.log(item)
                        return(<Grid md={4} xs={12} sx={{padding:"10px",cursor:"pointer",display:"flex",justifyContent:"start"}}  item  onClick={()=>third_stage_member(item.member.id)}>
                                 <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                       
                        
                                    {item.imageurl ? <img src={item.imageurl} style = {{width:"72px",height:"72px",objectFit:"cover",background:"#CBD5E1",border:"none", borderRadius:"36px"}} alt='Profile'/>:<Avatar {...teamStringAvatar(capitalize(item.member?.first_name)+" "+capitalize(item.member?.first_name))}  />}
                                    <Box>
                                    
                                    <TranslateText sx={{fontSize:"14px",}} >{capitalize(item.member?.first_name)+" "+capitalize(item.member?.last_name)} {item.member?.username? `(${item.member?.username})`:"" } </TranslateText>
                                    {/* <TranslateText sx={{fontSize:"14px",color:"#6F7883"}}> User Name: <span style={{color:"#000"}}>{item.member?.username&&}</span> </TranslateText> */}
                                    <Box sx={{display:"flex"}}><TranslateText sx={{fontSize:"14px",color:"#6F7883"}}> Level: </TranslateText> <Typography sx={{fontSize:"14px",color:"#6F7883"}}>{treeBoard?.length}</Typography></Box>
                                    <Box sx={{display:"flex"}}><TranslateText  sx={{fontSize:"14px",color:"#6F7883"}}>Members:</TranslateText> <Typography sx={{fontSize:"14px",color:"#6F7883"}}>{item?.team_count}</Typography></Box>
                                    {secondLevel&&
                                    <TranslateText sx={{fontSize:"14px",color:"#6F7883"}}>Mobile No: <span style={{color:"#000"}}>{item?.member?.mobileno?item?.member?.mobileno:"-"}</span></TranslateText>}
                                    </Box>


                                </Box>
                            </Grid>)}
                        )}

                           


                        </Grid>
                    </Box>
                    </Box>
                    
                    </Box>
                </Box>
        </Box>
        
        </Box>
    )
}
export default Dashboard