import {Box,Typography} from "@mui/material"
import { useDispatch } from "react-redux";
import { updateCartSideBar } from "../redux/active_bar";
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useSelector} from "react-redux";
import { useEffect,useState } from "react";
import { useMediaQuery } from "react-responsive"
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { landingPage_styles } from '../landing_page/landing_page';
import { TranslateText } from "../utils/googletranslator";
import { CalculateProductPrice } from "../utils/price";
const CartSideMount=()=>{

    const dispatch = useDispatch()
    const [total,setTotal]=useState(0.00)
    // const [admin, setAdmin] = useState(false)
    const admin=useSelector(state=>state.user.role) 
    const cart=useSelector(state=>state.product.cart)
    const authtoken=localStorage.getItem("authtoken")
    const member=useSelector(state=>state.user.member)
    useEffect(() => {
        const calculateTotal = () => {
            let totalPrice = 0;
            cart.forEach(item => {
                // // console.log(item)
                // const price = authtoken ? parseFloat(item.product?.selling_price) + Math.round(item.product?.selling_price * (item.product?.tax/100) ) : parseFloat(item.product.price) + Math.round(item.product?.price * (item.product?.tax/100) );
                const price = CalculateProductPrice({authtoken:authtoken,member:member,item:item,lineStrike:false})
                // console.log(price)
                totalPrice += parseFloat(price) * item.quantity;
            });
            setTotal(parseFloat(totalPrice).toFixed(2));
        };
        calculateTotal();
    }, [cart, authtoken,member]);
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });

   return <Box>

 {isLaptop&&<Box onClick={() => dispatch(updateCartSideBar(true))} sx={{ position: "fixed", top: "50%", right: 0, bgcolor: "rgba(39, 161, 255, 1)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "8px",padding:"14px 12px 12px" ,"&:hover":{bgcolor:"primary.light",cursor:"pointer"},zIndex:5,...landingPage_styles.buttonAnimation,overflow:"hidden"}}>
                        <Box sx={{ display: "flex",alignItems:"center",gap:"8px" }}><ShoppingCartOutlinedIcon sx={{ color: "#fff",height:"14",width:"16px" }} />  <TranslateText sx={{ color: "#fff",fontSize:"14px",fontWeight:"600" }} >{cart.length} Item</TranslateText></Box>
                        <Box sx={{ padding: "8px", bgcolor: "#fff", borderRadius: "5px",width:"100%",fontSize:"14px",fontWeight:"600",lineHeight:"20px" }}><CurrencyRupeeIcon sx={{fontSize:"14px"}}/>{total}</Box>
                    </Box>}  </Box>
}

export default CartSideMount