import { Alert, Box, Button, Divider, InputBase, InputLabel, MenuItem, OutlinedInput, Select, Skeleton, Snackbar, Stack, Tab, Tabs, Typography, } from '@mui/material';
import { updateBPIntialState } from "../redux/bundleProduct"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { Link, useNavigate } from 'react-router-dom';
import CustomAxios from '../utils/CustomAxios';
import { Delete, DeleteOutline, Edit, EditNote, Filter, Search } from '@mui/icons-material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from '../redux/snackbar';



const table_styles={
    header:{ fontSize:"14px",fontWeight:500,lineHeight:"20px",borderTop:"1px solid #E2E8F0",minWidth:"150px"},
    body:{padding:"10px 24px"}
}


const BundleProducts = ()=>{

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const bundles = useSelector(state=>state.bundles.bundles)
    const [Filters,setFilters] = useState({
        search:"",
        status:"",
        category:""
    })
    const [isloading,setIsloading] = useState(false)
    const [filteredProducts,setFilteredProducts] = useState(null)
    const getBundles = ()=>{
        CustomAxios.get('bundles/')
          .then((res) => {
            // console.log("--------------",res)
            if (res.data) {
              dispatch(updateBPIntialState({key:"bundles",value:res.data}))
    
            }
          })
          .catch(err => console.error(err))
    }

    const handleDeleteBundle = (e,b_id)=>{
      setIsloading(true)
      CustomAxios.delete('bundles/'+b_id).then((response)=>{
        let res = response.data
        if(response.status === 204){
          dispatch(updateSnackBarMessage('Deleted successfully.'))
          dispatch(updateSnackBarSeverity('success'))
          getBundles()
        }
        else{
          dispatch(updateSnackBarMessage('Deletion failed.'))
          dispatch(updateSnackBarSeverity('error'))
        }
        dispatch(updateSnackBarOpen(true))
        setIsloading(false)
      }).catch((err)=>{
        setIsloading(false)
      })
    }

    const handlePlaceholder = (val,placeholder)=>{
      if(val === '' || val ===  undefined){
        return <Typography sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</Typography>
      }
      return val?"Publish":"Draft"
    }

    useEffect(()=>{
      let isFiltered = false
      let data = ""
      // if(Filters.category && Filters.status){
      //   data = bundles?.filter((e)=>e.status === Filters.status && e.category === Filters.category)
      //   isFiltered = true
      // }
      // else if(Filters.category){
      //   data = bundles?.filter((e)=>e.category === Filters.category)
      //   isFiltered = true
      // }
      
      if(Filters.status || Filters.status === false){
        
        data = bundles?.filter((e)=>e.status===Filters.status)
        isFiltered = true
      }
      else if(Filters.search?.trim()){
        data = bundles.filter((e)=>e?.name?.toLowerCase().includes(Filters?.search?.toLowerCase()) || e?.sku?.toLowerCase().includes(Filters?.search?.toLowerCase()) )
      }
      else if( (!Filters.category && !Filters.status) || (!Filters.search && !Filters.category && !Filters.status) ){
        data = null
      }
  
      if(Filters.search?.trim() && isFiltered && filteredProducts?.length >=1 ){
        data = data.filter((e)=>e?.name?.toLowerCase().includes(Filters?.search?.toLowerCase()) || e?.sku?.toLowerCase().includes(Filters?.search?.toLowerCase()) )
      }
      setFilteredProducts(data)
  
    },[Filters])

    useEffect(()=>{
        // if(bundles.length === 0){
            getBundles()
        // }
    },[])

    return(
        <>
            <Stack gap={'20px'} sx={{p:"20px",border:"1px solid #FFFFFF", borderRadius:"8px"}}>
                <Stack direction={'row'} gap={'12px'}>
                    <Stack sx={{alignItems:"center",justifyContent:"center",width:"100%"}}>
                        <Typography sx={{fontWeight:500,fontSize:"24px",lineHeight:"32px"}}>{bundles.length}</Typography>
                        <Typography sx={{fontWeight:400,fontSize:"14px",lineHeight:"20px"}}>Total Products</Typography>
                    </Stack>
                    <Divider orientation='vertical' sx={{border:"1px solid #F0F4FC"}}/>
                    <Stack sx={{alignItems:"center",justifyContent:"center",width:"100%"}}>
                        <Typography sx={{fontWeight:500,fontSize:"24px",lineHeight:"32px"}}>{bundles?.filter((e)=>e.status === true)?.length}</Typography>
                        <Typography sx={{fontWeight:400,fontSize:"14px",lineHeight:"20px"}}>Published</Typography>
                    </Stack>
                    <Divider orientation='vertical' sx={{border:"1px solid #F0F4FC"}}/>
                    <Stack sx={{alignItems:"center",justifyContent:"center",width:"100%"}}>
                        <Typography sx={{fontWeight:500,fontSize:"24px",lineHeight:"32px"}}>{bundles?.filter((e)=>e.status === false)?.length}</Typography>
                        <Typography sx={{fontWeight:400,fontSize:"14px",lineHeight:"20px"}}>Draft</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack gap={'24px'} sx={{overflow:"hidden"}}>
              <Stack direction={'row'} sx={{justifyContent:"space-between",alignItems:"center"}}>
                <Stack direction={'row'} gap={'20px'}>
                  <InputBase inputProps={{className:'product-search'}} value={Filters.search} startAdornment={<Search sx={{color:"#53AEFF",}}/>} placeholder='Product name' onChange={(e)=>{setFilters({...Filters,search:e.target.value})}} sx={{p:"8px 12px",gap:"8px",border:"1px solid #CBD5E1",borderRadius:"4px",height:"36px",width:"298px", }}/>
                  <Select displayEmpty value={Filters.status} sx={{gap:"8px",borderRadius:"4px",height:"36px",width:"140px","& .MuiSelect-select":{display:"flex",alignItems:"flex-end"},"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"}}} onChange={(e)=>setFilters({...Filters,status:e.target.value})} renderValue={(val)=>handlePlaceholder(val,'Status')}>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value={true}>Publish</MenuItem>
                    <MenuItem value={false}>Draft</MenuItem>
                  </Select>
                  {/* <Select displayEmpty value={Filters.category} placeholder='Category' sx={{gap:"8px",borderRadius:"4px",height:"36px",width:"140px","& .MuiSelect-select":{display:"flex",alignItems:"flex-end"}, "& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"} }}onChange={(e)=>setFilters({...Filters,category:e.target.value})} renderValue={(val)=>handlePlaceholder(val,'Category')} >
                  <MenuItem value="">All</MenuItem>
                  {
                    productCategory?.map((type,type_id)=>{
                        return(
                            <MenuItem key={type_id} value={type} sx={{textTransform:"capitalize"}}>{type}</MenuItem>
                        )
                    })
                  }
                  </Select> */}
                </Stack>
                <Button onClick={()=>{dispatch(updateBPIntialState({key:"bundle",value:{}}))}} sx={{p:"8px 12px",borderRadius:"4px","&:hover":{color:"#fff"}}} variant="contained" component={Link} to="/bundle/new" >+ Create Bundle</Button>
              </Stack>

            <DataTable value={filteredProducts ? filteredProducts :bundles}>
                <Column style={{...table_styles.header,minWidth:"305px",wordBreak:"break-word"}} field="name" header="Product" bodyStyle={table_styles.body} body={(rowData)=>{
                  return <Stack direction={'row'} gap={'16px'} sx={{alignItems:"center"}}>
                      {/* <Box component={'img'} src={rowData.cover_details.original} sx={{width:"40px",height:"40px"}}/> */}
                      <Typography>{rowData.name}</Typography>
                  </Stack>
                }}></Column>
                <Column style={table_styles.header} field="slug_name" header="SKU#" bodyStyle={table_styles.body} ></Column>
                
                <Column style={table_styles.header} field="products_count" header="No of products" bodyStyle={table_styles.body} ></Column>
                
                <Column style={table_styles.header} field="price" header="Price" bodyStyle={table_styles.body} sortable ></Column>
                {/* <Column style={table_styles.header} field="selling_price" header="Sell Price" bodyStyle={table_styles.body} sortable ></Column>  */}
                <Column style={table_styles.header} field="out_of_stock" header="Out of stock" bodyStyle={table_styles.body} body={(rowData)=>{
                  return<Stack sx={{p:"4px 8px",bgcolor:rowData.out_of_stock?"#FF5449":"#00C561",borderRadius:"4px",width:"fit-content"}}>
                      <Typography sx={{color:"#FFFFFF",textTransform:"capitalize"}}>{rowData.out_of_stock?"Out of stock":"In stock"}</Typography>
                    </Stack>
                  }} sortable ></Column>
                <Column style={table_styles.header} field="status" header="Status" bodyStyle={table_styles.body} sortable body={(rowData)=>{
                  return<Stack sx={{p:"4px 8px",bgcolor:rowData.status?"#00C561":"#FF5449",borderRadius:"4px",width:"fit-content"}}>
                    <Typography sx={{color:"#FFFFFF",textTransform:"capitalize"}}>{rowData.status?"Publish":"Draft"}</Typography>
                  </Stack>
                }} ></Column>
                <Column style={table_styles.header} header="Actions" bodyStyle={table_styles.body} body={(rowData)=>{
                  return <Stack direction={'row'} gap={'16px'}>
                    <EditNote sx={{color:"#53AEFF",cursor:"pointer"}} onClick={()=>navigate(`/bundle/${rowData.id}`)}/>
                                {isloading?
                                  <Skeleton><Delete sx={{color:"red",cursor:"pointer"}} /></Skeleton>
                                :<DeleteOutline sx={{color:"#FF5449",cursor:"pointer"}} onClick={(e)=>handleDeleteBundle(e,rowData.id)}/>}
                  </Stack>
                }}></Column>
            </DataTable>

            </Stack>
        </>
    )
}


export default BundleProducts