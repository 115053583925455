import { createSlice } from "@reduxjs/toolkit";


let initialState = {
    message:"",
    // severity:"",
    open:false
    
};


let SnackBar = createSlice({
    name: "congrats",
    initialState: initialState,
    reducers: {
        updateMessage:(state, { type, payload }) => {
            
            state.message = payload
        },
       
        updateOpen:(state, { type, payload }) => {
            console.log(payload)
            
            state.open = payload
        },
    }
    })

    export let { 
        updateMessage,updateOpen
    } = SnackBar.actions
    
    
    
    export default SnackBar.reducer;