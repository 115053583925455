
import { Box, Paper, Typography, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Modal, Button, InputBase } from "@mui/material"
import package_config from "../assets/adminSidebar/package_config.svg"
import { useEffect, useState } from "react"
import CustomAxios from "../utils/CustomAxios"
import { useSelector } from "react-redux"
import { formStyle } from "./inventoryForm"
import { EditNote} from '@mui/icons-material';
import { useDispatch } from "react-redux"
import { updateSnackBarMessage ,updateSnackBarOpen,updateSnackBarSeverity} from "../redux/snackbar"
import MessageBar from "../modules/messageBar"
const PackageComponent = () => {
    let id = useSelector(state => state.user.id)
    let [packages, setPackages] = useState([])
    const [packageForm, setPackageForm] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState({})
    const [validation, setValidation] = useState(true)
    const [isLaoding, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})

    const dispatch=useDispatch()
    useEffect(() => {
        recoverPackage()

    }, [])
    let selectPackage = (item) => {
        // console.log(item)
        setErrors({})
        setSelectedPackage(item)
        setPackageForm(true)
        setIsLoading(false)


    }
    let cancelForm = () => {
        setSelectedPackage({})
        setPackageForm(false)

    }
    let handleSubmit = async() => {
        let payload=selectedPackage
        let valid=true
        let newError={}
        payload.user=id
        // console.log(selectedPackage,!selectedPackage?.name.trim())
        setIsLoading(true)
        if (!selectedPackage?.name.trim()) {

            // // console.log("it s happening")
            newError.name = "Please enter package name here"
            valid = false
           
        }
        if (!selectedPackage?.min_value) {
            // // console.log("it s happening")
            newError.min_value = "Please enter min value"
            valid = false
            
        }
        if (!selectedPackage?.max_value) {
            // // console.log("it s happening")
            newError.max_value = "Please enter max value"
            valid = false
           
        }
        if (!selectedPackage?.commission_percentage) {
            // // console.log("it s happening")
            newError.commission_percentage = "Please enter commission percentage"
            valid = false
            
        }
        if (!selectedPackage?.pv_percentage) {
            // // console.log("it s happening")
            newError.pv_percentage = "Please enter PV percentage"
            valid = false
            
        }
        if (!selectedPackage?.withdraw_avail) {
            // // console.log("it s happening")
            newError.withdraw_avail = "Please enter withdraw value"
            valid = false
            
        }
        if (!selectedPackage?.tds) {
            // // console.log("it s happening")
            newError.tds = "Please enter TDS percentage"
            valid = false
            
        }
        // setErrors(newError)
        if (valid && selectedPackage.id){
            await CustomAxios.put('recover_package/',payload).then(res => {
                if(res.data){
                    recoverPackage();setPackageForm(false)
                    dispatch(updateSnackBarMessage('Successfully package updated!'))
                    dispatch(updateSnackBarSeverity('success'))
                    dispatch(updateSnackBarOpen(true))
                    setIsLoading(false)
                }
                else{
                    dispatch(updateSnackBarMessage('Something went wrong!'))
                    dispatch(updateSnackBarSeverity('error'))
                    dispatch(updateSnackBarOpen(true))
                
                }
            })
                .catch(err => console.log(err))

        }
        else if(valid){
            await CustomAxios.post('package_creation/',payload).then(res => {
                if(res.data){
                    recoverPackage();setPackageForm(false)
                    dispatch(updateSnackBarMessage('Successfully package created!'))
                    dispatch(updateSnackBarSeverity('success'))
                    dispatch(updateSnackBarOpen(true))
                    setIsLoading(false)
                }
                else{
                    dispatch(updateSnackBarMessage('Something went wrong!'))
                    dispatch(updateSnackBarSeverity('error'))
                    dispatch(updateSnackBarOpen(true))
                }
            })
                .catch(err => console.log(err))
        }
        else{
            setErrors(newError)
            setIsLoading(false)
        }
// console.log(selectedPackage)
    }

    const recoverPackage = async () => {
        await CustomAxios.get('/recover_package').then(res => setPackages(res.data.packages)).catch(err => console.log(err))
    }

    // console.log(packages)



    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    return <Box className="background" sx={{ width: { xs: "calc(100% )", sm: "100%" }, p: { xs: "0px", sm: "0px" } }}>
        <MessageBar/>
        <Paper sx={{ padding: "24px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "500" }}>Admin Settings</Typography>

                </Box>
                <Box sx={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Box component={'img'} src={package_config} sx={{ width: "32px" }} />
                        <Typography sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "700" }}>
                            Package Configuration
                        </Typography>
                    </Box>
                    <Button sx={{ height: "3rem", padding: "0rem 1.25rem", bgcolor: "primary.light", "&:hover": { bgcolor: "primary.light", color: "primary.contrastText" }, textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "250px", alignSelf: "flex-start", borderRadius: "5px" }} variant="contained" onClick={() => { setPackageForm(true);selectPackage({  name:"", min_value:"", max_value:"",commission_percentage:"", pv_percentage:"", withdraw_avail:"",tds:""});setErrors({});setIsLoading(false) }}> Create Package</Button>
                </Box>

                <Box>
                    <TableContainer sx={{ bgcolor: "#fff", height: "100%", overflowY: "auto" }} >
                        <Table sx={{}} stickyHeader aria-label="sticky table"  >
                            <TableHead sx={{ backgroundColor: "rgba(226, 232, 240, 1)" }}>
                                <TableRow sx={{ backgroundColor: "rgba(226, 232, 240, 1)" }}>
                                    <TableCell sx={{ fontWeight: "600", textAlign: "left", background: "#E2E8F0" }}>Package Name</TableCell>
                                    <TableCell sx={{ fontWeight: "600", textAlign: "center", background: "#E2E8F0" }}>Package Value ₹ (PV)</TableCell>
                                    
                                    <TableCell sx={{ fontWeight: "600", textAlign: "center", background: "#E2E8F0" }} align="right">PV (%)</TableCell>
                                    <TableCell sx={{ fontWeight: "600", textAlign: "center", background: "#E2E8F0" }} align="right">PV Commission (%)</TableCell>
                                    <TableCell sx={{ fontWeight: "600", textAlign: "center", background: "#E2E8F0" }} align="right">Withdraw Value ₹</TableCell>
                                    <TableCell sx={{ fontWeight: "600", textAlign: "center", background: "#E2E8F0" }} align="right">TDS (%)</TableCell>
                                    <TableCell sx={{ fontWeight: "600", textAlign: "center", background: "#E2E8F0" }} align="right">Created On</TableCell>
                                    <TableCell sx={{ fontWeight: "600", textAlign: "center", background: "#E2E8F0" }} align="right">Created By</TableCell>
                                    <TableCell sx={{ fontWeight: "600", textAlign: "center", background: "#E2E8F0" }} align="right">Actions</TableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {packages.map(item => <TableRow 
                                    // key={prod_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 },}}
                                >

                                    <TableCell sx={{ textAlign: "left" }}>{item.name}</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>{item.min_value} - {item.max_value}</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>{item.pv_percentage}</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>{item.commission_percentage}</TableCell>
                                    
                                    <TableCell sx={{ textAlign: "center" }}>{item.withdraw_avail}</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>{item.tds}</TableCell>

                                    <TableCell sx={{ textAlign: "center" }}>{formatDate(item.created_on)}</TableCell>
                                    <TableCell sx={{  textAlign: "center" }} align="right">{item.updated.username}</TableCell>
                                    <TableCell sx={{  textAlign: "center" }} align="right"><EditNote sx={{color:"#53AEFF",cursor:"pointer"}} onClick={() => selectPackage(item)}/></TableCell>
                                </TableRow>


                                )}

                            </TableBody>
                        </Table>

                    </TableContainer>

                </Box>

            </Box>
            <Modal
                open={packageForm}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description">

                <Box sx={{
                    position: "absolute", top: "50%", left: "50%", transform: 'translate(-50%, -50%)',
                    flexDirection: "column",
                    bgcolor: "#fff",
                    // height: "150px",
                    // maxWidth: "500px",
                    width: { sm: "30%", xs: "100%" },
                }}>
                    <Box sx={{ bgcolor: "#F8F9FF", padding: "20px" }}>
                        <Typography sx={{ fontSize: "16px", fontWeight: "600", }}>
                            {selectedPackage.id ? "Edit" : "Add"}
                        </Typography>

                    </Box>

                    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px", overflow: "scroll" }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "20px",flexDirection:"column" ,justifyContent:"space-between"}}>
                            <Box sx={{alignSelf:"start"}}>
                                <Typography sx={formStyle.label}>Package Name<span style={{ color: "#FF5449" }} >*</span></Typography>
                                <InputBase sx={formStyle.inputBase} value={selectedPackage.name} onChange={(e) => setSelectedPackage({ ...selectedPackage, name: e.target.value })} />
                                <Typography sx={{ color: "error.light", fontSize: "14px", lineHeight: "20px" }}>{errors.name}</Typography>

                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center",gap:"32px" }}>
                                <Box>
                                    <Typography sx={formStyle.label}>Minimum Value<span style={{ color: "#FF5449" }} >*</span></Typography>
                                    <InputBase disabled={selectedPackage.id}sx={formStyle.inputBase} value={selectedPackage.min_value} onChange={(e) => setSelectedPackage({ ...selectedPackage, min_value: e.target.value.replace(/[a-zA-Z]/g, '') })} />
                                    <Typography sx={{ color: "error.light", fontSize: "14px", lineHeight: "20px" }}>{errors.min_value}</Typography>

                                </Box>
                                <Box>
                                    <Typography sx={formStyle.label}>Maximum Value<span style={{ color: "#FF5449" }} >*</span></Typography>
                                    <InputBase disabled={selectedPackage.id} sx={formStyle.inputBase} value={selectedPackage.max_value} onChange={(e) => setSelectedPackage({ ...selectedPackage, max_value: e.target.value.replace(/[a-zA-Z]/g, '') })} />
                                    <Typography sx={{ color: "error.light", fontSize: "14px", lineHeight: "20px" }}>{errors.max_value}</Typography>

                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center",gap:"32px" }}>
                                <Box>
                                    <Typography sx={formStyle.label}>PV Commission (%)<span style={{ color: "#FF5449" }} >*</span></Typography>
                                    <InputBase sx={formStyle.inputBase} value={selectedPackage.commission_percentage} onChange={(e) => setSelectedPackage({ ...selectedPackage, commission_percentage: e.target.value.replace(/[a-zA-Z]/g, '') })} />
                                    <Typography sx={{ color: "error.light", fontSize: "14px", lineHeight: "20px" }}>{errors.commission_percentage}</Typography>

                                </Box>
                                <Box>
                                    <Typography sx={formStyle.label}>PV (%)<span style={{ color: "#FF5449" }} >*</span></Typography>
                                    <InputBase sx={formStyle.inputBase} value={selectedPackage.pv_percentage} onChange={(e) => setSelectedPackage({ ...selectedPackage, pv_percentage: e.target.value.replace(/[a-zA-Z]/g, '')})} />
                                    <Typography sx={{ color: "error.light", fontSize: "14px", lineHeight: "20px" }}>{errors.pv_percentage}</Typography>

                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center",gap:"32px" }}>
                                <Box>
                                    <Typography sx={formStyle.label}>Withdraw value ₹<span style={{ color: "#FF5449" }} >*</span></Typography>
                                    <InputBase sx={formStyle.inputBase} value={selectedPackage.withdraw_avail} onChange={(e) => setSelectedPackage({ ...selectedPackage, withdraw_avail: e.target.value.replace(/[a-zA-Z]/g, '') })} />
                                    <Typography sx={{ color: "error.light", fontSize: "14px", lineHeight: "20px" }}>{errors.withdraw_avail}</Typography>

                                </Box>
                                <Box>
                                    <Typography sx={formStyle.label}>TDS (%)<span style={{ color: "#FF5449" }} >*</span></Typography>
                                    <InputBase disabled={selectedPackage.id} sx={formStyle.inputBase} value={selectedPackage.tds} onChange={(e) => setSelectedPackage({ ...selectedPackage, tds: e.target.value.replace(/[a-zA-Z]/g, '') })} />
                                    <Typography sx={{ color: "error.light", fontSize: "14px", lineHeight: "20px" }}>{errors.tds}</Typography>

                                </Box>
                            </Box>


                        </Box>
                    </Box>

                    <Box sx={{ bgcolor: "#F8F9FF", padding: "20px", display: "flex", justifyContent: "flex-end", gap: "16px" }}>
                        <Button sx={{ textTransform: "none" }} variant="outlined" onClick={cancelForm} >Cancel</Button>
                        <Button sx={formStyle.button} variant="contained" disabled={isLaoding} onClick={handleSubmit}>{selectedPackage.id?'Update':'Create'} </Button>

                    </Box>
                </Box>

            </Modal>

        </Paper>
    </Box>




}

export default PackageComponent