import "./productDetails.css";
import { useEffect, useRef, useState } from "react"
import { Box, Button, Divider, Grid, Skeleton, Stack, Typography } from "@mui/material"
import Layout from "../layout"
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useNavigate, useParams } from "react-router-dom"
import { UseDispatch, useDispatch, useSelector } from "react-redux"
import { updateProduct } from "../redux/products"
import { useMediaQuery } from "react-responsive"
import axios from "axios"
import CustomAxios from '../utils/CustomAxios';
import prodOne from "../assets/prodOne.png"
import { updateCart } from "../redux/products"
import { updateSideBar, updateCartSideBar } from "../redux/active_bar"
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Cards from "../components/cards";
import Footer from "../components/footer";
import Product_list from "../components/product_list_old";
import placeholder from "../assets/placeholder.jpg"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShareIcon from '@mui/icons-material/Share';
import { updateSnackBarMessage, updateSnackBarSeverity, updateSnackBarOpen } from "../redux/snackbar";
import MessageBar from "../modules/messageBar";
import { ArrowBack, ChevronLeft } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import productDetails from "../assets/prodect details/product details.svg"
import Ingredients from "../assets/prodect details/ingredients.svg"
import benefits from "../assets/prodect details/benefits.svg"
import dosage from "../assets/prodect details/dosage.svg"
import storage from "../assets/prodect details/storage.svg"
import productDetailsBlue from "../assets/prodect details/productDetailsBlue.svg"
import IngredientsBlue from "../assets/prodect details/ingredientsBlue.svg"
import benefitsBlue from "../assets/prodect details/benefitsBlue.svg"
import dosageBlue from "../assets/prodect details/dosageBlue.svg"
import storageBlue from "../assets/prodect details/storageBlue.svg"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Carousel } from 'primereact/carousel';
import { Galleria } from 'primereact/galleria';
import { classNames } from "primereact/utils";
import { TranslateText } from "../utils/googletranslator"
import { CalculateProductPrice } from "../utils/price";
const ProductDetails = () => {
    const cartSidebar = useSelector(state => state.sideBar.cartSidebar)
    const cart = useSelector(state => state.product?.cart)
    const [total, setTotal] = useState(0.00)
    const Allproducts = useSelector(state => state.product.Allproducts)
    const product = useSelector(state => state.product.product)
    const authtoken = localStorage.getItem("authtoken")
    const id = useSelector(state => state.user.id)
    const params = useParams()
    const dispatch = useDispatch()
    const [imageError, serImageError] = useState(true)
    const sliderRef = useRef(null);
    const [slideIndex, setSlideIndex] = useState(0)
    const navigate = useNavigate()
    const backgroundColors = ["#DAC7F7", "#FFD3E1", "#D0E0FD", "#D3EDD9", "#E5E7B6"];
    const [galleriaActiveImg,setGalleriaActiveImg] = useState(0)
    const member=useSelector(state=>state.user.member)
    const CustomNextArrow = (props)=>{
        const { className, style, onClick } = props;
        return <button className={className} style={{...style,bgcolor:"transparent",color:"#1E293B"}} onClick={onClick}>
            <ChevronLeft sx={{transform:"rotate(-90deg)"}}/>
        </button>
    }
    const CustomPrevArrow = (props)=>{
        const { className, style, onClick } = props;
        return <button className={className} style={{...style,bgcolor:"transparent",color:"#1E293B"}} onClick={onClick}>
            <ChevronLeft sx={{transform:"rotate(90deg)"}}/>
        </button>
    }
    const settings = {
        centerMode: true,
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        speed: 500,
        // pauseOnHover: true,
        autoplay: true,
        // autoplaySpeed: 1500,
        beforeChange: (cr, next) => setSlideIndex(next),
        // adaptiveHeight: true,
        // variableWidth: true,
        vertical: true,
        // verticalSwiping: false
        nextArrow: <CustomNextArrow/>,
        prevArrow: <CustomPrevArrow/>
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };
    // console.log(product)
    useEffect(() => {
        dispatch(updateProduct([]))
        getproduct()

    }, [params.id])
    const getproduct = async () => {
        const result = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}products/${params.id}`)
        // console.log(result)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        dispatch(updateProduct(result.data))
    }
    const cartCheck = (id) => {
        // // console.log(id)
        // // console.log(cart)
        const filt = cart.filter(item => item.product?.id == id)
        // filt[0].quantity
        if (filt.length > 0) {
            return filt[0].quantity

        }
        else {
            return false
        }

    }
    const updateQuantity = async (object, condition) => {
        let productUpdate=false
        let products = [...cart]
        const newCart = products.map(item => {
            // console.log(item)
            if (condition && item.product?.id == object.id && item.product.inventry_details?.available_quantity>item.quantity) {
                let updated = { ...item }
                productUpdate=true
                updated.quantity += 1
                // // console.log("if condition")
                if (item.id) {
                    CustomAxios.patch(`cart/${item.id}/`, {
                        quantity: updated.quantity,
                        totalprice: updated.quantity * (parseFloat(updated.price) + Math.round(updated?.price * (updated.product?.tax / 100)))
                    })
                    return updated
                }
                else {

                    return updated

                }

            }
            else if (!condition && item.product?.id == object.id) {
                productUpdate=true
                let updated = { ...item }
                updated.quantity -= 1

                if (item.id && updated.quantity >= 1) {
                    CustomAxios.patch(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/${item.id}/`, {
                        quantity: updated.quantity,
                        totalprice: updated.quantity * (parseFloat(updated.price) + Math.round(updated?.price * (updated.product?.tax / 100)))
                    })
                    return updated
                }
                else if (item.id && updated.quantity < 1) {
                    CustomAxios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/${item.id}/`)
                    return updated
                }
                else {

                    return updated

                }


            }
            else if (item.product?.id == object.id){
                // console.log( item.product.inventry_details?.available_quantity, item.quantity)
                if(condition && item.product.inventry_details?.available_quantity<=item.quantity) {
                    productUpdate=false
                } 
                else{
                    productUpdate=true
                }
                return item
               

            }
            else{
                return item
            }

        })
        if (productUpdate){
            dispatch(updateCart(newCart.filter((item) => !item?.quantity < 1)))
            localStorage.setItem("cart", JSON.stringify(newCart.filter((item) => !item?.quantity < 1)))
            // console.log("clipboard open")
            dispatch(updateSnackBarMessage("Successfully Updated the Cart!"))
            dispatch(updateSnackBarSeverity("success"))
    
            dispatch(updateSnackBarOpen(true))
        }
        else{
            dispatch(updateCart(newCart.filter((item) => !item?.quantity < 1)))
            localStorage.setItem("cart", JSON.stringify(newCart.filter((item) => !item?.quantity < 1)))
            // console.log("clipboard open")
            dispatch(updateSnackBarMessage(`You are exceeding the available stock!. You can add only ${object.inventry_details?.available_quantity} quantity to your cart.`))
            dispatch(updateSnackBarSeverity("error"))
    
            dispatch(updateSnackBarOpen(true))
        }
        // console.log(newCart)
        // setProduct(products)
        

    }
    const addCart = (item, quantity) => {
        const addCart = { product: item, quantity: quantity };
        dispatch(updateCart([...cart, addCart]));
        localStorage.setItem("cart", JSON.stringify([...cart, addCart]));
        if (authtoken) {
            handleCart(item, quantity);
        }
        dispatch(updateSnackBarMessage("Successfully Added to Cart!"));
        dispatch(updateSnackBarSeverity("success"));
        dispatch(updateSnackBarOpen(true));
    };
    const handleCart = async (item, quantity) => {
        // console.log(item,quantity,id)

        await CustomAxios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/`, {
            products: item.id,
            quantity: quantity,
            user: id, created_by: id,
            price:item.selling_price,

            totalprice: quantity * (parseFloat(item.selling_price) + Math.round(item.selling_price * (item.tax / 100))),
            updated_by: id
        })
        const response = await CustomAxios.get(`usercart/${id}`,)
        // console.log(response)
        dispatch(updateCart(response.data))
        localStorage.setItem("cart", JSON.stringify(response.data))
    }
    const handleCopyurl = (e) => {
        // console.log("share icon lettter")
        const url = process.env.REACT_APP_URL + `product_details/${e}`
        navigator.clipboard.writeText(url)
            .then(() => {
                // console.log("clipboard open")
                dispatch(updateSnackBarMessage("URL copied to clipboard!"))
                dispatch(updateSnackBarSeverity("success"))
            })
            .catch((error) => {
                dispatch(updateSnackBarMessage(error))
                dispatch(updateSnackBarSeverity("error"))
            });
        dispatch(updateSnackBarOpen(true))

    }


    useEffect(() => {
        const total = cart.reduce((a, b) => a + parseInt((authtoken ? b.product?.selling_price : b.product?.price) * b.quantity), 0);
        setTotal(parseFloat(total).toFixed(2));
    }, [cart, authtoken]);
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(max-width: 900px)' });
    const [productDescription, setproductDescription] = useState({});

    useEffect(() => {
        // Sample HTML string
        const htmlString = product.description

        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
    
        // Extract data
        const paragraphs = Array.from(doc.querySelectorAll('p'));
    
        const contentDetails = paragraphs[0]?.textContent.trim() || '';
        const category = paragraphs[1]?.textContent.replace('Category:', '').trim() || '';
    
        // Extract "May help to" list
        const mayHelpToIndex = paragraphs.findIndex(p => p.querySelector('em')?.textContent.includes('May help to:'));

        let mayHelpTo = [];
        if (mayHelpToIndex !== -1) {
        for (let i = mayHelpToIndex + 1; i < paragraphs.length; i++) {
            const text = paragraphs[i].textContent.trim();
            // Stop when reaching other sections like Ingredients, Recommended Dosage, or Storage
            if (paragraphs[i].innerHTML.includes('<strong>Ingredients:</strong>') ||
                paragraphs[i].innerHTML.includes('<strong>Recommended Dosage:</strong>') ||
                paragraphs[i].innerHTML.includes('<strong>Storage:</strong>')) {
            break;
            }
            if (text.startsWith('•')) {
            mayHelpTo.push(text.replace(/^•\s*/, ''));
            }
        }
        }
    
        // Extract "Ingredients" list
        const ingredientsIndex = paragraphs?.findIndex(p => p?.innerHTML?.includes('<strong>Ingredients:</strong>'));

        let em_regex = "<em[^>]*>(.*?)</em>"
        let in_strong_regex = "<strong[^>]*>Ingredients(.*?)</strong>"
        const ingredientsElement = paragraphs?.find(p => p?.innerHTML?.match(in_strong_regex));
        // const ingredientsElement = paragraphs?.find(p => p?.innerHTML?.toLocaleLowerCase().includes('<strong>Ingredients:</strong>'.toLocaleLowerCase()));
        // const ingredients = ingredientsElement?.innerText?.toLocaleLowerCase().split("ingredients:")?.[1]?.trim()
        const ingredients = ingredientsElement?.innerText?.split("Ingredients:")?.[1]?.trim() || '';
        const ing = ingredients?.split(",")
        // Extract all occurrences of "Recommended Dosage"
        const recommendedDosages = [];
        let inDosageSection = false;
        let currentDosageDetails = [];
        
        // Iterate over paragraphs
        paragraphs.forEach((p, index) => {
            const textContent = p.textContent?.trim();
        
            // Check for the start of a "Recommended Dosage" section
            if (textContent.includes('Recommended Dosage:') || p.innerHTML?.includes('<strong>Recommended Dosage:</strong>')) {
                inDosageSection = true;
                currentDosageDetails = []; // Reset the current dosage details
                // Handle inline dosage details
                if (textContent.includes('Recommended Dosage:') && !p.innerHTML?.includes('<p>')) {
                    const singleLineDosage = textContent.replace('Recommended Dosage:', '')?.trim();
                    if (singleLineDosage) {
                        recommendedDosages.push({ type: 'General', detail: singleLineDosage });
                    }
                }
            } else if (inDosageSection) {
                // Collect dosage details in the section
                if (textContent && !textContent.includes('Recommended Dosage:') && !textContent.includes('Storage:')) {
                    // Check if it includes type and detail
                    if (textContent.includes(':')) {
                        const [type, detail] = textContent.split(':')?.map(s => s.trim());
                        currentDosageDetails.push({ type, detail });
                    } else {
                        // Default to general if no type
                        currentDosageDetails.push({ type: 'General', detail: textContent });
                    }
                } else if (textContent.includes('Recommended Dosage:') || textContent.includes('Storage:')) {
                    // End of the dosage section
                    inDosageSection = false;
                    if (currentDosageDetails.length > 0) {
                        recommendedDosages.push(...currentDosageDetails);
                        currentDosageDetails = [];
                    }
                }
            }
        });
        
        // After the loop, if the section was not properly closed, add remaining details
        if (inDosageSection && currentDosageDetails.length > 0) {
            recommendedDosages.push(...currentDosageDetails);
        }

    
        // Extract storage details
        const storageElement = paragraphs.find(p => p.innerHTML.includes('<strong>Storage:</strong>'));
        const storage = storageElement?.textContent?.replace('Storage:', '')?.trim() || '';
    
        // Convert to JSON format
        const extractedData = {
          content_details: contentDetails,
          category: category,
          may_help_to: mayHelpTo,
          ingredients: ing,
          recommended_dosage: recommendedDosages,
          storage: storage
        };

        setproductDescription(extractedData);
    }, [product]);
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    // console.log(productDescription)
    const [activeSection, setActiveSection] = useState(null);

    // Refs for each section
    const producDetailsRef = useRef(null);
    const ingredientsRef = useRef(null);
    const benefitsRef = useRef(null);
    const dosageRef = useRef(null);
    const storageRef = useRef(null);
    
    const headerHeight = 200; // Height of the sticky header
    
    const handleScroll = () => {
      const scrollPosition = window.scrollY + headerHeight; // Adjust for header height
  
      if (producDetailsRef.current && producDetailsRef.current.offsetTop <= scrollPosition &&
          producDetailsRef.current.offsetTop + producDetailsRef.current.offsetHeight > scrollPosition) {
        setActiveSection('producDetails');
      } else if (ingredientsRef.current && ingredientsRef.current.offsetTop <= scrollPosition &&
                 ingredientsRef.current.offsetTop + ingredientsRef.current.offsetHeight > scrollPosition) {
        setActiveSection('ingredients');
      } else if (benefitsRef.current && benefitsRef.current.offsetTop <= scrollPosition &&
                 benefitsRef.current.offsetTop + benefitsRef.current.offsetHeight > scrollPosition) {
        setActiveSection('benefits');
      } else if (dosageRef.current && dosageRef.current.offsetTop <= scrollPosition &&
                 dosageRef.current.offsetTop + dosageRef.current.offsetHeight > scrollPosition) {
        setActiveSection('dosage');
      } else if (storageRef.current && storageRef.current.offsetTop <= scrollPosition &&
                 storageRef.current.offsetTop + storageRef.current.offsetHeight > scrollPosition) {
        setActiveSection('storage');
      }
    };
  
    const scrollToSection = (ref) => {
      const topOffset = ref.current.offsetTop - headerHeight ; // Adjust for header height
      
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


    const [touchStartEvent,setTouchStartEvent] = useState(null)
    const [touchMoveEvent,setTouchMoveEvent] = useState(null)

    const handleGalleryTouchEnd = (e)=>{
        e.preventDefault()
        if(touchStartEvent?.clientX > touchMoveEvent?.clientX){
            
            let product_len = product?.gallery_details?.original ? JSON.parse(product?.gallery_details?.original)?.length : 0
            if( galleriaActiveImg < product_len - 1 ){
                setGalleriaActiveImg(galleriaActiveImg + 1)
            }
        }
        else if(touchStartEvent?.clientX < touchMoveEvent?.clientX){
            
            if(galleriaActiveImg > 0){
                setGalleriaActiveImg(galleriaActiveImg - 1)
            }
        }
    }


    return (<Box sx={{ display: "flex", flexDirection: "column" }}>
        <Layout />
        <MessageBar />
        <Box sx={{ display: "flex", flexDirection: "column", padding: { xs: "10px 24px", sm: "10px 24px", md: "32px 120px" }, gap: "24px",marginTop:"118px" }}>
            <Box sx={{ display: "flex", gap: "10px", cursor: "pointer" }} onClick={() => navigate("/products")}>
                <ArrowBack /> <TranslateText>Shop our products</TranslateText>
            </Box>
            <Box sx={{ display: "flex", gap: "30px", alignItems: "center",flexDirection: { xs: "column", sm: "column", md: "row" }, width:"100%"  }}>

                {/* {product?.gallery_details?.original && JSON.parse(product?.gallery_details?.original).map((image, index) => (<Box>
                    <Box component="img" src={imageError ? image : placeholder} onError={() => serImageError(false)} sx={{ maxWidth: { md: "496px" }, height: { md: "484px" }, width: "100%" }}></Box>
                </Box>))} */}
                {/* <Stack sx={{maxWidth:"608px",maxHeight:"484px"}}>

                    <Stack className="slider-container" sx={{justifyContent:"center",maxWidth:"100%",height:"100%"}}>
                        <Slider {...settings} >
                                    {product?.gallery_details?.original && JSON.parse(product?.gallery_details?.original).map((image, index) => (
                                        <Box className={index === slideIndex ? "slide slide-active" : "slide"} key={index} >
                                            
                                            <Box component="img"  src={imageError? image:placeholder}  onError={()=>serImageError(false)}
                                            sx={{width:"96px",height:"86px"}} 
                                            ></Box>
                                        </Box>
                                    ))}
                        </Slider> 
                    </Stack>
                </Stack> */}
                {product?.gallery_details?.original && JSON.parse(product?.gallery_details?.original)?.length<4 && (!isMobile && !isLaptop) && <Stack sx={{justifyContent:"flex-start",border: "1px solid #f8f9ff",height:"460px"}}>
                    {product?.gallery_details?.original && JSON.parse(product?.gallery_details?.original).map((image, index) => (
                        <Box className={index === slideIndex ? "slide slide-active" : "slide"} key={index} sx={{cursor:"pointer"}} onClick={()=>setGalleriaActiveImg(index)} >
                            
                            <Box component="img"  src={imageError? image:placeholder}  onError={()=>serImageError(false)}
                                sx={[{width:"96px",height:"86px",borderBottom:"1px solid #f8f9ff",p:"8px",opacity:0.5,"&:hover":{opacity:1}},galleriaActiveImg === index && {border:"1px solid #27a1ff",opacity:1}]} 
                            ></Box>
                        </Box>
                    ))}
                </Stack>}
                                {!product?.gallery_details?.original?
                    <Stack direction={ !isMobile && !isLaptop ?'row':'column-reverse'} sx={{width:{xs:"inherit",md:"unset"},height:"460px",justifyContent:"flex-start",alignItems:"center"}}>

                        <Skeleton component={Box} sx={{width:{xs:"50%",md:"96px"},height:{xs:"10%",md:"100%"},borderBottom:"1px solid #f8f9ff",p:"8px",transform:"none"}}></Skeleton>
                        <Skeleton sx={{width:{xs:"inherit",md:"496px"},height:"100%",p:"24px",border:"1px solid #f8f9ff",transform:"none"}}></Skeleton>
                    </Stack>
                 :   
                    <Galleria
                    activeIndex={galleriaActiveImg}
                    onItemChange={(e)=>setGalleriaActiveImg(e.index)}
                    style={{width:{xs:"inherit",md:"unset"}}}
                    pt={{previousThumbnailButton:({state})=>({className: product?.gallery_details?.original && JSON.parse(product?.gallery_details?.original)?.length <4 && classNames('p-disabled')})}}
                    value={product?.gallery_details?.original && JSON.parse(product?.gallery_details?.original)} 
                    thumbnailsPosition={ !isMobile && !isLaptop ? "left" : "bottom"}
                    thumbnail={(image)=>{ return <Box component="img"  src={imageError? image:placeholder}  onError={()=>serImageError(false)} sx={{width:"96px",height:"96px",borderBottom:"1px solid #f8f9ff",p:"8px"}} ></Box> }} 
                    item={(image)=>{ return <Box component={'img'} onTouchStart={(e)=> setTouchStartEvent(e.touches[0])} onTouchMove={(e)=> setTouchMoveEvent(e.touches[0])} onTouchEnd={(e)=> handleGalleryTouchEnd(e)} src={image} sx={{width:{xs:"350px",md:"496px"},height:{xs:"350px",md:"460px"},p:"24px",border:"1px solid #f8f9ff"}} /> }} 
                    // numVisible={ product?.gallery_details?.original && JSON.parse(product?.gallery_details?.original)?.length>=4 ? 4 : product?.gallery_details?.original && JSON.parse(product?.gallery_details?.original)?.length}
                    numVisible={4}
                    showThumbnails={ isMobile || isLaptop || product?.gallery_details?.original && JSON.parse(product?.gallery_details?.original)?.length<4?false:true}
                    showIndicators={isMobile || isLaptop ? true : false}
                    />
                }



                <Box sx={{ display: "flex", gap: "11px", flexDirection: "column", maxWidth: { xs: "100%", sm: "100%", md: "562px" }, width: "100%" }}>
                    <TranslateText sx={{ fontSize: "14px" , fontWeight: "600", lineHeight: "24px",height:"24px",bgcolor:"#FACC15",padding:"0px 8px",width:"100%",maxWidth:"70px",borderRadius:"6px",textAlign:"center" }}>{product.category}</TranslateText>
                    <TranslateText sx={{ fontSize: { xs: "24px", sm: "24px", md: "36px" }, fontWeight: "700", lineHeight: "43.2px" }}>{product.name}</TranslateText>
                    <TranslateText sx={{ fontSize: { xs: "16px", sm: "16px", md: "20px" }, fontWeight: "400", lineHeight: "24px" }}>{product.details}</TranslateText>
                    <TranslateText sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "19.2px", color: "grey.600" }}>Size - {product.unit}</TranslateText>
                    <TranslateText sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "19.2px", color: "grey.600" }}>SKU - {product.sku}</TranslateText>
                    {/* {authtoken ? ( */}
                                    <>
                                        {/* {product.price != product.selling_price && */}
                                            <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "19.2px", color: "error.light", textDecoration: "line-through" }}><CurrencyRupeeIcon sx={{ fontSize: "16px", fontWeight: 400, color: "error.light", marginTop: "-1px" }} />{
                                                // (parseFloat(product.price) + Math.round(product?.price * (product?.tax/100) )).toFixed(2)
                                                CalculateProductPrice({authtoken:authtoken,member:"nonmember",item:{product:product},lineStrike:true})
                                            }</Typography>
                                        {/* } */}
                                       <Typography sx={{ fontSize: "32px", fontWeight: "700", lineHeight: "38.4px", color: "primary.light" }}><CurrencyRupeeIcon sx={{ fontSize: "32px", fontWeight: 700, color: "primary.light", marginTop: "-1px" }} />{ 
                                            // (parseInt(product.selling_price) + Math.round(parseInt(product.selling_price) * (parseInt(product.tax)/100))).toFixed(2) 
                                            CalculateProductPrice({authtoken:authtoken,member:member,item:{product:product},lineStrike:false})
                                       }</Typography>
                                    </>
                                {/* ) : (
                                    <Typography sx={{fontSize: "32px", fontWeight: "700", lineHeight: "38.4px", color: "primary.light" }}><CurrencyRupeeIcon sx={{ fontSize: "32px", fontWeight: 700, color: "primary.light", marginTop: "-1px" }} /> {
                                            // (parseFloat(product.price) + Math.round(product?.price * (product?.tax/100) )).toFixed(2)
                                            CalculateProductPrice({authtoken:authtoken,member:"nonmember",item:{product:product},lineStrike:false})
                                        }</Typography>
                                )} */}
                   
                    <TranslateText sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "19.2px", color: "grey.600" }}>Inclusive of GST</TranslateText>
                    {Object.keys(product)?.length>=1 ?product.inventry_details?.available_quantity>0?
                    <TranslateText sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "24px", color: "#00E471" }}><Box sx={{ width: "12px", height: "12px",borderRadius:"50px", bgcolor: "#00E471",display:"inline-block" }}></Box> In stock, ready to ship</TranslateText>:
                    <TranslateText sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "24px", color: "error.light" }}><Box sx={{ width: "12px", height: "12px",borderRadius:"50px", bgcolor: "error.light",display:"inline-block" }}></Box> Out Of Stock</TranslateText>
                    :null}
                    <Box sx={{ display: "flex", gap: "24px" }}>
                    {cartCheck(product.id)&&product.inventry_details.available_quantity>0 ?  <Button variant="contained" sx={{ maxWidth: "311px", width: "100%", bgcolor: "black", height: "36px", padding: "8px 12px", textTransform: "none", "&:hover": { bgcolor: "black", color: "white" },display:"flex",gap:"20px"  }}>
                                <Typography onClick={()=>updateQuantity(product,false)} sx={{ fontSize:"14px" ,fontWeight:600,cursor:"pointer" ,padding:"0px 8px"}}>
                                <RemoveIcon sx={{fontSize:"15px",color:"#fff"}} />
                                </Typography>
                                <Typography sx={{fontSize:"14px" ,fontWeight:600 }}>
                                {cartCheck(product.id)}
                                </Typography>
                                <Typography onClick={()=>updateQuantity(product,true)} sx={{ fontSize:"14px" ,fontWeight:600,cursor:"pointer",padding:"0px 8px" }}>
                                <AddIcon  sx={{fontSize:"15px",color:"#fff"}} />
                                </Typography>
                            </Button> :
                        <Button disabled={product?.inventry_details?.available_quantity<=0} variant="contained" sx={{ maxWidth: "311px", width: "100%", bgcolor: "black", height: "36px", padding: "8px 12px", textTransform: "none", "&:hover": { bgcolor: "black", color: "white" } }} onClick={(e) => addCart(product, 1, e)}><ShoppingCartOutlinedIcon sx={{ marginRight: "10px" }} /><TranslateText sx={{fontSize:"14px"}}>Add to Cart </TranslateText> </Button>}
                        <Button variant="contained" sx={{ bgcolor: "secondary.grey", width: "100%", minWidth: "44px", maxWidth: "44px", height: "36px", padding: "8px 12px", borderRadius: "4px", "&:hover": { bgcolor: "secondary.grey" }, boxShadow: "none" }}><ShareIcon sx={{ width: "20px", height: "20px", color: "secondary.greyDark" }} onClick={() => handleCopyurl(product.id)} /></Button>
                    </Box>
                </Box>
            </Box>

        </Box>
        {!isMobile && !isLaptop && <Box sx={{ bgcolor: "secondary.greyBg", height: "84px", padding: { xs: "10px 24px", sm: "10px 24px", md: "16px 120px" }, width: "100%", display: "flex", gap: "16px",position:"sticky",top:"118px" ,zIndex:2 }}>
            <Button sx={{ bgcolor: activeSection === 'producDetails' ?"black":"secondary.grey", color:activeSection === 'producDetails' ? "white":"secondary.greyDark", padding: "10px 24px", borderRadius: "26px", "&:hover": { bgcolor: activeSection === 'producDetails' ?"black":"secondary.grey",color:activeSection === 'producDetails' ? "white":"secondary.greyDark" }, width: "100%", display: "flex", gap: "10px" }} onClick={() => scrollToSection(producDetailsRef)}><Box component="img" src={productDetails}></Box><TranslateText sx={{ fontSize: "20px", fontWeight: "500", lineHeight: "30px" }}>Product Details</TranslateText></Button>
            <Button sx={{ bgcolor: activeSection === 'ingredients' ?"black":"secondary.grey", color:activeSection === 'ingredients' ? "white":"secondary.greyDark", padding: "10px 24px", borderRadius: "26px", "&:hover": { bgcolor: activeSection === 'ingredients' ?"black":"secondary.grey",color:activeSection === 'ingredients' ? "white":"secondary.greyDark" }, width: "100%", display: "flex", gap: "10px" }} onClick={() => scrollToSection(ingredientsRef)}><Box component="img" src={Ingredients}></Box><TranslateText sx={{ fontSize: "20px", fontWeight: "500", lineHeight: "30px" }}>Ingredients</TranslateText></Button>
            <Button sx={{ bgcolor: activeSection === 'benefits' ?"black":"secondary.grey", color:activeSection === 'benefits' ? "white":"secondary.greyDark", padding: "10px 24px", borderRadius: "26px", "&:hover": { bgcolor: activeSection === 'benefits' ?"black":"secondary.grey",color:activeSection === 'benefits' ? "white":"secondary.greyDark" }, width: "100%", display: "flex", gap: "10px" }} onClick={() => scrollToSection(benefitsRef)}><Box component="img" src={benefits}></Box><TranslateText sx={{ fontSize: "20px", fontWeight: "500", lineHeight: "30px" }}>Benefits</TranslateText></Button>
            <Button sx={{ bgcolor: activeSection === 'dosage' ?"black":"secondary.grey", color:activeSection === 'dosage' ? "white":"secondary.greyDark", padding: "10px 24px", borderRadius: "26px", "&:hover": { bgcolor: activeSection === 'dosage' ?"black":"secondary.grey",color:activeSection === 'dosage' ? "white":"secondary.greyDark" }, width: "100%", display: "flex", gap: "10px" }} onClick={() => scrollToSection(dosageRef)}><Box component="img" src={dosage}></Box><TranslateText sx={{ fontSize: "20px", fontWeight: "500", lineHeight: "30px" }}>Dosage & Storage</TranslateText></Button>
            {/* <Button sx={{ bgcolor: activeSection === 'storage' ?"black":"secondary.grey", color:activeSection === 'storage' ? "white":"secondary.greyDark", padding: "10px 24px", borderRadius: "26px", "&:hover": { bgcolor: activeSection === 'storage' ?"black":"secondary.grey",color:activeSection === 'storage' ? "white":"secondary.greyDark" }, width: "100%", display: "flex", gap: "10px" }} onClick={() => storageRef)}><Box component="img" src={storage}></Box><TranslateText sx={{ fontSize: "20px", fontWeight: "500", lineHeight: "30px" }}>Storage</TranslateText></Button> */}
        </Box>}

        {(isMobile || isLaptop) && <Box sx={{ padding: { xs: "10px 24px", sm: "10px 24px", md: "64px 120px" }, display: "flex", flexDirection: "column", gap: "16px", justifyContent: "center", alignItems: "center" }}>

            <Accordion defaultExpanded disableGutters expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ width: "100%" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Box sx={{ display: "flex", gap: "10px",alignItems:"center" }}><Box component="img" sx={{ width: "36px", height: "36px" }} src={productDetailsBlue}></Box><TranslateText sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "30px" }}>Product Details</TranslateText></Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{display:"flex",flexDirection:"column",gap:"10px"}}>
                        <TranslateText sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "24px", textAlign: "center" }}>{product.name}</TranslateText>
                        <TranslateText sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", textAlign: "center" }}>{productDescription.content_details}</TranslateText>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion disableGutters expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{ width: "100%" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5-content"
                    id="panel5-header"
                >
                    <Box sx={{ display: "flex", gap: "10px",alignItems:"center"  }}><Box component="img" sx={{ width: "36px", height: "36px" }} src={IngredientsBlue} ></Box>
                        <TranslateText sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "30px" }}>Ingredients</TranslateText></Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columns={12} spacing={3} justifyContent="center">
                        {productDescription?.ingredients?.map((ing, ind) => (<Grid item xs={12} sm={6} md={4} key={ind} >
                            <Box sx={{  padding:"10px",minHeight:"50px",height: "100%", bgcolor: "secondary.grey", borderRadius: "12px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <TranslateText sx={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", textAlign: "center" }}>{ing}</TranslateText>
                            </Box>
                        </Grid>))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion disableGutters expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ width: "100%" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Box sx={{ display: "flex", gap: "10px",alignItems:"center"  }}><Box component="img" sx={{ width: "36px", height: "36px" }} src={benefitsBlue} ></Box>
                        <TranslateText sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "30px" }}>The Benefits</TranslateText></Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columns={12} spacing={3} justifyContent="center">
                        {productDescription?.may_help_to?.map((benefit, ind) => (<Grid item xs={12} sm={6} md={4} key={ind} >
                            <Box sx={{ padding:"10px",minHeight:"50px",height: "100%", bgcolor: backgroundColors[ind % backgroundColors.length], borderRadius: "12px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <TranslateText sx={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", textAlign: "center" }}>{benefit}</TranslateText>
                            </Box>
                        </Grid>))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion disableGutters expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ width: "100%" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Box sx={{ display: "flex", gap: "10px",alignItems:"center"  }}><Box component="img" src={dosageBlue} sx={{ width: "36px", height: "36px" }} ></Box>
                        <TranslateText sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "30px" }}>Recommended Dosage</TranslateText></Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{display:"flex",flexDirection:"column",gap:"20px"}}>
                    {productDescription?.recommended_dosage?.map((dosage) => {
                        console.log(dosage)
return(
    <Box sx={{  padding:"10px",minHeight:"50px",height: "100%", bgcolor: "secondary.grey", borderRadius: "12px", display: "flex", justifyContent: "center", alignItems: "center",flexDirection:"column" }}>
        <TranslateText sx={{fontWeight:"600",lineHeight: "24px", textAlign: "center" }}>{dosage.type == "General" ? "" : `${dosage.type}`}</TranslateText>
        <TranslateText sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", textAlign: "center" }}>{dosage.detail}</TranslateText>
    </Box>
)
                    })}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion disableGutters expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ width: "100%" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Box sx={{ display: "flex", gap: "10px",alignItems:"center"  }}><Box component="img" src={storageBlue} sx={{ width: "36px", height: "36px" }} ></Box>
                        <TranslateText sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "30px" }}>Storage</TranslateText></Box>
                </AccordionSummary>
                <AccordionDetails>
                    {/* <TranslateText> */}
                    <Box sx={{  padding:"10px",minHeight:"50px",height: "100%", bgcolor: "secondary.grey", borderRadius: "12px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <TranslateText sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", textAlign: "center" }}>{productDescription.storage}</TranslateText>
                    </Box>
                    {/* </TranslateText> */}
                </AccordionDetails>
            </Accordion>
        </Box>}

        {!isMobile && !isLaptop && <><Box ref={producDetailsRef} id="producDetails" sx={{ padding: { xs: "10px 24px", sm: "10px 24px", md: "64px 120px" }, display: "flex", flexDirection: "column", gap: "16px", justifyContent: "center", alignItems: "center" }}>
            <Box component="img" src={productDetailsBlue} sx={{ height: "96px", width: "96px", color: "primary.light" }}></Box>
            <TranslateText sx={{ fontSize: "28px", fontWeight: "600", lineHeight: "33.6px", textAlign: "center" }}>{product.name}</TranslateText>
            <TranslateText sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", textAlign: "center" }}>{productDescription.content_details}</TranslateText>
        </Box>
        <Box ref={ingredientsRef} id="ingredients" sx={{ padding: { xs: "10px 24px", sm: "10px 24px", md: "64px 120px" }, display: "flex", flexDirection: "column", gap: "16px", justifyContent: "center", alignItems: "center",bgcolor:"secondary.grey" }}>
                <Box component="img" src={IngredientsBlue} sx={{ height: "96px", width: "96px", color: "primary.light" }}></Box>
                <TranslateText sx={{ fontSize: "28px", fontWeight: "600", lineHeight: "33.6px" }}>Ingredients </TranslateText>
                <TranslateText sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", textAlign: "center"  }}>Ayurveda, the ancient science of life, promotes health and wellness through balance and harmony in the body, mind, and spirit. Practiced for over 5,000 years in India, it uses natural remedies from herbs, plants, and minerals. Each ingredient is carefully chosen and combined based on traditional knowledge and modern research to create effective, safe, and natural products. </TranslateText>
                <Box sx={{display:"flex",gap:"20px",width:"100%",flexWrap:"wrap",justifyContent:"center"}}>
                       {productDescription?.ingredients?.map((ing)=>(
                <TranslateText sx={{ fontSize: "16px", fontWeight: "600", lineHeight: "24px", textAlign: "center",padding:"8px 12px",bgcolor:"white" , borderRadius:"18px"  }}>{ing}</TranslateText>
                ))}
                </Box>
             
            </Box>
            <Box ref={benefitsRef} id="benefits" sx={{ padding: { xs: "10px 24px", sm: "10px 24px", md: "64px 120px" }, display: "flex", flexDirection: "column", gap: "16px", justifyContent: "center", alignItems: "center" }}>
                <Box component="img" src={benefitsBlue} sx={{ height: "96px", width: "96px", color: "primary.light" }}></Box>
                <TranslateText sx={{ fontSize: "28px", fontWeight: "600", lineHeight: "33.6px" }}>The Benefits</TranslateText>
                <Grid container columns={12} spacing={3} justifyContent="center">
                    {productDescription?.may_help_to?.map((benefit, ind) => (<Grid item md={4} key={ind} >
                        <Box sx={{ height:"100%",minHeight: "88px", bgcolor: backgroundColors[ind % backgroundColors.length], borderRadius: "12px", display: "flex", justifyContent: "center", alignItems: "center",padding:"10px" }}>
                            <TranslateText sx={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px",textAlign:"center" }}>{benefit}</TranslateText>
                        </Box>
                    </Grid>))}
                </Grid>

            </Box>
            <Box ref={dosageRef} id="dosage" sx={{ padding: { xs: "10px 24px", sm: "10px 24px", md: "0px 120px" }, display: "flex", gap: "16px", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ width: "50%", bgcolor: "secondary.grey", padding: "30px", display: "flex", alignItems: "center", width: "100%", flexDirection: "column", alignSelf: "stretch", gap: "16px" }}>
                    <Box component="img" src={dosageBlue} sx={{ height: "96px", width: "96px", color: "primary.light" }}></Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
                        <TranslateText sx={{ fontSize: "28px", fontWeight: "600", lineHeight: "33.6px" }}>Recommended Dosage</TranslateText>
                        {productDescription?.recommended_dosage?.map((dosage) => (
                            <TranslateText sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}>{dosage.type == "General" ? "" : `${dosage.type}:`}{dosage.detail}</TranslateText>

                        ))}
                    </Box>
                </Box>
                <Box ref={storageRef} id="storage" sx={{ width: "50%", bgcolor: "secondary.grey", padding: "30px", display: "flex", alignItems: "center", width: "100%", flexDirection: "column", alignSelf: "stretch", gap: "16px" }}>
                    <Box component="img" src={storageBlue} sx={{ height: "96px", width: "96px", color: "primary.light" }}></Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
                        <TranslateText sx={{ fontSize: "28px", fontWeight: "600", lineHeight: "33.6px" }}>Storage</TranslateText>
                        <TranslateText sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}>{productDescription.storage}</TranslateText>
                    </Box>
                </Box>
            </Box></>}
        <Box sx={{ padding: { xs: "10px 24px", sm: "10px 24px", md: "64px 120px" }, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Cards current_product = {params.id}/>
        </Box>
<Footer/>

    </Box>

    )
}
export default ProductDetails