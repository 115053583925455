import { createSlice } from "@reduxjs/toolkit";


let initialState = {
    Allproducts:[],
    product:{},
    cart:[],
    orders:[],
    OrdersAndLevels:[],
    bg :[
        {
            "id": 1,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(161, 206, 247, 0.5) 100%)"
        },
        {
            "id": 2,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(250, 203, 178, 0.5) 100%)"
        },
        {
            "id": 3,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(241, 190, 214, 0.5) 100%)"
        },
        {
            "id": 4,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(236, 176, 178, 0.5) 100%)"
        },
        {
            "id": 5,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(195, 228, 191, 0.5) 100%)"
        },
        {
            "id": 6,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(204, 206, 108, 0.5) 100%)"
        },
        {
            "id": 7,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(126, 188, 255, 0.5) 100%)"
        },
        {
            "id": 8,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(156, 215, 170, 0.5) 100%)"
        },
        {
            "id": 9,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(181, 144, 238, 0.5) 100%)"
        },
        {
            "id": 10,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 167, 196, 0.5) 100%)"
        },
        {
            "id": 11,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(161, 192, 252, 0.5) 100%)"
        },
        {
            "id": 12,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(234, 136, 214, 0.5) 100%)"
        },
        {
            "id": 13,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(115, 183, 243, 0.5) 100%)"
        },
        {
            "id": 14,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(223, 191, 112, 0.5) 100%)"
        },
        {
            "id": 15,
            "color": "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(223, 191, 112, 0.5) 100%)"
        }
    ],
    productCategory:['syrup','powder','capsule'],
    productTab:"ip"
};


let product = createSlice({
    name: "sideBar",
    initialState: initialState,
    reducers: {
        updateAllProduct:(state, { type, payload }) => {
            
            state.Allproducts = payload
        },
        updateProduct:(state, { type, payload }) => {
            // console.log(payload)
            state.product = payload
        },
        updateCart:(state, { type, payload }) => {
            // console.log(payload)
            state.cart = payload
        },
        updateOrders:(state, { type, payload }) => {
            // console.log(payload)
            state.orders = payload
        },
        updateOrdersAndLevels:(state, { type, payload }) => {
            state.OrdersAndLevels = payload
        },
        updateProductTab:(state, { type, payload }) => {
            state.productTab = payload
        },
        
    }
    })

    export let { 
      updateAllProduct,updateProduct,updateCart,updateOrders,updateOrdersAndLevels,updateProductTab
    } = product.actions
    
    
    
    export default product.reducer;