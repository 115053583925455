import { Modal ,Drawer} from "@mui/material"
import SignupForm from "../forms/signup_form"
import { useSelector } from "react-redux"
import LoginForm from "../forms/login_form"
import { useMediaQuery } from "react-responsive"
import { useEffect, useState } from "react"


const SignupModal=()=>{
    const signInForm=useSelector(state=>state.user.signInForm)
    const formType=useSelector(state=>state.user.formType)
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });


    const isMobile = useMediaQuery({ maxWidth: 600 });
    const toggleDrawer = (anchor, open) => {
        // console.log("????????????changed")
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //   return;
        // }
        
        setState({ ...state, [anchor]: open });
      };
      useEffect(()=>{
        if (signInForm){
            toggleDrawer('bottom', true)
        }else{
            toggleDrawer('bottom', false)
        }
      },[signInForm])
   
// console.log(signInForm)

    return<> {!isMobile? <Modal
            open={signInForm}
            // onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            sx={{zIndex:"21 !important"}}
            >
                {formType=='login'?<LoginForm/>:<SignupForm/>}
                



    </Modal>:<>
     {[ 'bottom'].map((anchor) => {
        // <React.Fragment key={anchor}>
           {/* <Button onClick={}>{anchor}</Button> */}
           {/* <InputBase value={selectedVoucher?selectedVoucher.voucher.name:""} sx={[formStyle.inputBase,{cursor:"pointer",textTransform:"capitalize"}]} onClick={toggleDrawer(anchor, true)} placeholder="Apply Coupon"  endAdornment={selectedVoucher? <HighlightOffIcon sx={{color:"#DA342E"}} onClick={()=>{setOpenCoupon(false);setSelectedVoucher()}} />  : <AddIcon sx={{color:"#1099F4"}} onClick={()=>toggleDrawer(anchor, true)} />}></InputBase> */}
          return <Drawer
            anchor={anchor}
            open={state[anchor]}
            // onClose={toggleDrawer(anchor, false)}
          >
            {/* {list(anchor)} */}
            {formType=='login'?<LoginForm/>:<SignupForm/>}
            {/* hello to all */}

          </Drawer>
        // </React.Fragment>
     } )}</>
    
}</>
}

export default SignupModal