import {
  Box,
  Button,
  Divider,
  InputBase,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  CircularProgress,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Stack,
  styled,
  stepConnectorClasses,
  StepConnector,
  FormControl,
  MenuItem,
  Select,
  Autocomplete,
  FormControlLabel,
  Tooltip,
  RadioGroup,
  Radio,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import VerifiedIcon from "@mui/icons-material/Verified";
import InfoIcon from "@mui/icons-material/Info";
import { TranslateText } from "../utils/googletranslator";
import {
  updateEmail,
  updateMobile,
  updateWhatsapp,
  updateFirstName,
  updateLastName,
  updatePassword,
  updateOtpCheck,
  updateMemberResponse,
  updateRole,
  updateId,
  updateProfile,
  updateUserData,
  updateUserName,
  updateCountry,
  updateIdentityNumber,
  updateisVerifyTick,
  updateDob,
  updateRefID,
  updateMembership,
  updateConrtol
} from "../redux/user_reducer";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import mayi_logo from "../assets/mayi_logo.png";
import signupImage from "../assets/Frame 91-1.jpg";
import CustomAxios from "../utils/CustomAxios";
import { useMediaQuery } from "react-responsive";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import background from "../assets/Background.jpg";
// import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { green } from "@mui/material/colors";
import OtpSignPin from "../components/otp_verify_signup";
import ConfirmationDialog from "../components/ConfirmationDialog";
import MessageBar from "../modules/messageBar";
import { countries as countries_json } from "../countries";
import {
  updateSnackBarMessage,
  updateSnackBarSeverity,
  updateSnackBarOpen,
} from "../redux/snackbar";
export const inputStyles = {
  inputBase: {
    border: "1px solid",
    borderColor: "grey.400",
    maxWidth: { xs: "100%", sm: "100%" },
    width: "100%",
    borderRadius: "5px",
    height: "48px",
    fontSize: "14px",
    padding: "14px",
    color: "grey.600",
  },
  Select: {
    border: "1px solid",
    borderColor: "grey.400",
    maxWidth: { xs: "100%", sm: "100%" },
    width: "100%",
    borderRadius: "5px",
    height: "48px",
    fontSize: "14px",
    padding: "14px",
    color: "grey.600",
  },
  PhoneInput: {
    border: "1px solid",
    borderColor: "grey.400",
    maxWidth: { xs: "100%", sm: "100%" },
    width: "100%",
    borderRadius: "5px",
    height: "48px",
    fontSize: "14px",
    padding: "14px",
    color: "grey.600",
  },
  label: {
    marginBottom: "8px",
    fontWeight: 400,
    fontSize: "14px",
    color: "grey.900",
    textAlign: "left",
  },
  terms: { fontWeight: 400, fontSize: "14px", whiteSpace: "pre-wrap" },
  paper: {
    padding: { sm: "32px", xs: "32px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    bgcolor: "#fff",
    height: "150px",
    width: { sm: "50%", xs: "100%" },
  },
  button: {
    height: "3rem",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    maxWidth: "100%",
    "&:hover": { bgcolor: "primary.light", color: "primary.contrastText" },
    borderRadius: "5px",
    "&:focus": { border: "0px solid white" },
  },
  heading: {},
  logo: { width: "100px", cursor: "pointer" },
  subHeading: { marginTop: "12px", fontWeight: "700", fontSize: "14px" },
  content: {
    marginBottom: "16px",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "center",
  },
  selectPaper: {
    maxHeight: "10px",
    overflowY: "auto",
  },
};

const SignupStepperForm = () => {
  const dispatch = useDispatch();
  const first_name = useSelector((state) => state.user.first_name);
  const last_name = useSelector((state) => state.user.last_name);
  const email = useSelector((state) => state.user.email);
  const password = useSelector((state) => state.user.password);
  const identityNumber = useSelector((state) => state.user.identityNumber);
  const Dob = useSelector((state) => state.user.dob);
  const [openInfo, setOpenInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const tooltipRef = useRef(null);
  const [showPassword, setShowPassword] = useState({
    password: false,
    con_password: false,
  });
  const [referralCode, setReferralCode] = useState("");
  const [response, setResponse] = useState("");
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [mobileNo, setMobileNo] = useState("");
  const [numberLength, setNumberLength] = useState(10);
  const [otpModal, setOtpmodal] = useState({ value: false, type: "" });
  const [checked, setChecked] = useState(false);
  // const [isVerified, setIsVerified] = useState(false);
  const [pendingCountry, setPendingCountry] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [refs_id, setRefId] = useState("");
  const [platform,setPlatform]=useState("web")
  const refs_id=useSelector(state=>state.user.ref_id)
  const [verifyError, setVerifyError] = useState("");
  // const isVerify = useSelector((state) => state.isVerifyTick);
  const queryParameters = new URLSearchParams(window.location.search);
  const clientId =
    "521447616048-relr5c80sac2rudgmfub8kantovfc5pe.apps.googleusercontent.com"; // Replace with your actual client ID
  const authtoken = localStorage.getItem("authtoken");
  const user_data = useSelector((state) => state.user);
  const member=useSelector((state)=>state.user.member)
  const [openMember, setOpenMember] = useState(false);
  const [openNonMember, setOpenNonMember] = useState(false);
  useEffect(() => {
    if (authtoken) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (selectedCountry?.value != "IN") {
      setChecked(true);
    } else {
      setChecked(false);
    }
    // console.log(selectedCountry);
  }, [selectedCountry]);

  const handleSummit = () => {
    const formData = {
      email: email,
      first_name: first_name,
      last_name: last_name,
      password: password,
      referralCode: referralCode,
      username: user_data.user_name,
      mobile_no: user_data.mobile_no,
      identityNumber: identityNumber,
      selectedCountry: selectedCountry,
      con_password: user_data.con_password,
      dob: Dob,
      platform:platform
    };

    const validate = validateForm(formData);
    if (validate && agree) {
      signup();
      // dispatch(updateisVerifyTick(false));
    }
  };
  //   --------------------------- working code ----------------
  const fetchCountries = async () => {
    const access_key = "3c20bc99871906ff01975e743bec3a5b";
    try {
      // const response = await axios.get("https://restcountries.com/v3.1/all/");
      const response = {"data":countries_json}
      // console.log(response);
      return response.data.map((country) => ({
        value: country.cca2,
        label: country.name.common,
        dialCode: country.idd?.root + (country.idd?.suffixes?.[0] || ""),
        flag: country.flags?.png,
        numberLength: country.cca2 === "IN" ? 10 : 15,
      }));
    } catch (error) {
      // console.error("Error fetching countries:", error);
      return [];
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      const countryOptions = await fetchCountries();
      setCountries(countryOptions);

      // Set India as the default option
      const india = countryOptions.find((country) => country.value === "IN");
      setSelectedCountry(india);
      setMobileNo(india ? "" : "");
    };

    getCountries();
  }, []);

  useEffect(() => {
    const initializeCountries = async () => {
      const countryOptions = await fetchCountries();
      setCountries(countryOptions);

      // Set India as the default country
      const india = countryOptions.find((country) => country.value === "IN");
      setSelectedCountry(india);
      setMobileNo(india ? "" : "");
    };

    initializeCountries();
  }, []);

  const handleCountryChange = (event, value) => {
    const selectedOption = countries.find(
      (country) => country.value === value?.value
    );
    const hasDetails = user_data.mobile_no || identityNumber;

    if (selectedCountry?.value !== selectedOption?.value) {
      if (hasDetails) {
        setPendingCountry(selectedOption || null);
        setIsDialogOpen(true);
      } else {
        setSelectedCountry(selectedOption || null);
      }
    }
  };

  const handleConfirm = () => {
    // Reset the form data when confirmed
    setSelectedCountry(pendingCountry || null);
    setMobileNo("");
    dispatch(updateIdentityNumber(""));
    dispatch(updateMobile(""));
    dispatch(updateisVerifyTick(false));

    // setIsVerified(false);
    setIsDialogOpen(false);
  };

  const handleCancel = () => {
    // Close the dialog without making any changes
    setIsDialogOpen(false);
  };

  const handleInfoClick = () => {
    setOpenInfo((prev) => !prev);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  useEffect(() => {
    if (selectedCountry) {
      setNumberLength(selectedCountry.numberLength);
    }
  }, [selectedCountry]);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const id = useSelector((state) => state.user.id);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const userId = useSelector((state) => state.user.id);

  useEffect(() => {
    setReferralCode(queryParameters.get('code'));
    if (queryParameters.get('code')){
      setPlatform('referred')
    }
    else if(queryParameters.get('signup_via')){
      localStorage.setItem('signup_via',queryParameters.get('signup_via'))
      navigate('/products')
      
      // setPlatform(queryParameters.get('signup_via'))
    }
    if(localStorage.getItem('signup_via')){
      // console.log(localStorage.getItem('signup_via'))
      setPlatform(localStorage.getItem('signup_via'))
    }
    
  }, []);
  useEffect(() => {
    dispatch(updatePassword(""));
    dispatch(updateUserName(""));
    dispatch(updateFirstName(""));
    dispatch(updateLastName(""));
    dispatch(updateEmail(""));
    dispatch(updateMobile(""));
    dispatch(updateId(""));
    dispatch(updateCountry(""));
    dispatch(updateIdentityNumber(""));
    dispatch(updateOtpCheck("signup"));
  }, []);
  const signup = async () => {
    setIsloading(true);
    // console.log(selectedCountry);
    const payload = {
      email: email,
      first_name: first_name,
      last_name: last_name,
      password: password,
      referralcode: referralCode,
      isactive: false,
      member:member,
      username: user_data.user_name,
      mobileno: user_data.mobile_no,
      identitynumber: identityNumber,
      country: selectedCountry?.label,
      dialcode:
        selectedCountry?.value == "US" ? "+1" : selectedCountry.dialCode,
      authority: selectedCountry?.value == "IN" ? "aadhaar" : "passport",
      whatsapp: checked ? true : false,
      platform:platform
    };
    // console.log("=====================================", payload);
    await CustomAxios.post(`signup/`, payload)
      .then((data) => {
        setIsloading(false);
        localStorage.setItem("user", JSON.stringify(data.data));
        localStorage.setItem("isVerified", false);

        if (data.data.message === "Registration Successful!") {
          // console.log(data.data.user_id)
          dispatch(updatePassword(""));
          dispatch(updateUserName(""));
          dispatch(updateFirstName(""));
          dispatch(updateLastName(""));
          dispatch(updateEmail(data.data.email));
          dispatch(updateMobile(data.data.mobileno));
          dispatch(updateId(data.data.user_id));
          dispatch(updateWhatsapp(checked ? true : false));
          dispatch(updateCountry(data.data.selectedCountry));
          dispatch(updateIdentityNumber(data.data.identityNumber));
          dispatch(updateOtpCheck("signup"));
          navigate("/otp-verification");
        } else {
          setResponse(data.data.message);
          // setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsloading(false);
        // console.log(error);
        // console.log(error);
      });
  };


  const handleTogglePasswordVisibility = (val) => {
    if (val === "con") {
      setShowPassword((prevShowPassword) => {
        return {
          ...prevShowPassword,
          con_password: !prevShowPassword.con_password,
        };
      });
    } else {
      setShowPassword((prevShowPassword) => {
        return {
          ...prevShowPassword,
          password: !prevShowPassword.password,
        };
      });
    }
  };

  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isTablet = useMediaQuery({ maxWidth: 900, minWidth: 600 });
  const isLaptop = useMediaQuery({ query: "(min-width: 900px)" });

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const memberTooltip = () => {
    setOpenMember(true);
  };
  const memberTooltipClose = () => {
    setOpenMember(false);
  };
  const nonMemberTooltip = () => {
    setOpenNonMember(true);
  };
  const nonMemberTooltipClose = () => {
    setOpenNonMember(false);
  };

  const handleVerifyClick = async () => {
    // console.log(selectedCountry, Dob);

    const currentDate = new Date();
    const selectedDob = new Date(Dob);
    if (!selectedCountry) {
      setErrors((prev) => ({
        ...prev,
        selectedCountry: "Please select a country",
      }));
      return;
    }

    // Check if the selected country is not India and DOB is not provided
    if (selectedCountry?.value !== "IN" && !Dob) {
      setErrors((prev) => ({
        ...prev,
        Dob: "Please enter your Date of Birth",
      }));
      return;
    } else {
      // Check if the DOB is in the future
      if (selectedDob > currentDate) {
        setErrors((prev) => ({
          ...prev,
          Dob: "Date of Birth cannot be in the future. Please select a valid date.",
        }));
        return;
      } else {
        // Clear the DOB error if the selected country is India or DOB is valid
        setErrors((prev) => ({
          ...prev,
          Dob: "", // Clear the error message if it exists
        }));
      }
    }

    // Check if identityNumber is provided
    if (!identityNumber.trim()) {
      if (selectedCountry?.value === "IN") {
        setErrors((prev) => ({
          ...prev,
          identityNumber: "Please enter the Aadhaar number",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          identityNumber: "Please enter the Passport number",
        }));
      }
      return;
    }

    try {
      setIsLoading(true);
      let payload = {};
      if (selectedCountry?.value === "IN") {
        payload = {
          id: userId,
          aadhaar_number: identityNumber,
          mobileNumber: user_data.mobile_no,
          verifyOtpFor: "aadhaar",
        };
      } else {
        payload = {
          id: userId,
          identity_number: identityNumber,
          mobileNumber: user_data.mobile_no,
          dob: Dob,
          verifyOtpFor: "passport",
        };
      }

      // console.log("Payload for verification:", payload);
      const response = await CustomAxios.post("verifykyc/", payload);
      // console.log(response);

      if (response.data.status === "SUCCESS") {
        setErrors((prev) => ({ ...prev, identityNumber: "" }));
        // setRefId(response.data.ref_id);
        dispatch(updateRefID (response.data.ref_id));

        if (payload.verifyOtpFor === "aadhaar") {
          setOtpmodal({
            value: true,
            type: "aadhaar",
            ref_id: response.data.ref_id,
          });
        } else {
          dispatch(updateisVerifyTick(true));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          identityNumber: response.data.error,
        }));
      }
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        identityNumber: error ? error.response.data.message: error.response.data.error,
      }));
      // console.log(error, "response from error-----------------" , error.response.data.message)
    } finally {
      setIsLoading(false);
    }
  };

  const validateFormOnNext = (step) => {
    let formData = {
      email,
      first_name,
      last_name,
      password,
      referralCode,
      username: user_data.user_name,
      selectedCountry,
      mobile_no: user_data.mobile_no,
      identityNumber,
      con_password: user_data.con_password,
      dob: Dob,
    };

    let valid = true;
    const newErrors = {};

    // Step-specific validations
    if (step === 0) {
      // First name validation
      if (!formData.first_name.trim()) {
        newErrors.first_name = "Enter your First Name";
        valid = false;
      }
      // Last name validation
      if (!formData.last_name.trim()) {
        newErrors.last_name = "Enter your Last Name";
        valid = false;
      }
      // Username validation
      if (!formData.username.trim()) {
        newErrors.username = "Enter your Profile / Username";
        valid = false;
      } else if (formData.username.length < 3) {
        newErrors.username = "Minimum 3 characters allowed";
        valid = false;
      } else if (formData.username.length > 12) {
        newErrors.username = "Maximum 12 characters allowed";
        valid = false;
      }

      // Email validation
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!formData.email.trim()) {
        newErrors.email = "Enter your Email address";
        valid = false;
      } else if (!emailPattern.test(formData.email)) {
        newErrors.email = "Please enter a valid Email address.";
        valid = false;
      }
    }

    if (step === 1) {
      // Mobile number validation
      if (!formData.mobile_no.trim()) {
        newErrors.mobile_no = "Enter your Mobile Number";
        valid = false;
      } else if (!formData.selectedCountry) {
        newErrors.selectedCountry = "Please select your country";
        valid = false;
      } else if (formData.selectedCountry?.value === "IN") {
        // Validation for Indian numbers (must be exactly 10 digits)
        // if (formData.mobile_no.length !== 10) {
        //   newErrors.mobile_no = "Enter a valid 10-digit Indian Mobile Number";
        //   valid = false;
        // }
      } else {
        // Validation for other countries (minimum 5, maximum 15 digits)
        // if (formData.mobile_no.length < 6 || formData.mobile_no.length > 15) {
        //   newErrors.mobile_no = "Enter a valid Mobile Number";
        //   valid = false;
        // }
      }

      // Check if country is selected
      if (!formData.selectedCountry || !formData.selectedCountry?.value) {
        newErrors.selectedCountry = "Please select a country";
        valid = false;
      }

      // Country selection and Date of Birth validation
      if (formData.selectedCountry?.value !== "IN" && !formData.dob) {
        newErrors.dob = "Enter your Date of Birth";
        valid = false;
      }

      // Identity number validation
      if (!user_data.isVerifyTick) {
        if (!formData.identityNumber.trim()) {
          newErrors.identityNumber = "Enter your Identity Number";
          valid = false;
        } else if (
          formData.selectedCountry?.value === "IN" &&
          formData.identityNumber.length !== 12
        ) {
          newErrors.identityNumber = "Enter a valid 12-digit Aadhaar Number";
          valid = false;
        } else {
          // If identity number is provided but not verified
          newErrors.identityNumber = "Please verify your Identity Number.";
          valid = false;
        }
      }
    } else if (step === 2) {
      // Password validation
      if (!formData.password.trim()) {
        newErrors.password = "Please enter your Password";
        valid = false;
      } else if (
        formData.password.length < 8 ||
        !/[A-Z]/.test(formData.password) ||
        !/[a-z]/.test(formData.password) ||
        !/\d/.test(formData.password) ||
        !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(formData.password)
      ) {
        newErrors.password =
          "The Password must be at least 8 characters long and contain uppercase, lowercase letters, special characters, and numbers.";
        valid = false;
      }

      // Confirm password validation
      if (!formData.con_password.trim()) {
        newErrors.con_password = "Please re-enter your Password";
        valid = false;
      } else if (formData.password !== formData.con_password) {
        newErrors.con_password = "Confirm Password does not match";
        valid = false;
      }
    } else if (step === 3) {
      if (!agree) {
        newErrors.checkbox = "Please agree to the terms and policy";
        valid = false;
      }
    }

    // console.log("Form data for validation:", formData, newErrors);
    newErrors.Dob = "";
    setErrors(newErrors);
    if (valid) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const validateForm = (formData) => {
    let valid = true;
    const newErrors = {};
    // console.log("????????????????????????/validation form")
    // First name validation
    if (!formData.first_name.trim()) {
      newErrors.first_name = "Enter your First Name";
      valid = false;
    }

    // Last name validation
    if (!formData.last_name.trim()) {
      newErrors.last_name = "Enter your Last Name";
      valid = false;
    }

    // Email validation
    const emailPattern = /^\S+@\S+\.\S+$/;
    if (!formData.email.trim()) {
      newErrors.email = "Enter your Email address";
      valid = false;
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "Please enter a valid Email address";
      valid = false;
    }

    // Terms & conditions validation
    if (!agree) {
      newErrors.checkbox = "Please agree to Terms and Policy​";
    }

    // Password validation
    if (!formData.password.trim()) {
      newErrors.password = "Please enter your Password";
      valid = false;
    } else if (
      formData.password.length < 8 ||
      !/[A-Z]/.test(formData.password) ||
      !/[a-z]/.test(formData.password) ||
      !/\d/.test(formData.password) ||
      !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(formData.password)
    ) {
      newErrors.password =
        "The Password must be at least 8 characters long and contain uppercase, lowercase letters, special characters, and numbers";
      valid = false;
    }

    // Username validation
    if (!formData.username.trim()) {
      newErrors.username = "Enter your Profile / Username";
      valid = false;
    } else if (formData.username.length < 3) {
      newErrors.username = "Minimum 3 characters allowed";
      valid = false;
    } else if (formData.username.length > 12) {
      newErrors.username = "Maximum 12 characters allowed";
      valid = false;
    }

    // Country validation
    if (
      !formData.selectedCountry?.value ||
      formData.selectedCountry?.value === null
    ) {
      newErrors.selectedCountry = "Please select your country";
      valid = false;
    }

    // Mobile number validation
    const mobilePattern = /^\d+$/; // Assuming a 10-digit mobile number format
    if (!formData.mobile_no.trim()) {
      newErrors.mobile_no = "Please enter your Mobile Number";
      valid = false;
    } else if (!mobilePattern.test(formData.mobile_no)) {
      newErrors.mobile_no = "Enter a valid Mobile Number";
      valid = false;
    }

    // Identity number validation
    if (!formData.identityNumber.trim()) {
      newErrors.identityNumber = "Enter your Identity Number";
      valid = false;
    } else if (
      formData.selectedCountry?.value === "IN" &&
      formData.identityNumber.length !== 12
    ) {
      newErrors.identityNumber = "Enter a valid 12-digit Aadhaar Number";
      valid = false;
    } else if (!user_data.isVerifyTick) {
      newErrors.identityNumber = "Please Verify your Identity Number";
      valid = false;
    }

    // Confirm password validation
    if (!formData.con_password.trim()) {
      newErrors.con_password = "Please re-enter your Password";
      valid = false;
    } else if (formData.password !== formData.con_password) {
      newErrors.con_password = "Confirm Password does not match";
      valid = false;
    }

    // Date of Birth validation
    if (!formData.dob && formData.selectedCountry?.value !== "IN") {
      newErrors.dob = "Please enter your Date of Birth";
      valid = false;
    } else if (formData.dob && new Date(formData.dob) > new Date()) {
      newErrors.dob = "Date of Birth cannot be in the future";
      valid = false;
    }

    if (!formData.referralCode?.trim()) {
      newErrors.referralCode = "Please Enter your Sponsor Referral code";
      valid = false;
    }

    setErrors(newErrors);

    return valid;
  };

  useEffect(() => {
    dispatch(updateEmail(""));
    dispatch(updatePassword(""));
    dispatch(updateCountry(""));
    dispatch(updateIdentityNumber(""));
    dispatch(updateisVerifyTick(false))
    dispatch(updateDob(null));
  }, []);

  useEffect(() => {
    setErrors({});
  }, [selectedCountry]);

  const passwordVisible = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  };

  const handleKeyPress = (e, step) => {
    if (e.key === "Enter") {
      validateFormOnNext(step);
      // handleSummit();
    }
  };

  const homePage = () => {
    navigate("/");
  };

  // const handleGoogleSuccess = (credentialResponse) => {
  //     const details = jwtDecode(credentialResponse.credential);
  //     // console.log(details);
  //     const { given_name, family_name, email } = details;
  //     // Dispatch these details to update the Redux store or make an API call to register/login the user
  //     // console.log(given_name,family_name,email)
  //     dispatch(updateFirstName(given_name));
  //     dispatch(updateLastName(family_name));
  //     dispatch(updateEmail(email));

  //     // Redirect to the password page
  //     navigate("/");

  // };

  // const handleGoogleError = () => {
  //     // .log('Login Failed');
  // };

  const handleGoogleSuccess = async (credentialResponse) => {
    const details = jwtDecode(credentialResponse.credential);
    // console.log(details);
    const { given_name, family_name, email } = details;
    // console.log(given_name, family_name, email);

    const payload = {
      email: email,
      first_name: given_name,
      last_name: "family_name" in details ? family_name : "",
      referralcode: referralCode ? referralCode : "",
      is_active: true,
      registration_type: "google",
    };
    // console.log(payload)

    // console.log(given_name);
    // console.log(family_name);
    // console.log(email);

    setIsloading(true);
    try {
      const response = await CustomAxios.post(`signup/`, payload);
      setIsloading(false);

      if (response.data.message === "Registration Successful!") {
        const decode = jwtDecode(response.data.access_token);
        localStorage.setItem("address", JSON.stringify(response.data.add));
        localStorage.setItem("authtoken", response.data.access_token);
        localStorage.setItem("refreshToken", response.data.refresh_token);
        // console.log(response.data.add);
        // console.log(decode);
        localStorage.setItem("user", JSON.stringify (response.data));
        dispatch(updateMemberResponse(response.data));
        dispatch(updateFirstName(decode.first_name));
        dispatch(updateLastName(decode.last_name));
        dispatch(updateEmail(decode.email));
        dispatch(updateRole(decode.role));
        dispatch(updateConrtol(decode.control))
        dispatch(updateCountry(decode.selectedCountry));
        dispatch(updateIdentityNumber(decode.identityNumber));
        dispatch(updateId(decode.id));
        dispatch(updateId(decode.id));
        if (response.data.att.length > 0) {
          dispatch(updateProfile(response.data.att[0].original));
        }
        dispatch(updateEmail(decode.email));
        navigate("/");
      } else {
        setErrors({ passworderror: response.data.message });
      }
    } catch (error) {
      setIsloading(false);
      // console.error(error);
    }
  };

  let signup_steps = [<TranslateText sx={{fontSize:"12px" }}>basic info</TranslateText>, <TranslateText  sx={{fontSize:"12px" }}>Identity</TranslateText>, <TranslateText  sx={{fontSize:"12px" }}>security</TranslateText>, <TranslateText  sx={{fontSize:"12px" }}>referral</TranslateText>];
  const [active_step, setActiveStep] = useState(0);

  const StepperConnector_styles = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: "12px",
      left: "calc(-50% + 20px)",
      right: "calc(50% + 20px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#53AEFF",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#53AEFF",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#D6DAE2",
      borderTopWidth: 2,
      borderRadius: 1,
    },
  }));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        handleTooltipClose();
      }
    };

    // Add event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <>
        <Box
          component={Paper}
          elevation={isMobile ? 0 : 1}
          sx={[
            isLaptop || isTablet
              ? {
                  left: "50%",
                  top: "50%",
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                }
              : "",
            {
              display: "flex",
              alignItems: { xs: "center", sm: "initial" },
              flexDirection: "column",
              width: { md: "750px", xs: "100%" },
              maxWidth: { xs: "100%", sm: "440px" },
            },
            isMobile && { borderRadius: "unset" },
          ]}
        >
          {/* <Box src={signupImage} sx={{ clipRule: "", width: { md: "40%", xs: "100%", sm: "40%" }, height: { xs: "50vh", sm: "100vh", md: "auto" }, maxheight: "80vh", overflowY: "scroll", backgroundSize: "auto", objectFit: "cover" }} component="img" /> */}
          {isMobile && (
            <Box
              src={background}
              sx={{
                width: { md: "40%", xs: "100%", sm: "40%" },
                height: { xs: "50vh", sm: "100vh", md: "auto" },
                maxheight: "80vh",
                overflowY: "scroll",
                backgroundSize: "auto",
                objectFit: "fill",
              }}
              component="img"
            />
          )}
          <Stack
            sx={[
              {
                alignItems: "center",
                gap: { xs: "24px", md: "29px" },
                p: { xs: "16px", sm: "32px" },
                width: "100%",
              },
            ]}
          >
            <Stack sx={{ alignItems: "center" }}>
              <Box
                src={mayi_logo}
                component="img"
                sx={inputStyles.logo}
                onClick={homePage}
              />
              <TranslateText sx={inputStyles.subHeading} color="grey.900">
                Join us!
              </TranslateText>
              <TranslateText sx={inputStyles.content} color="grey.900">
                {" "}
                Create your account and start exploring.
              </TranslateText>
            </Stack>
            <Box sx={{alignSelf:"start"}}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={member}
                            onChange={(e)=>dispatch(updateMembership(e.target.value))}
                            >
                             <Tooltip PopperProps={{  sx: {"& .MuiTooltip-tooltip": { backgroundColor: "#001F38",  color: "#fff",  }, "& .MuiTooltip-arrow": { color: "#001F38",   }, },   }} title={  <Box ref={tooltipRef}  sx={{ backgroundColor: "#001F38", color: "white",  fontSize: "12px", textAlign: "center", padding: "8px", borderRadius: "4px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", maxWidth: "240px",}} ><TranslateText sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} >MAYI IQ Member:</TranslateText>  <TranslateText sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} > Unlock earning opportunities and special offers. Need to complete KYC verification.    </TranslateText>   </Box>}arrow open={openMember} onOpen={memberTooltip} onClose={memberTooltipClose}  placement="bottom" >
                            <FormControlLabel value="member" control={<Radio />} label={<TranslateText>Member</TranslateText>} />
                            </Tooltip>
                            <Tooltip PopperProps={{  sx: {"& .MuiTooltip-tooltip": { backgroundColor: "#001F38",  color: "#fff",  }, "& .MuiTooltip-arrow": { color: "#001F38",   }, },   }} title={  <Box ref={tooltipRef}  sx={{ backgroundColor: "#001F38", color: "white",  fontSize: "12px", textAlign: "center", padding: "8px", borderRadius: "4px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", maxWidth: "240px",}} ><TranslateText sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} >MAYI IQ Basic user:</TranslateText>  <TranslateText sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} >This option is for casual users who want to explore the platform. No KYC is required.    </TranslateText>   </Box>}arrow open={openNonMember} onOpen={nonMemberTooltip} onClose={nonMemberTooltipClose}  placement="bottom" >
                            <FormControlLabel value="non_member" control={<Radio />} label={<TranslateText>Non Member</TranslateText>} />
                            </Tooltip>
                            
                            </RadioGroup>  
                        </Box>
            <Stepper
              sx={{ width: "100%" }}
              activeStep={active_step}
              alternativeLabel
              connector={<StepperConnector_styles />}
            >
              {signup_steps.map((label, l_id) => (
                <Step key={l_id}>
                  <StepLabel
                    sx={{
                      fontWeight: 400,
                      "& .MuiStepLabel-label": { mt: "4px !important" },
                      "& .Mui-active": { fontWeight: "700 ! important" },
                      "& .Mui-active MuiStepConnector-line": {
                        borderColor: "red",
                      },
                      fontSize: "12px",
                      lineHeight: "20px",
                      letterSpacing: "0.25px",
                      color: "#171C21",
                      textTransform: "capitalize",
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {active_step === 0 ? (
              <Stack sx={{ gap: "16px", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box>
                    <TranslateText sx={inputStyles.label} color="grey.700">
                      First Name <span style={{ color: "red" }}>*</span>
                    </TranslateText>
                    <InputBase
                      autoFocus
                      autoComplete="given-name"
                      value={first_name}
                      sx={inputStyles.inputBase}
                      placeholder="Enter your First Name"
                      onKeyDown={(e) => handleKeyPress(e, active_step)}
                      onChange={(e) =>
                        dispatch(updateFirstName(e.target.value))
                      }
                    />
                    {errors.first_name && (
                      <TranslateText
                        sx={{
                          mt: "4px",
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "error.light",
                        }}
                      >
                        {errors.first_name}
                      </TranslateText>
                    )}
                  </Box>
                  <Box>
                    <TranslateText sx={inputStyles.label} color="grey.700">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </TranslateText>
                    <InputBase
                      autoComplete="family-name"
                      value={last_name}
                      sx={inputStyles.inputBase}
                      placeholder="Enter your Last Name"
                      onKeyDown={(e) => handleKeyPress(e, active_step)}
                      onChange={(e) => dispatch(updateLastName(e.target.value))}
                    />
                    {errors.last_name && (
                      <TranslateText
                        sx={{
                          mt: "4px",
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "error.light",
                        }}
                      >
                        {errors.last_name}
                      </TranslateText>
                    )}
                  </Box>
                </Box>
                <Box>
                  <TranslateText sx={inputStyles.label} color="grey.700">
                    Profile / Username <span style={{ color: "red" }}>*</span>
                  </TranslateText>
                  <InputBase
                    autoComplete="member_username"
                    value={user_data.user_name}
                    sx={inputStyles.inputBase}
                    placeholder="Set Profile / Username"
                    onKeyDown={(e) => handleKeyPress(e, active_step)}
                    onChange={(e) =>
                      dispatch(
                        updateUserData({
                          type: "user_name",
                          data: e.target.value.replace(" ", ""),
                        })
                      )
                    }
                  />
                  {errors.username && (
                    <TranslateText
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "error.light",
                        mt: "4px",
                      }}
                    >
                      {errors.username}
                    </TranslateText>
                  )}
                </Box>
                <Box>
                  <TranslateText sx={inputStyles.label} color="grey.700">
                    Email ID <span style={{ color: "red" }}>*</span>
                  </TranslateText>
                  <InputBase
                    autoFocus
                    autoComplete="email"
                    value={email}
                    sx={inputStyles.inputBase}
                    placeholder="Enter your Email ID"
                    onKeyDown={(e) => handleKeyPress(e, active_step)}
                    onChange={(e) => dispatch(updateEmail(e.target.value))}
                  />
                  {errors.email && (
                    <TranslateText
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "error.light",
                        mt: "4px",
                      }}
                    >
                      {errors.email}
                    </TranslateText>
                  )}
                </Box>
              </Stack>
            ) : active_step === 1 ? (
              <Stack sx={{ gap: "16px", width: "100%" }}>
                <Box
                  x={{
                    display: "flex",
                    gap: "16px",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <TranslateText sx={inputStyles.label} color="grey.700">
                    Country <span style={{ color: "red" }}>*</span>
                  </TranslateText>
                  <Autocomplete
                    sx={{ padding: "0px" }}
                    options={countries}
                    getOptionLabel={(option) => option.label}
                    onChange={handleCountryChange}
                    value={selectedCountry || null}
                    isOptionEqualToValue={(option, value) =>
                      value ? option?.value === value?.value : false
                    } // Check for null value
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={option.flag}
                          alt={option.label}
                          style={{
                            width: "20px",
                            height: "15px",
                            marginRight: "15px",
                          }}
                        />
                        {option.label}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        sx={{ height: "48px" }}
                        {...params}
                        placeholder="Search country..."
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: selectedCountry?.flag && (
                            <img
                              src={selectedCountry.flag}
                              alt={selectedCountry.label}
                              style={{
                                width: "20px",
                                height: "15px",
                                marginRight: "10px",
                                marginLeft: "5px",
                              }}
                            />
                          ),
                        }}
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </Box>
                {errors?.selectedCountry && (
                  <TranslateText
                    sx={{
                      mt: "4px",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "error.light",
                      alignSelf: "flex-start",
                      // background: "red",
                    }}
                  >
                    {errors?.selectedCountry}
                  </TranslateText>
                )}
                {/* Mobile Number Input */}
                <Box>
                  <TranslateText sx={inputStyles.label}>
                    Mobile Number <span style={{ color: "red" }}>*</span>
                  </TranslateText>
                  <InputBase
                    autoComplete="tel-national"
                    value={user_data.mobile_no}
                    onKeyDown={(e) => handleKeyPress(e, active_step)}
                    onChange={(e) => {
                      let cleanedValue = e.target.value.replace(/\D/g, ""); // remove non-numeric characters
                      // if (selectedCountry?.value === "IN") {
                      //   cleanedValue = cleanedValue.slice(0, 10); // limit to 10 digits for India
                      // } else {
                      //   cleanedValue = cleanedValue.slice(0, 15); // limit to 15 digits for other countries
                      // }
                      dispatch(
                        updateUserData({
                          type: "mobile_no",
                          data: cleanedValue,
                        })
                      );
                    }}
                    sx={inputStyles.inputBase}
                    startAdornment={
                      selectedCountry && (
                        <Typography
                          sx={{
                            pr: "5px",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            maxWidth: { xs: "100%", sm: "100%" },
                          }}
                        >
                          <img
                            src={selectedCountry.flag}
                            alt="Country flag"
                            style={{
                              width: "20px",
                              height: "15px",
                              marginRight: "5px",
                            }}
                          />
                          {selectedCountry.value == "US"
                            ? "+1"
                            : selectedCountry.dialCode}
                        </Typography>
                      )
                    }
                    placeholder="Enter your mobile number"
                  />
                  {errors.mobile_no && (
                    <TranslateText
                      sx={{
                        mt: "4px",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "error.light",
                      }}
                    >
                      {errors.mobile_no}
                    </TranslateText>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      position: "relative",
                    }}
                  >
                    <Box sx={{ display: "inline" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked || selectedCountry?.value !== "IN"}
                            onChange={handleCheckboxChange}
                            disabled={selectedCountry?.value !== "IN"}
                            sx={{
                              color:
                                selectedCountry?.value !== "IN"
                                  ? "grey.300"
                                  : "primary.light", // Change color based on the selected country
                              "&.Mui-checked": {
                                color: "primary.light",
                              },
                              "&.Mui-disabled": {
                                color: "grey.300", // Lighter color when disabled
                              },
                            }}
                          />
                        }
                        label={
                          <TranslateText
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "grey.900",
                              textAlign: "left",
                            }}
                          >
                            Get updates / OTP via WhatsApp{" "}
                          </TranslateText>
                        }
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "inline",
                        marginLeft: "-23px",
                        marginBottom: "6px",
                        background: "transparent",
                      }}
                    >
                      <Tooltip
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "#001F38", // Change the background color here
                              color: "#fff", // Change the text color if needed
                            },
                            "& .MuiTooltip-arrow": {
                              color: "#001F38", // Change the arrow color
                            },
                          },
                        }}
                        title={
                          <Box
                            ref={tooltipRef} // Attach ref to the tooltip box
                            sx={{
                              backgroundColor: "#001F38", // Dark background for tooltip
                              color: "white", // White text color
                              fontSize: "12px",
                              textAlign: "center",
                              padding: "8px",
                              borderRadius: "4px",
                              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                              maxWidth: "240px",
                            }}
                          >
                            <TranslateText
                              sx={{
                                fontSize: "12px",
                                color: "white", // White text color for the tooltip
                                textAlign: "left",
                              }}
                            >
                              Receive notifications about OTP, offers, updates,
                              and important account info on WhatsApp.
                            </TranslateText>
                          </Box>
                        }
                        arrow
                        open={open} // Control the tooltip's open state
                        onOpen={handleTooltipOpen} // Show tooltip on click
                        onClose={handleTooltipClose} // Hide tooltip when it should close
                        placement="bottom" // Tooltip position
                      >
                        <IconButton
                          sx={{
                            position: "relative",
                            left: 0,
                            borderRadius: "50%",
                          }}
                          onClick={handleTooltipOpen} // Open tooltip on click
                        >
                          <InfoIcon
                            sx={{
                              width: "16px",
                              height: "16px",
                              color: "#53AEFF",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  {selectedCountry?.value === "IN" ? (
                    <TranslateText sx={inputStyles.label} color="grey.700">
                      Aadhaar Number <span style={{ color: "red" }}>*</span>
                    </TranslateText>
                  ) : (
                    <TranslateText sx={inputStyles.label} color="grey.700">
                      Date of Birth/Passport Number{" "}
                      <span style={{ color: "red" }}>*</span>
                    </TranslateText>
                  )}

                  <Box
                    sx={{
                      flex: 2,
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      flexDirection:
                        selectedCountry?.value === "IN" ? "row" : "column",
                    }}
                  >
                    <Box sx={{ width: "100%", display: "flex", gap: "8px" }}>
                      {selectedCountry?.value !== "IN" && (
                        <DatePicker
                          value={Dob}
                          onChange={(e) => dispatch(updateDob(e))}
                          disabled={user_data.isVerifyTick}
                          // disabled={!iskyceditable}
                          className="pan-datepicker"
                          format="DD/MM/YYYY"
                          sx={{
                            "&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root":
                              { ...inputStyles.inputBase, border: "none" },
                          }}
                        />
                      )}

                      <InputBase
                        value={identityNumber}
                        onChange={(e) => {
                          let cleanedValue = e.target.value;
                          if (selectedCountry?.value === "IN") {
                            cleanedValue = cleanedValue
                              .replace(/\D/g, "")
                              .slice(0, 12); // Aadhaar: only digits, max 12
                          } else {
                            cleanedValue = cleanedValue.slice(0, 15); // Passport: max 15 characters (alphanumeric)
                          }
                          dispatch(updateIdentityNumber(cleanedValue));
                        }}
                        disabled={user_data.isVerifyTick}
                        sx={inputStyles.inputBase}
                        placeholder={
                          selectedCountry?.value === "IN"
                            ? "Enter Aadhaar Number"
                            : "Enter Passport Number"
                        }
                        onKeyDown={(e) => handleKeyPress(e, active_step)}
                        endAdornment={
                          user_data.isVerifyTick && (
                            <VerifiedIcon
                              sx={{
                                color: "lightgreen",
                              }}
                            />
                          )
                        }
                      />

                      {/* <InputBase
                        value={identityNumber}
                        onChange={(e) => {
                          let cleanedValue = e.target.value;
                          if (selectedCountry?.value === "IN") {
                            cleanedValue = cleanedValue
                              .replace(/\D/g, "")
                              .slice(0, 12); // Aadhaar: only digits, max 12
                          } else {
                            cleanedValue = cleanedValue.slice(0, 15); // Passport: max 15 characters (alphanumeric)
                          }
                          dispatch(updateIdentityNumber(cleanedValue));

                          // Check if the cleaned value is empty and set isVerifyTick accordingly
                          if (!cleanedValue) {
                            dispatch(updateisVerifyTick(false)); // Dispatch action to set isVerifyTick to false if the input is empty
                          }
                        }}
                        disabled={user_data.isVerifyTick}
                        sx={inputStyles.inputBase}
                        placeholder={
                          selectedCountry?.value === "IN"
                            ? "Enter Aadhaar Number"
                            : "Enter Passport Number"
                        }
                        onKeyDown={(e) => handleKeyPress(e, active_step)}
                        endAdornment={
                          identityNumber && user_data.isVerifyTick ? (
                            <VerifiedIcon
                              sx={{
                                color: "lightgreen",
                              }}
                            />
                          ) : null
                        }
                      /> */}
                    </Box>

                    {errors?.Dob && (
                      <TranslateText
                        sx={{
                          mt: "4px",
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "error.light",
                          alignSelf: "flex-start",
                          // background: "red",
                        }}
                      >
                        {errors?.Dob}
                      </TranslateText>
                    )}

                    {errors?.identityNumber &&
                      selectedCountry?.value !== "IN" && (
                        <TranslateText
                          sx={{
                            mt: "4px",
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "error.light",
                            alignSelf: "flex-start",
                          }}
                        >
                          {errors.identityNumber}
                        </TranslateText>
                      )}

                    {!user_data.isVerifyTick && (
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={handleVerifyClick}
                        sx={{
                          height: "48px",
                          padding: "0rem 1.25rem",
                          alignSelf: "flex-start",
                        }}
                      >
                        {isLoading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          <TranslateText>Verify</TranslateText>
                        )}
                      </Button>
                    )}
                  </Box>

                  {errors.identityNumber && selectedCountry?.value === "IN" && (
                    <TranslateText
                      sx={{
                        mt: "4px",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "error.light",
                      }}
                    >
                      {errors?.identityNumber}
                    </TranslateText>
                  )}
                  <TranslateText
                    sx={{
                      mt: "4px",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "error.light",
                    }}
                  >
                    {verifyError}
                  </TranslateText>
                </Box>

                <OtpSignPin
                  otpModal={otpModal}
                  ref_id={refs_id}
                  setOtpmodal={setOtpmodal}
                  aadhaar_number={identityNumber.replace(/\s/g, "")}
                  mobileNumber={user_data.mobile_no}
                  identityLabel={
                    selectedCountry?.value === "IN"
                      ? "Aadhaar Number"
                      : "Passport Number"
                  }
                  identityNumber={identityNumber}
                />
                <ConfirmationDialog
                  open={isDialogOpen}
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  title="Change Country Confirmation"
                  content="Changing your country will reset your mobile number and identity information. Do you want to proceed?"
                />
              </Stack>
            ) : active_step === 2 ? (
              <Stack sx={{ gap: "16px", width: "100%" }}>
                <Box>
                  <TranslateText sx={inputStyles.label} color="grey.700">
                    Password <span style={{ color: "red" }}>*</span>
                  </TranslateText>
                  <InputBase
                    autoFocus
                    value={password}
                    type={showPassword.password ? "text" : "password"}
                    placeholder="Enter your password"
                    sx={inputStyles.inputBase}
                    onKeyDown={(e) => handleKeyPress(e, active_step)}
                    onChange={(e) => dispatch(updatePassword(e.target.value))}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleTogglePasswordVisibility("password")
                          }
                          edge="end"
                        >
                          {showPassword.password ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.password && (
                    <TranslateText
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "error.light",
                        mt: "4px",
                      }}
                    >
                      {errors.password}
                    </TranslateText>
                  )}
                </Box>
                <Box>
                  <TranslateText sx={inputStyles.label} color="grey.700">
                    Confirm Password <span style={{ color: "red" }}>*</span>
                  </TranslateText>
                  <InputBase
                    value={user_data.con_password}
                    type={showPassword.con_password ? "text" : "password"}
                    placeholder="Re-enter password"
                    sx={inputStyles.inputBase}
                    onKeyDown={(e) => handleKeyPress(e, active_step)}
                    onChange={(e) =>
                      dispatch(
                        updateUserData({
                          type: "con_password",
                          data: e.target.value,
                        })
                      )
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleTogglePasswordVisibility("con")}
                          edge="end"
                        >
                          {showPassword.con_password ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.con_password && (
                    <TranslateText
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "error.light",
                        mt: "4px",
                      }}
                    >
                      {errors.con_password}
                    </TranslateText>
                  )}
                </Box>
              </Stack>
            ) : active_step === 3 ? (
              <Stack sx={{ gap: "16px", width: "100%" }}>
                <Box>
                  <TranslateText sx={inputStyles.label} color="grey.700">
                    Sponsor Referral Code
                  </TranslateText>
                  <input
                    autoFocus
                    autoComplete="referral_code"
                    value={referralCode}
                    placeholder="Enter Referral Code"
                    style={{
                      ...inputStyles.inputBase,
                      outline: "none",
                      borderColor: "#bdbdbd",
                      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
                    }}
                    // onChange={(e) => setReferralCode(e.target.value)}
                    onChange={(e) =>
                      setReferralCode(e.target.value.replace(/\s+/g, ""))
                    }
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* <Checkbox
                      checked={agree}
                      onChange={() => setAgree(!agree)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ pl: 0 }}
                    /> */}
                    <Typography sx={inputStyles.terms}>
                      <TranslateText
                      component={Link}
                        // to="/terms-service"
                        style={{
                          textDecoration: "none",
                          color: "#000",
                          fontWeight: "400",
                          // textDecorationLine: "underline",
                          cursor:"none"
                        }}
                      >
                        Don't&nbsp;have&nbsp;a&nbsp;referral&nbsp;code
                      </TranslateText>
                      &nbsp;?&nbsp;
                      <TranslateText
                      component={Link}
                        style={{
                          textDecoration: "none",
                          color: "rgba(39, 161, 255, 1)",
                          fontWeight: "400",
                          // textDecorationLine: "underline",
                        }}
                        // to="/privacy-policy"
                        onClick={() => setReferralCode("MIQWPHBO")}
                      >
                        Click to copy
                      </TranslateText>
                    </Typography>
                  </Box>
                  {errors.referralCode && (
                    <TranslateText
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "error.light",
                        mt: "4px",
                      }}
                    >
                      {errors.referralCode}
                    </TranslateText>
                  )}
                </Box>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={agree}
                      onChange={() => setAgree(!agree)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ pl: 0 }}
                    />
                    <Typography sx={inputStyles.terms}>
                      <TranslateText
                      component={Link}
                        to="/terms-service"
                        style={{
                          textDecoration: "none",
                          color: "rgba(39, 161, 255, 1)",
                          fontWeight: "400",
                          textDecorationLine: "underline",
                        }}
                      >
                        Terms&nbsp;of&nbsp;Services
                      </TranslateText>
                      &nbsp;& &nbsp;
                      <TranslateText
                      component={Link}
                        style={{
                          textDecoration: "none",
                          color: "rgba(39, 161, 255, 1)",
                          fontWeight: "400",
                          textDecorationLine: "underline",
                        }}
                        to="/privacy-policy"
                      >
                        Privacy Policy
                      </TranslateText>
                    </Typography>
                  </Box>
                  {errors.checkbox && (
                    <TranslateText
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "error.light",
                        mt: "4px",
                      }}
                    >
                      {errors.checkbox}
                    </TranslateText>
                  )}
                  <TranslateText sx={{ color: "error.light", mt: "4px" }}>
                    {response}
                  </TranslateText>
                </Box>
              </Stack>
            ) : null}

            <Stack sx={{ gap: "20px", width: "100%" }}>
              {active_step >= 1 ? (
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  sx={{ justifyContent: "space-between", gap: "20px" }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      ...inputStyles.button,
                      borderRadius: "8px",
                      width: { xs: "100%", sm: "178px" },
                      height: "40px",
                      "&:hover": { bgcolor: "unset" },
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      setActiveStep(active_step - 1);
                    }}
                  >
                   <TranslateText>Previous</TranslateText> 
                  </Button>
                  {active_step >= 3 ? (
                    <Button
                      variant="contained"
                      sx={{
                        ...inputStyles.button,
                        width: { xs: "100%", sm: "178px" },
                        height: "40px",
                        borderRadius: "8px",
                      }}
                      onClick={handleSummit}
                    >
                      {isloading ? (
                        <CircularProgress color="inherit" size={30} />
                      ) : (
                        <TranslateText>Sign up</TranslateText>
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        ...inputStyles.button,
                        width: { xs: "100%", sm: "178px" },
                        height: "40px",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        validateFormOnNext(active_step);
                      }}
                    >
                      <TranslateText>Next</TranslateText>
                    </Button>
                  )}
                </Stack>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    ...inputStyles.button,
                    height: "40px",
                    borderRadius: "8px",
                  }}
                  fullWidth
                  onClick={() => {
                    validateFormOnNext(active_step);
                  }}
                >
                  <TranslateText>Next</TranslateText>
                </Button>
              )}
              {/* <Divider sx={{ fontSize: "14px", fontFamily:"Roboto" }} >Or</Divider> */}
              {/* <Box sx={{ display: 'flex', flexDirection:"column", justifyContent: "center"}}> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {/* <GoogleLogin
                                        onSuccess={handleGoogleSuccess}
                                        // onError={handleGoogleError}
                                    /> */}
                <Stack direction="row">
                  <TranslateText sx={{ textAlign: "center", fontSize: "14px" }}>
                    {" "}
                    Already have an account?&nbsp;
                  </TranslateText>
                  <Link
                    to="/login"
                    style={{
                      textDecoration: "none",
                      textDecorationLine: "underline",
                      fontSize: "14px",
                    }}
                  >
                    <TranslateText
                      sx={{ textDecoration: "none", fontSize: "14px" }}
                      color="primary"
                    >
                      Sign in
                    </TranslateText>
                  </Link>
                </Stack>
              </Box>
              {/* </Box> */}
            </Stack>
          </Stack>

          {/* <Box sx={{ padding: { sm: "32px "32px" }, display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', bgcolor: "#fff", width: { sm: "60%", xs: "100%" } }}>
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}> */}
          {/* <Box sx={{ display: "flex", gap: "16px", flexDirection: { xs: "column", sm: "row" } }}>
                                <Box>
                                    <TranslateText sx={inputStyles.label} color="grey.700">First Name <span style={{ color: "red" }}>*</span></TranslateText>
                                    <InputBase sx={inputStyles.inputBase} placeholder="Enter your First Name" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateFirstName(e.target.value))} />
                                    {errors.first_name && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.first_name}</TranslateText>}
                                </Box>
                                <Box>
                                    <TranslateText sx={inputStyles.label} color="grey.700">Last Name <span style={{ color: "red" }}>*</span></TranslateText>
                                    <InputBase sx={inputStyles.inputBase} placeholder="Enter your Last Name" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateLastName(e.target.value))} />
                                    {errors.last_name && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.last_name}</TranslateText>}
                                </Box>
                            </Box> */}
          {/* <Box>
                                <TranslateText sx={inputStyles.label} color="grey.700">Email <span style={{ color: "red" }}>*</span></TranslateText>
                                <InputBase sx={inputStyles.inputBase} placeholder="Enter email address" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateEmail(e.target.value))} />
                                {errors.email && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.email}</TranslateText>}
                            </Box>
                            <Box>
                                <TranslateText sx={inputStyles.label} color="grey.700">Password <span style={{ color: "red" }}>*</span></TranslateText>
                                <InputBase type={showPassword ? 'text' : 'password'} placeholder="Enter password" sx={inputStyles.inputBase} onKeyDown={handleKeyPress} onChange={(e) => dispatch(updatePassword(e.target.value))} endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                } />
                                {errors.password && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.password}</TranslateText>}
                            </Box>
                            <Box>
                                <TranslateText sx={inputStyles.label} color="grey.700">Sponsor Referral Code</TranslateText>
                                <InputBase sx={inputStyles.inputBase} value={referralCode} placeholder="Enter Referral Code" onKeyDown={handleKeyPress} onChange={(e) => setReferralCode(e.target.value)} />
                                {errors.referralCode && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.referralCode}</TranslateText>}
                            </Box>
                            <Box>
                                <Box sx={{ display: "flex", alignItems: "center", }}>
                                    <Checkbox
                                        checked={agree}
                                        onChange={() => setAgree(!agree)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <TranslateText sx={inputStyles.terms} ><a href="https://mayiiq.com/terms-of-services/" style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)", fontWeight: "400", textDecorationLine: "underline" }} >Terms&nbsp;of&nbsp;Services</a >&nbsp;and&nbsp;<a style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)", fontWeight: "400", textDecorationLine: "underline" }} href="https://mayiiq.com/privacy-policy/">Privacy Policy</a></TranslateText>
                                </Box>
                                {errors.checkbox && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.checkbox}</TranslateText>}
                            </Box>
                            <TranslateText sx={{ color: "error.light" }}>{response}</TranslateText>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Button variant="contained" sx={inputStyles.button} onClick={handleSummit} >{isloading ? <CircularProgress color="inherit" size={30} /> : "Sign up"}</Button>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                <TranslateText sx={{ textAlign: "center", fontSize: "14px" }}> Already have an account?&nbsp;</TranslateText><Link to="/login" style={{ textDecoration: "none", textDecorationLine: "underline", fontSize: "14px" }}><TranslateText sx={{ textDecoration: "none", fontSize: "14px" }} color="primary">Sign in</TranslateText></Link>
                            </Box>
                            <Divider sx={{ marginTop: "24px", marginBottom: "20px", fontSize: "14px" }} >Or</Divider>
                            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                                <GoogleLogin
                                    onSuccess={handleGoogleSuccess}
                                    // onError={handleGoogleError}
                                />
                            </Box> */}
          {/* <Divider sx={{ marginTop: "24px", marginBottom: "20px", fontSize: "14px" }} >Or</Divider> */}
          {/* </Box>
                    </Box> */}
        </Box>
      </>
    </GoogleOAuthProvider>
  );
};
export default SignupStepperForm;
