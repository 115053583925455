
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    first_name:"",
    last_name:"",
    user_name:"",
    email:"",
    password:"",
    con_password:"",
    mobile_no:"",
    billing_address:[],
    shipping_address:[],
    bio:"",
    card:{},
    addressModal:false,
    id:"",
    addressList:[],
    address:{},
    role:"",
    allAddress:[],
    profile:'',
    otp:'',
    package:"",
    listitem:"",
    member_response:{},
    transactionPin:"",
    reconfirmPin:"",
    typeEnable:false,
    accumulated:{},
    transactionPinModal:false,
    selecteIdForTransaction:"",
    transactionpinVerification:"not verified",
    bankModal:false,
    bank:{},
    allBank:[],
    openWithdrawRequest:false,
    withdrawAmount:"",
    withdrawAccount:"",
    withdraRequest:[],
    withdrawAmountChange:false,
    withdrawId:"",
    isProfileReloaded:false,
    chatbot:false,
    withdrawDisabled:false,
    withdraw:false,
    OtpVerifyPopup:false,
    overallSpend:"",
    currentAmount:"",
    country: "",
  identityNumber: "",
  isVerifyTick: false,
  dob:null,
  whatsapp:false,
  ref_id:"",
  language:localStorage.getItem('language')?localStorage.getItem('language'):'en',
  member:'non_member',
  accessControl:[{role:"supercontrol",access:[{inventry:['/inventry_overview','/stocklist','/stocklist/:id','/product','/product/:id',"/bundle","/bundle/:id"]},'/profile',{settings:['/notification-settings','/delivery-settings',]},{admin:['/package_config',]},'/orders','/orders/:orderId','/Withdraw-request','/commision-history',]},{role:"accountcontrol",access:[{inventry:['/inventry_overview','/stocklist','/stocklist/:id']},'/commision-history','/Withdraw-request',]},{role:'operationcontrol',access:[{inventry:['/product','/product/:id']},'/orders','/orders/:orderId',]},{role:'common',access:[]},{role:'user',access:['/orderstatus','/my_networth','/profile','/dashboard']}],
  control:'',
  headerHeight:0,
  signInForm:false,
  formType:'login'


};


const userDataReducer = createSlice({
    name: "userDataReducer",
    initialState: initialState,
    reducers: {
        updateFirstName: (state, { type, payload }) => {
            // console.log("user name",payload)
            state.first_name = payload
        },
        updateLastName: (state, { type, payload }) => {
            // console.log("user name",payload)
            state.last_name = payload
        },
        updateEmail: (state, { type, payload }) => {
            // console.log("user name",payload)
            state.email = payload
        },
        updateMobile: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.mobile_no = payload
        },
        updatePassword: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.password = payload
        },
        updateAddressModal: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.addressModal = payload
        },
        updateGetAddress:(state, {type, payload}) =>{
            state.address=payload
        },
        updateAddress: (state=initialState,  {type, payload, where} ) => {
            // console.log("user name",payload, where)
            //state.address = payload
            if (payload.add==="add"){
            return{...state, 
                    address:[...state.address, payload]}}
            else{
                state.address = payload
            }
        },
        updateId: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.id = payload
        },
        updateRole: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.role = payload
        },
        updateBio:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.bio = payload
        },
        updateAllAddress:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.allAddress = payload
        },
        updateProfile:(state, { type, payload }) => {
            state.profile = payload
            // console.log(payload)
        },
        updateOtpCheck:(state, { type, payload }) => {
            state.otp = payload
            // console.log(payload)
        },
        updatePackage:(state, { type, payload }) => {
            state.package = payload
            // console.log(payload)
        },
        updateListSelect:(state, { type, payload }) => {
            state.listitem = payload
            // console.log(payload)
        },
        updateMemberResponse:(state, { type, payload }) => {
            state.member_response = payload
            // console.log(payload)
        },
        updateUserData:(state,{type,payload}) =>{
            // console.log("------data---------",payload)
            state[payload.type] = payload.data
        },
        updateTransactionPin:(state, { type, payload }) => {
            state.transactionPin = payload
        },
        updateReconfirmPin:(state, { type, payload }) => {
            state.reconfirmPin = payload
        },
        updateUserName:(state, { type, payload }) => {
            state.user_name = payload
        },
        updateTypeEnable:(state, { type, payload }) => {
            state.typeEnable = payload
        },
        updateAccumulated:(state, { type, payload }) => {
            state.accumulated = payload
        },
        updateTransactionPinModal:(state, { type, payload }) => {
            // console.log(payload)
            state.transactionPinModal = payload
        },
        updateSelecteIdForTransaction:(state, { type, payload }) => {
            // console.log(payload)
            state.selecteIdForTransaction = payload
        },
        updateTransactionPinVerification:(state, { type, payload }) => {
            // console.log(payload)
            state.transactionpinVerification = payload
        },
        updateBankModal: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.bankModal = payload
        },
        updateGetBank:(state, {type, payload}) =>{
            state.allBank=payload
        },
        updateBank: (state=initialState,  {type, payload, where} ) => {
            // console.log("user name",payload, where)
            state.bank = payload
        //     if (payload.add==="add"){
        //     return{...state, 
        //             bank:[...state.bank, payload]}}
        //     else{
        //         return{...state, 
        //             bank:{...state.bank,...payload}
        //     }
        // }
        },
        updateOpenWithdrawRequest: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.openWithdrawRequest = payload
        },
        updateWithdrawAmount: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.withdrawAmount = payload
        },
        updateWithdrawAccount: (state, { type, payload }) => {
            // // console.log("user name",payload)
            state.withdrawAccount = payload
        },
        updateWithdrawRequest:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.withdraRequest = payload
        },
        updateWithdrawAmountChange:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.withdrawAmountChange = payload
        },
        updateWithdrawId:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.withdrawId = payload
        },
        updateIsProfileReloaded:(state,{ type, payload })=>{
            state.isProfileReloaded = payload
        },
        updateChatBot:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.chatbot = payload
        },
        updateWithdrawDisabled:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.withdrawDisabled = payload
        },
        updateWithdraw:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.withdraw = payload
        },
        updateOtpVerifyPopup:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.OtpVerifyPopup = payload
        },
        updateOverallSpend:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.overallSpend = payload
        },
        updateCurrentAmount:(state, { type, payload }) => {
            // // console.log("user name",payload)
            state.currentAmount = payload
        },
        updateCountry: (state, { type, payload }) => {
          // // console.log("user name",payload)
          state.selectedCountry = payload;
        },
        updateIdentityNumber: (state, { type, payload }) => {
          // // console.log("user name",payload)
          state.identityNumber = payload;
        },
        updateisVerifyTick: (state, { type, payload }) => {
          // // console.log("user name",payload)
          state.isVerifyTick = payload;
        },
        updateDob: (state, { type, payload }) => {
          // // console.log("user name",payload)
          state.dob = payload;
        },
        updateWhatsapp: (state, { type, payload }) => {
          // // console.log("user name",payload)
          state.whatsapp = payload;
        },
        updateRefID:(state, { type, payload }) => {
          state.ref_id=payload
        },
        updateLanguage:(state, { type, payload }) => {
            // console.log(payload)
            state.language=payload
          },
          updateMembership:(state, { type, payload }) => {
            // console.log(payload)
            state.member=payload
          },
          updateConrtol : (state,{type, payload})=>{
            state.control = payload
        },
        updateHeaderHeight : (state,{type, payload})=>{
          state.headerHeight = payload
        },
          updateSigninForm:(state,{type, payload})=>{
            // console.log(payload)
            state.signInForm = payload
          },
          updateFormType:(state,{type, payload})=>{
            state.formType = payload
          },
    }
    })

    export const { 
        updateFirstName,updateUserName,updateCountry,updateIdentityNumber, updateLastName,updateEmail,updateMobile,updatePassword,updateAddressModal,updateAddress,updateBio,
        updateRole,updateId,updateAllAddress, updateProfile, updateOtpCheck, updatePackage, updateListSelect, updateMemberResponse,updateTransactionPin,updateReconfirmPin,
        updateUserData,updateTypeEnable,updateAccumulated,updateTransactionPinModal,updateSelecteIdForTransaction ,updateTransactionPinVerification,updateBankModal,updateGetBank,updateBank,updateisVerifyTick,
        updateOpenWithdrawRequest,updateWithdrawAmount,updateWithdrawAccount,updateWithdrawRequest,updateWithdrawAmountChange,updateWithdrawId, updateIsProfileReloaded,updateChatBot,updateWithdrawDisabled,updateWithdraw,updateOtpVerifyPopup,updateOverallSpend,updateCurrentAmount,
        updateDob,updateWhatsapp,updateRefID,updateLanguage,updateMembership,updateConrtol,updateHeaderHeight,updateSigninForm,updateFormType
    } = userDataReducer.actions
    
    
    
    export default userDataReducer.reducer;