
import Header1 from "../components/header1";
import { Box, Stack, Typography , keyframes} from "@mui/material"
import banner from "../assets/discoverHero.png"
import bundleBanner from "../assets/bundleHero.png"
import Layout from "../layout"
import Cards from "../components/cards_old"
import { useRef, useState } from "react"
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useDispatch, useSelector } from "react-redux"
import { updateSideBar, updateCartSideBar } from "../redux/active_bar"
import AdminSidebar from "../components/adminSideBar"
import { useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ProductList from "../components/productList"
import Product_list from "../components/product_list"
import Footer from "../components/footer"
import MessageBar from "./messageBar"
import CartSideMount from "../components/cartSideMount"
import MeetOurExperts from "../landing_page/meetourexperts"
import { TranslateText } from "../utils/googletranslator";
import { landingPage_styles } from "../landing_page/landing_page";
import { Link } from "react-router-dom";
import BundleProductsListUser from "../components/bundleProductsList_user";
import { Galleria } from "primereact/galleria";
const Home = () => {
    const dispatch = useDispatch()
    const [total,setTotal]=useState(0.00)
    const cartSidebar=useSelector(state=>state.sideBar.cartSidebar)
    const headerHeight=useSelector(state=>state.user.headerHeight)
    // const [admin, setAdmin] = useState(false)
    const admin=useSelector(state=>state.user.role) 
    const cart=useSelector(state=>state.product.cart)
    const authtoken=localStorage.getItem("authtoken")
    const member=useSelector(state=>state.user.member)
    const is1980px = useMediaQuery({ minWidth: 2560 });
    const galleriaRef = useRef()
    useEffect(() => {
        const calculateTotal = () => {
            let totalPrice = 0;
            cart.forEach(item => {
                const price = authtoken ? parseFloat(item.product?.selling_price) + Math.round(parseFloat(item.product?.selling_price) * (parseFloat(item.product?.tax)/100) ) : parseFloat(item.product?.price) + Math.round( parseFloat(item.product?.price) * parseFloat(item.product?.tax/100) );
                totalPrice += parseInt(price) * item.quantity;
            });
            setTotal(parseFloat(totalPrice).toFixed(2));
        };
        calculateTotal();
    }, [cart, authtoken]);
    const isLaptop = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const shine = keyframes`
    0% {
      transform: translateX(0px) rotate(25deg);
    }
    100% {
      transform: translateX(2000px) rotate(25deg);
    }
  `;
  const boxStyle = {
    background: "#00C2FF",
    // height: "62px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    position: "relative",
    overflow: "hidden",
    padding:"12px 8px",
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-20px',
      left: 0,
      height: '50px',
      width: '100%',
      background: 'rgba(255,255,255, 0.3)',
      filter: 'blur(5px)',
      boxShadow: '0 0 10px rgba(255,255,255, 0.1)',
      transform: 'translateX(-100%)',
      animation: `${shine} 2s linear infinite`,
    },
  };

  const bannerGalleriaData = [
    {
        img:banner,
        header:"DISCOVER THE POWER OF AYURVEDA",
        subHeader:"Shop online for natural healing & wellness!",
        style:{color:"#FFFFFF"}
    },
    {
        img:bundleBanner,
        header:"Unleash your best self with our tailor-made health bundles",
        subHeader:"designed to supercharge every aspect of your wellness journey",
        style:{color:"#303030"}
    }
  ]
     
   
    return (
        <Box sx={{overflowY:cartSidebar?"hidden":"auto",height:"100vh"}}>
            {admin=="admin" ? <Box sx={{ bgcolor: { sm: "grey.200", xs: "#fff" }, minHeight: "calc(100vh - 80px)" ,marginBottom:"54px"}}>
                <Header1 />
                <Box sx={{ display: "flex", marginTop: "80px" }}>
                    <AdminSidebar />

                </Box>
            </Box>

                : <Box sx={{marginBottom:{xs:"60px",md:"0px",},}}><Layout />
                     <Box sx={{bgcolor:"grey.100",marginTop:`${headerHeight}px`}}>
                     {!isMobile?<Box sx={[boxStyle,]}>
                    <TranslateText sx={{...landingPage_styles.introduction.discountText,color:"#FFF",fontWeight:600}}>Join now and unlock 25% off on all products </TranslateText>
                    <Box sx={{background:"yellow",padding:  {xs:"4px 20px",md:"8px 20px",display:"flex",alignItems: "center"},borderRadius:"23px"}}> <TranslateText sx={{fontSize: {xs:"16px",md:"20px"}, fontWeight: "500", lineHeight:{xs:"24px", md:"30px"}}}>plus exclusive</TranslateText>&nbsp;<TranslateText component={Link} style={{background: "linear-gradient( 45deg, #00E471 10%,#53AEFF 90%)", WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundClip: 'text', textFillColor: 'transparent'}} to="https://help.mayiiq.com/search/?logicalNames=&q=member+benefits" target="_blank"> member benefits!</TranslateText></Box>
                   
                    {/* <TranslateText sx={landingPage_styles.introduction.discountText}> on all purchases.</TranslateText> */}
                </Box>:<Box sx={[boxStyle,{background:"#00C2FF",height:"62px",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",gap:"10px"}]}>
                <TranslateText sx={{...landingPage_styles.introduction.discountText,color:"#FFF",fontWeight:600}}>Join now for</TranslateText>
                <TranslateText sx={landingPage_styles.introduction.discount}>25% off</TranslateText>
                </Box>}
        <MessageBar/>
                
                <Galleria
                ref={galleriaRef}
                circular
                autoPlay
                showThumbnails={false}
                showIndicators
                transitionInterval={3000}
                value={bannerGalleriaData}
                item={(item)=>{
                    return <Box sx={{ position: "relative", width: "100%", marginTop: { sm: "10x", xs: "0px" } }}>
                        <Box onMouseEnter={()=>galleriaRef.current?.stopSlideShow()} onTouchStart={()=>galleriaRef.current?.stopSlideShow()} onMouseLeave={()=>galleriaRef.current?.startSlideShow()} onTouchEnd={()=>galleriaRef.current?.startSlideShow()} onTouchCancel={()=>galleriaRef.current?.startSlideShow()} component='img' src={item.img} sx={{ width: "100%" }} />
                        <Stack sx={{ gap:{xs:"8px",sm:"12px",md:"16px"},textTransform:"uppercase",position: "absolute", top: "50%", left: "10%", transform: 'translateY(-50%)',maxWidth:{xs:"35%",md:"40%"},width:"100%" }}>
                            <TranslateText sx={{ ...item.style,fontFamily:"Poppins",textAlign: "left", fontWeight: 700, fontSize: { xs: "12px", sm: "24px", md:"32px", lg: is1980px?"64px":"52px" }, lineHeight:"100%"}}>
                                {item.header}
                            </TranslateText>
                            <TranslateText sx={{ ...item.style,fontFamily:"Poppins",textAlign: "left", fontWeight: 400, fontSize: { xs: "10px", sm: "20px", md:"24px", lg: is1980px?"46px":"40px" }, lineHeight:"100%" }}>
                                {item.subHeader}
                            </TranslateText>
                        </Stack>
                    </Box>
                }}
                />
                
                        
                        
                        {/* <Cards /> */}
                        <Product_list/>
                        {/* <BundleProductsListUser/> */}

                    </Box>
                   {/* <CartSideMount/> */}
                   
                  
                </Box>}
                {/* <MeetOurExperts/>  */}

            <Footer/>

        </Box>
    )

}
export default Home  