import "./profile.css"
import { Box, Button, InputBase, Paper, TextareaAutosize, Typography, Grid, Select, MenuItem, FormControl,InputLabel, Stack,Modal, Checkbox, Autocomplete, TextField, Skeleton } from "@mui/material"
import Layout from "../layout"
import SideBarList from "../components/list"
import CheckCirleIcon from "../assets/Check_circle.svg";
import ExcIcon from "../assets/Exclamation.svg";
import SheildIcon from "../assets/dashboad/profile/Frame.svg";
import ExcRedIcon from "../assets/exclamation_Red.svg";
import { inputStyles } from "../forms/signup_form"
import { useDispatch, useSelector } from "react-redux"
import { updateAddressModal, updateFirstName,updateLastName,updateUserName,  updateBio, updateAddress,updateEmail,updateMobile,updateWhatsapp,updateProfile, updateOtpCheck, updateTransactionPin, updateReconfirmPin, updateMemberResponse,updateRole,updateId, updateTypeEnable, updateAccumulated,updateGetBank,updateWithdrawRequest, updateIsProfileReloaded, updateMembership, updateRefID,updateConrtol } from "../redux/user_reducer"
// import { updateVerifyIdentity } from "../redux/user_reducer"
import { jwtDecode } from "jwt-decode";
import { styled } from '@mui/system';
import VerifiedIcon from '@mui/icons-material/Verified';
import BackupIcon from '@mui/icons-material/Backup';
// import Address from "../components/address_modal"
import { TranslateText } from "../utils/googletranslator";
import { useMediaQuery } from "react-responsive"
import axios from "axios"
import CustomAxios from '../utils/CustomAxios';
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CircularProgress from '@mui/material/CircularProgress';
import Address from "../components/address_modal"
import Avatar from '@mui/material/Avatar';
import { OtpTransPin } from "../components/otp_transactionPIN";
import { ArrowDropDown, Edit, EditOutlined } from "@mui/icons-material";
import security_png from "../assets/profile/Shield.png"
import { Calendar } from 'primereact/calendar';
import { DatePicker } from "@mui/x-date-pickers";
import { keyframes } from '@mui/system';
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import MessageBar from "./messageBar";
import dayjs from "dayjs";
import CloseIcon from '@mui/icons-material/Close';      
import { MuiOtpInput } from "mui-one-time-password-input";
import BankComponent from "../components/bankSection"
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { countries as countries_json } from "../countries";
import { Dropdown } from "primereact/dropdown";
import OtpSignPin from "../components/otp_verify_signup";
import ReferralUpdate from "../components/referalUpdate";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter)

export const formStyle = {
    inputBase: { border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" },
    // profinputBase: { border: "1px solid", borderColor: "grey.400", width: "50%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" },
    inputBasePin: { border: "1px solid #CBD5E1", maxWidth:"537px",width: "100%", borderRadius: "4px", height: "48px", fontSize: "16px", padding: "12px", color: "grey.700" },
    label:{ marginBottom: "12px", fontWeight: 400, fontSize: "16px" ,textAlign:"left"},
    textBox: { border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", fontSize: "16px",padding: "14px", color: "grey.700" },
    button: { height: "3rem",padding:"1rem 1.25rem", bgcolor:"primary.light","&:hover":{bgcolor:"primary.light",color:"primary.contrastText"},textTransform: "none", fontWeight: 600, fontSize: "14px", alignSelf: "flex-start",borderRadius:"5px" ,color:"#fff"},
    outlinedButton: { height: "3rem",padding:"1rem 1.25rem",textTransform: "none", fontWeight: 600, fontSize: "14px",  alignSelf: "flex-start",borderRadius:"5px" },
}


const Profile = () => {
    const cartSidebar=useSelector(state=>state.sideBar.cartSidebar)
    const glowingBorder = keyframes`
        0% {
            box-shadow: 0 0 8px 2px rgba(34, 197, 94, 0.3);
        }
        50% {
            box-shadow: 0 0 16px 4px rgba(34, 197, 94, 0.7);
        }
        100% {
            box-shadow: 0 0 8px 2px rgba(34, 197, 94, 0.3);
        }
    `
    const fileRef = useRef()
    const urlParams = new URLSearchParams(window.location.search)
    const { hash } = useLocation();
    const kyc_ref = useRef()
    const transpin_ref = useRef()
    const dispatch = useDispatch()
    const [imagefile, setImagefile] = useState('')
    const [selectedImage, setSelectedImage] = useState("");
    const [imagestatus, setImageStatus] = useState(true)
    const [useremail, setUserEmail] = useState("")
    const [isloading, setIsloading] = useState(false)
    const [isDloading, setIsDloading] = useState(false)
    const [allow, setAllow] = useState(false)
    const [userreponse, setUserResponse] = useState({})
    const [reasonSelect, setReasonSelect] = useState("")
    const [message, setMessage] = useState("")
    const [userid, setUserId] = useState('')
    const [pmessage, setPMessage] = useState('')
    const [ profileEdit,setProfileEdit]=useState(false)
    const username=useSelector(state => state.user.user_name)
    const [errors,setErrors]=useState({})
    const [verify,setVerify]=useState(false)
    const [mobileNO,setMobileNo]=useState("")
    const [emailid,setEmailid]=useState("")
    const [mobileEdit,setMobileEdit]=useState(false)
    const [emailEdit,setEmailEdit]=useState(false)
    const [verification,setVerification]=useState(false)
    const [transactionpinSuccess,setTransactionpinSuccess]=useState(false)
    const [otp,setOtp]=useState('')
    const [resend,setResend]=useState(0)
    const [maskedEmail,setMaskedEmail]=useState()
    const [maskedmobileno,setMaskedMobile]=useState()
    // const [typeEnable,setTypeEnable]=useState(false)
    const typeEnable=useSelector(state=>state.user.typeEnable)
    // const urlParams = new URLSearchParams(useLocation().search)
    // console.log(username)
    // const [imageurl, setImageUrl] = useState('')
    const [otpModal, setOtpmodal] = useState({ value: false, type: ''})
    const [otpIdentityModal, setOtpIdentityModal] = useState({ value: false, type: '', ref_id: ''})
    const [reTransPinOtp, setReTransPinOtp] = useState(false)
    const [transPinPresent, setTranspinPresent] = useState(false)
    const [otpNotmatch, setOtpNotMatch] = useState(false)
    const [retransClose, setRetransclose] = useState(false)
    const [maskedValue, setMaskedValue] = useState('');
    const [time, setTime] = useState();
    const [validations, setValidations] = useState({transPin:"",resubmitPin:"",equalPin:"",numberCheck:""})
    const navigate = useNavigate()
    const [emailVerified,setEmailVerified]=useState(true)
    const [mobileVerified,setMobileVerified]=useState(true)
    const member=useSelector(state=>state.user.member)
    // let 
    let  first_name = useSelector(state => state.user.first_name)
    let  last_name = useSelector(state => state.user.last_name)
    let  email = useSelector(state => state.user.email)
    let  mobile = useSelector(state => state.user.mobile_no)
    let bio = useSelector(state => state.user.bio)
    let id = parseInt(useSelector(state => state.user.id))
    let profile = useSelector(state => state.user.profile)
    // console.log(profile,imagestatus)
    let transactionPin = useSelector(state => state.user.transactionPin)
    let reconfirmPin = useSelector(state => state.user.reconfirmPin)
    // console.log(id)
    // let address,updateFirstName, updateLastName,
    // let address = useSelector(state => state.user.address)
    const fulladdress = useSelector(state => state.user.address)
    const [addresslist, setAddressList] = useState([])
    let addValue = false
    addValue = window.sessionStorage.getItem('addressadd');
   
    const address = useSelector(state => state.user.allAddress)
    const whatsapp=useSelector(state=>state.user.updateWhatsapp)
    // console.log(address)
    // // console.log(username)
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const [kyc_details,setKycDetails] = useState({
        fullName:"",
        dob:null,
        mobileNo:'',
        pan:"",
        expirydate:null,
        type:""
    })
    const [iskyceditable,setIskyceditable] = useState(false)
    const [kycPresent,setkycPresent] = useState(false)
    const [kyc_errors,setKycErrors] = useState({})
    const [kyc_linked_data,setKyc_Linked_data] = useState({})
    const [kyc_existing_data,setKyc_existing_data] = useState({})

    const isProfileReloaded = useSelector(state=>state.user.isProfileReloaded)
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [isWhatsappNotificationEnabled,setIsWhatsappNotificationEnabled] = useState(false)
    const [identity_number,setIdentityNumber] = useState('')
    // const [isIdentityNumberVerified,setIsIdentityNumberVerified] = useState(false)
    const isIdentityNumberVerified = useSelector(state=>state.user.isIdentityVerified)
    const [IdentityDob,setIdentityDob] = useState(null)
    const [isOverviewSecReload,setIsOverviewSecReload] = useState(true)
    // console.log("??????????????????????selected country",selectedCountry)
    useEffect(()=>{
        setCountries(countries_json.map((country) => ({
            value: country.cca2,
            label: country.name.common,
            dialCode: country.idd?.suffixes?.length>1? country.idd?.root :country.idd?.root + (country.idd?.suffixes?.[0] || ""),
            flag: country.flags?.png,
            numberLength: country.cca2 === "IN" ? 10 : 15,
        })) )
    },[])

    useEffect(() => {
        // console.log(id)
        setUserId(id)
    }, [id])
    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);
    useEffect(() => {
        
        fetchuserdetails()
    }, [userid])

    useEffect(()=>{
        setUserEmail(email)
    },[email])

    useEffect(() => {
        // // console.log(addValue)
        // console.log(fulladdress)
        if (fulladdress == true) {
            setAddressList(address)
        }
    }, [fulladdress])

    useEffect(() => {
        // console.log(id)
        setUserId(id)
    }, [id])

    useEffect(() => {
        fetchuserdetails()
        dispatch(updateIsProfileReloaded(false))
    }, [userid,transactionpinSuccess,isProfileReloaded])

    const checkForNullUndefined = (val)=>{
        if(val !== undefined && val !== "" && val !== null){
            return val
        }
        return ""
    }


    const fetchuserdetails = async () => {
        // console.log("id", id)
        const payload = {
            user_id: userid
        }
        if(userid){
            setIsOverviewSecReload(true)
            await CustomAxios.post(`get_details/`, payload).then(response => {
                // console.log(response.data)
                setUserResponse(response.data)
                setSelectedCountry( countries.filter((e)=>e.label == response.data?.user?.country)[0])
                setIsWhatsappNotificationEnabled(response.data?.user?.whatsapp)
                if(response.data.account.transaction_pin){
                    setTranspinPresent(true)
                }
                setIskyceditable(false)
                response.data.account.kyc_status?setkycPresent(true):setkycPresent(false)
                setKycDetails({...kyc_details,...response.data.kyc_details || {}, dob: response.data.kyc_details ? dayjs(response.data.kyc_details.dob) : null,mobileNo:response.data.kyc_details?response.data.kyc_details.mobileNo :response.data.user.mobileno,expirydate:response.data.kyc_details?dayjs(response.data.kyc_details.expirydate):null})
                // setKycDetails({...kyc_details,...response.data.kyc_details || {}, dob: response.data.kyc_details ? dayjs(response.data.kyc_details.dob) : null})
                setKyc_existing_data({...kyc_details,...response.data.kyc_details || {}, dob: response.data.kyc_details ? dayjs(response.data.kyc_details.dob) : null,expirydate:dayjs(response.data.kyc_details.expirydate)})
                // console.log(response.data)
                dispatch(updateUserName(response.data.user.username))
                dispatch(updateMemberResponse(response.data))
                dispatch(updateFirstName(response.data.user.first_name))
                dispatch(updateLastName(response.data.user.last_name))
                dispatch(updateEmail(response.data.user.email))
                dispatch(updateMobile(response.data.user.mobileno))
                dispatch(updateWhatsapp(response.data.user.whatsapp))
                dispatch(updateBio(response.data.account.bio))
                dispatch(updateAccumulated(response.data.accumulated))
                dispatch(updateGetBank(response.data.bankdetails))
                dispatch(updateWithdrawRequest(response.data.withdrawRequest))
                dispatch(updateMembership(response.data.user.member))
                setIdentityNumber(checkForNullUndefined(response.data?.user?.identitynumber))
                setIdentityDob(checkForNullUndefined(response.data?.user?.authority_dob) ? dayjs(response.data?.user?.authority_dob) : null)
                // dispatch(updateVerifyIdentity(response.data?.user?.identitynumber? true: false))
                // setImageUrl(response.data.att?.original)
                if (response.data.att?.original){
                    setImageStatus(true)
                }
                dispatch(updateProfile(response.data.att?.original))
                // console.log(bio, mobile,response.data.att?.original)
                const get_address = response.data.add.flatMap(innerArray => innerArray);
                // console.log(get_address)
                dispatch(updateAddress(get_address, "update"))
                // console.log(address)
                setIsOverviewSecReload(false)
            }
            ).catch(error => {
                setIsOverviewSecReload(false)
                // console.log(error)
            })
        }
    }
    useEffect(() => {
        // console.log(time)
        if (time > 0) {
          const timerId = setInterval(() => {
            setTime(prevTime => prevTime - 1);
          }, 1000);
          return () => clearInterval(timerId); // Cleanup interval on component unmount
        }
        else{
            setResend(true)
        }
      }, [time]);

    useEffect(() => {
        // // console.log(addValue)
        // console.log(fulladdress)
        if (fulladdress == true) {
            setAddressList(address)
        }
    }, [fulladdress])
    
    const updateProfiles = async () => {
        
        // console.log("allow to all")
       
        // console.log(email)
        const formData = {
            first_name: first_name,
            last_name: last_name,
            // bio: bio,
            email: emailVerified&&emailid? emailid:email,
            mobile_no:mobileVerified&&mobileNO? mobileNO:mobile,
            username:username,
            country:selectedCountry?.label,
            dialcode:selectedCountry?.dialCode,
            whatsapp:isWhatsappNotificationEnabled,
            // identity_number : identity_number,
            // authority:selectedCountry?.value === "IN"
            // ? "AADHAR"
            // : "PASSPORT"

        }

        const validation = profileValidate(formData)
        // console.log(formData)
        if (validation) {
            const form = new FormData();
            // console.log("form validation")
            form.append("first_name", first_name);
            form.append("last_name", last_name);
            // console.log("before form validation")
            // form.append("bio", bio)
            form.append("mobileno", mobileVerified&&mobileNO? mobileNO:mobile)
            form.append("dialcode",selectedCountry?.dialCode)
            form.append("country",selectedCountry?.label)
            form.append("whatsapp",isWhatsappNotificationEnabled)
            // form.append("identityNumber",identity_number)
            // form.append("authority",formData.authority)
            // selectedCountry?.value !== "IN" && form.append("authority_dob",IdentityDob?.format('YYYY-MM-DD'))
            // console.log("before form validation")
            form.append("email", emailVerified&&emailid? emailid:email)
            form.append("user_id", id)
            // form.append("username",username)
            // console.log("form", form)
            form.append("file_url", imagefile)
            // console.log(imagefile)
            //// console.log(username, bio, email, id)
            if (userreponse.att?.length === 0) {
                form.append("att_id", "")
            }
            else {
                // console.log(userreponse.att)
                // console.log("else")
                form.append("att_id", userreponse.att?.id)
            }
            form.append('address_list', JSON.stringify(address))
            setIsloading(true)
            const updateresponse = await CustomAxios.post(`update_profile/`, form)
            setIsloading(false)
            if (updateresponse.data.message == "User Profile succesfully updated without email") {
                setMessage("Updated Successfully !")
                setProfileEdit(false)
                window.location.reload();
            }
            else {
                if (updateresponse.data.message == "User Profile updated along with email") {
                    dispatch(updateOtpCheck('profile'))
                    // navigate('/otp-verification')
                    setProfileEdit(false)
                }
                else {
                    if (updateresponse.data.message == "Give a different email") {
                        setMessage("Email id already exist Give a different email")
                        setProfileEdit(false)
                    }
                }
            }
        }



    }
    // useEffect(()=>{
    //     document.body.scrollTop = 0;
    //     document.documentElement.scrollTop = 0;

    // },[])
    const profileValidate = (formData) => {
        let valid = true;
        // console.log(formData)
        let newErrors = {}
        // console.log(formData)
        if (!formData.first_name.trim()) {
            newErrors.firstname = 'Please enter your First Name here';
            valid = false;
        }
        if (!formData.last_name.trim()) {
            newErrors.lastname = 'Please enter your Last Name here';
            valid = false;
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
        if (!emailVerified && !formData.email.trim()) {
            newErrors.email = 'Enter your email address.​';
            valid = false;
        } else if (!emailVerified && !emailPattern.test(formData.email)) {
            newErrors.email = 'Please enter valid email address';
            valid = false;
        }
        else if(!emailVerified && emailPattern.test(formData.email)){
            newErrors.email="Please Verify Email"
            valid=false
        }
        if(!mobileVerified && !formData.mobile_no.trim()){
            newErrors.mobile_no = 'Enter your Mobile Number.'
            valid = false
        }
        else if(!mobileVerified &&formData.mobile_no.length !== 10){
            newErrors.mobile_no = 'Enter Valid Number.'
            valid = false
        }
        else if(!mobileVerified && formData.mobile_no.length == 10){
            newErrors.mobile_no="Please Verify Mobile No"
            valid=false
        }
        // if (!identity_number?.trim()) {
        //     if (selectedCountry?.value === "IN") {
        //         newErrors.identity_number = "Please enter the Aadhaar number"
        //         valid=false
        //     }
        //     else {
        //         newErrors.identity_number = "Please enter the Dob and Passport number"
        //         valid=false
        //     }
        // }
        // else if(identity_number){
        //     if (selectedCountry?.value === "IN" && identity_number?.length != 12) {
        //         newErrors.identity_number = "Please enter a valid Aadhaar number"
        //         valid=false
        //     }
        //     else{
        //         if(identity_number?.length != 15 && selectedCountry?.value !== "IN" ){
        //             newErrors.identity_number = "Please enter a valid Passport number"
        //             valid=false
        //         }
        //     }
        // }
    //    let dob = IdentityDob?.format("YYYY-MM-DD")
    //     if(!dob?.trim() && selectedCountry?.value !== "IN" ){
    //         newErrors.identity_number = "Please enter the Dob"
    //         valid=false
    //     }


        // console.log(newErrors)
        setErrors(newErrors)
        return valid

    }
    // const updateEmail = () => {
    //     // console.log("allow to all")
    // }

    // const updateMobile = () => {
    //     // console.log("allow to all")
    // }

    const uploadImage=(event)=>{
        // console.log(event)
        // console.log("we are here")
        const file = event.target.files[0];
        setImagefile(file)
        if (file) {

            const reader = new FileReader();

            reader.onload = (e) => {
                setSelectedImage("")
              setSelectedImage(e.target.result);
              setImageStatus(true)
            };

            reader.readAsDataURL(file);
          }
    }
    const editAddress = (item) => {
        dispatch(updateAddress(item))
        dispatch(updateAddressModal(true))
    }
    useEffect( ()=>{
        const payload = {
            id : id
        } 
        if (useremail){
            CustomAxios.post(`get_delete/`, payload).then(({data})=>{
                // console.log(data)
                setAllow(data?.request_decline)
            }).catch(error=>{
                // console.log(error)
            })
        }
    },[id])

    // const loadFile =() =>{
    //     document.getElementById("image-upload").click();
    // }
    const handleReasonSelect=(e)=>{
        setReasonSelect(e.target.value)
    }
    const handleAccDelRequest= async ()=>{
        const payload={
            id:id,
            reason:reasonSelect
        }
        if (!allow || reasonSelect!=""){
            setIsDloading(true)
        const response = await CustomAxios.post(`request_delete/`, payload)
        setIsDloading(false)
        if (response.data.message=="Mail sent successfully"){
            setMessage("Your profile deletion request has been successfully submitted. Your account will be deleted within 2 business days.")
        }}

    }

    // console.log(imagestatus, imagefile)

    const isTabletHori = useMediaQuery({ query: '(min-width: 1200px)' });
    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }
    function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
            width:"172px",height:"172px",
            fontSize:"32px",borderRadius:0
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }
      const capitalize = (str) => {
        return str?.split(' ').map(word => word?.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      };

        const handleFocus = () => {
            setMaskedValue(transactionPin);
        };
      const handleCheckCharacterLength = (e) => {  //-----------------> for checking the transaction PIN length
        e.preventDefault();
        setMaskedValue('* '.repeat(e.target.value.length));
        if (transactionPin.length > 0 && transactionPin.length < 6 ) {
            setValidations(prev => ({...prev, transPin: "Minimum 6 Numbers"}));
          }
           else {
            setValidations(prev => ({...prev, transPin: ""}));
          }
        if(reconfirmPin.length > 0 && reconfirmPin.length < 6) {
            setValidations(prev => ({...prev, resubmitPin: "Minimum 6 Numbers"}));
          }
          else {
            setValidations(prev => ({...prev, resubmitPin:""}));
          }

        
      } 
      const sendOTP = (e,type) => {
        e.preventDefault();
        
        let payload
        if(type === "transactionpin"){
            if(transactionPin === reconfirmPin || transPinPresent){
                setTime(30)
                setOtpNotMatch(false)
                setOtpmodal({ value: true, type: 'transactionpin' })
                payload = {
                otp_type:type,
                id:id
                 } }
                 else{
                     setValidations(prev => ({...prev, equalPin: "Pin does not match"}));
                 }
        }
        if(type === "kyc"){ 
            let errors = validateKYCFormDetails()
            if(Object.keys(errors).length == 0){  
                setTime(30)
                setOtpmodal({ value: true, type: 'kyc' })
                    payload = {
                    otp_type:type,
                    id:id
                }}
                // if(kyc_linked_data.email){
                //     payload['email'] = kyc_linked_data.email
                // }
                // if(kyc_linked_data.mobileno){
                //     payload['mobileno'] = kyc_linked_data.mobileno
                // }
        }
        if(payload){

            CustomAxios.post(`profile_otp/`, payload).then(({data})=>{
            if(data.message == "Transaction OTP has been sent"){
                dispatch(updateOtpCheck('transOtp'));
            }
            if(data.message == "KYC OTP has been sent"){  
                dispatch(updateOtpCheck('kycOtp'));
            }
        }).catch(error=>{
            // console.log(error)
        })}
       
      }
      const handleSubmitPin = () => {   //-----------------> for submitting transaction PIN
            const payload = {
                transaction_pin:transactionPin,
                user_id:userid
            }
            
            CustomAxios.post(`Transaction_pin/`, payload).then(({data})=>{  //----------------> API for adding transaction pin  
               if(data.message == "transaction pin has been set successfully"){
                   setTranspinPresent(true);
                   setOtpmodal({ value: false, type: '' })
                   setTransactionpinSuccess(true)
                   dispatch(updateSnackBarMessage("Transaction pin has been set successfully"))
                   dispatch(updateSnackBarSeverity("success"))
                   dispatch(updateSnackBarOpen(true))
               }
            }).catch(error=>{
                // console.log(error)
                setTransactionpinSuccess(true)

            })
      }
      const handleClearPin = () => {     //-------------------> Transaction pin form clear
        if(!transPinPresent){
            dispatch(updateTransactionPin(""))
            dispatch(updateReconfirmPin(""))
            setMaskedValue("")
            setValidations({transPin:"",resubmitPin:"",equalPin:"",numberCheck:""})
        }
      }

      useEffect(()=>{
            if(transPinPresent){
                // dispatch(updateTransactionPin("* * * * * *"))
                setMaskedValue("* * * * * *")
            }else{
                dispatch(updateTransactionPin(""))
            }
      },[transPinPresent])

     const changePin = (e) => {        //-------------------> Resetting Transaction pin
        e.preventDefault();
        // dispatch(updateTransactionPin(""));
        dispatch(updateReconfirmPin(""));
        setMaskedValue("")
        setTranspinPresent(false)
        setRetransclose(true)
        setValidations({transPin:"",resubmitPin:"",equalPin:"",numberCheck:""})
     }

     const handleCopy = (event) => {
        event.preventDefault();
    };
    
    useEffect(()=>{
        if(urlParams.get('scrollto') === "kyc" && kyc_ref.current){
            kyc_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            window.scrollTo({top:kyc_ref.current.getBoundingClientRect().top - 94,behavior:"smooth"})
        }
       
        if(urlParams.get('scrollto') == "transaction_pin" && transpin_ref.current){
            const topOffset = transpin_ref.current.offsetTop;

            window.scrollTo({
                top: topOffset, 
                behavior: 'smooth',
            });
        }

    },[urlParams])

    const handleKycCancel = ()=>{
        if(userreponse?.kyc_details){
            setKycDetails({...kyc_details,...userreponse.kyc_details,dob :dayjs(userreponse.kyc_details.dob),expirydate:dayjs(userreponse.kyc_details.expirydate)})
        }
        else{
            setKycDetails({
                ...kyc_details,
                fullName:"",
                dob:null,
                // mobileNo:"",
                pan:""
            })
        }
        setIskyceditable(false)
        setKycErrors({})
    }

    const validateKYCFormDetails = ()=>{
        let errors={}
        let pan_regrx = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
        if(!kyc_details.fullName || !kyc_details.fullName.trim()){
            errors['fullName'] = "Required."
        }
        if(!kyc_details.dob){
            errors['dob'] = "Required."
        }
        else if(!kyc_details.dob.isSameOrBefore(dayjs())){
            errors['dob'] = "DOB cannot be in future."
        }
        if( !kyc_details.mobileNo || !kyc_details.mobileNo.trim()){
            errors['mobileNo'] = "Required."
        }
        else if(kyc_details.mobileNo.length !== 10  && selectedCountry?.value=='IN'){
            errors['mobileNo'] = "Enter a valid number."
        }
        if(!kyc_details.pan.trim()){
            errors['pan'] = "Required."
        }
        else if(kyc_details.pan.length !==10  && selectedCountry?.value=='IN'){
            errors['pan'] = "Enter a valid pan."
        }
        else if( !pan_regrx.test(kyc_details.pan.trim().toUpperCase()) && selectedCountry?.value=='IN'){
            errors['pan'] = "Invalid format."
        }
        if(!kyc_details.expirydate&& selectedCountry?.value!=='IN'){
            errors['expirydate'] = "Required."
        }
        else if( selectedCountry?.value!=='IN'&&!kyc_details.expirydate.isSameOrAfter(dayjs()) ){
            errors['expirydate'] = "Expiry Date cannot be in Past."
        }
        setKycErrors(errors)
        return errors

    }
    const dayjs = require('dayjs')

    const handleKycSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);
        setOtpNotMatch(false)
        dispatch(updateSnackBarMessage(""));
    
        let errors = validateKYCFormDetails();

        // let is_kyc_data_changed = Object.keys(kyc_details).filter(key=> ['fullName','dob','mobileNo','pan'].includes(key)).some((key)=>console.log(key,kyc_details[key] , kyc_existing_data[key]))
        if (Object.keys(errors).length === 0 ) {
            let is_kyc_data_changed = Object.keys(kyc_details).filter(key=> ['fullName','mobileNo','pan','dob','expirydate'].includes(key)).some((key)=> { console.log("????????????????????????????????",key);return key == 'dob'||key=='expirydate'  ?   dayjs(kyc_details[key], 'DD-MM-YYYY').format('YYYY-MM-DD') !== dayjs(kyc_existing_data[key], 'DD-MM-YYYY').format('YYYY-MM-DD'):kyc_details[key]?.replace(" ",'').toLowerCase() !== kyc_existing_data[key]?.replace(" ",'').toLowerCase() })
            if(is_kyc_data_changed){

                try {
                    let formattedDob = dayjs(kyc_details.dob, 'DD-MM-YYYY').format('YYYY-MM-DD');
                    let dobString = formattedDob.toString();
                    let formattedExpiry = dayjs(kyc_details.expirydate, 'DD-MM-YYYY').format('YYYY-MM-DD');
                    let expiryString = formattedExpiry.toString();
                    let payload = {
                        ...kyc_details,
                        dob:dobString,
                        // dob: formattedDob,
                        user_id: id,
                        type:selectedCountry?.value=='IN'?"pan":"passport",
                        expirydate:expiryString
                    };
        
                    if (userreponse?.kyc_details && iskyceditable) {
                        payload.kyc_id = userreponse?.kyc_details?.id;
                        // delete payload['pan'];
                        // delete payload['id'];
        
                        const res = await CustomAxios.put('kyc-details/', payload);
                        handlePutResponse(res.data);
                    } else {
                        payload.account = userreponse?.account?.id;
                        payload.id = id;
        
                        const res = await CustomAxios.post('kyc-details/', payload);
                        handlePostResponse(res.data);
                    }
                } catch (err) {
                    // console.error(err);
                    handleError("Failed to update KYC details");
                }
            }
            else{
                !is_kyc_data_changed && Object.keys(errors).length === 0 && handleError("No modifications were made to the data.")
            }
        } else {
            setIsloading(false);
        }
    };
    
    const handlePutResponse = (data) => {
        if (data.error) {
            if (typeof data.error === "object") {
                let keys = Object.keys(data.error);
                let errors = {};
                keys.forEach((e) => errors[e] = "Already Exists.");
                setKycErrors(errors);
                dispatch(updateSnackBarMessage("Contact Customer Support."));
            } else {
                dispatch(updateSnackBarMessage(data.error));
            }
            dispatch(updateSnackBarSeverity("error"));
            dispatch(updateSnackBarOpen(true));
            setOtpmodal({ value: false, type: '' });
        } else {
            // Handle success response for PUT method
            setKyc_Linked_data(data?.json_data)
            dispatch(updateSnackBarMessage("KYC details updated successfully"));
            setOtpmodal({ value: true, type: 'kyc' });
            setTime(30);
            dispatch(updateOtpCheck("kycOtp"));
        }
        setIsloading(false);
    };
    
    const handlePostResponse = (data) => {
        if (data.error) {
            if (typeof data.error === "object") {
                let keys = Object.keys(data.error);
                let errors = {};
                keys.forEach((e) => errors[e] = "Already Exists.");
                setKycErrors(errors);
                dispatch(updateSnackBarMessage("Contact Customer Support."));
            } else {
                dispatch(updateSnackBarMessage(data.error));
            }
            dispatch(updateSnackBarSeverity("error"));
            dispatch(updateSnackBarOpen(true));
            setOtpmodal({ value: false, type: '' });
        } else {
            // Handle success response for POST method
            setKyc_Linked_data(data?.json_data)
            dispatch(updateSnackBarMessage("KYC details added successfully"));
            setOtpmodal({ value: true, type: 'kyc' });
            setTime(30);
            dispatch(updateOtpCheck("kycOtp"));
        }
        setIsloading(false);
    };
    
    const handleError = (message) => {
        dispatch(updateSnackBarMessage(message));
        dispatch(updateSnackBarSeverity("error"));
        dispatch(updateSnackBarOpen(true));
        setIsloading(false);
    };

    const maskEmail = (email) => {       //--------------> This is for masking email
        const [localPart, domain] = email.split('@');
        if (localPart.length <= 2) {
            return `${localPart}@${domain}`; 
        }
        const visiblePart = localPart.slice(-2); 
        const maskedPart = 'X'.repeat(localPart.length - 2);
        return `${maskedPart}${visiblePart}@${domain}`;
    };
      const maskMobileNo = (mobileNumber) => {     //-----------> This is for masking mobile
        if (mobileNumber.length <= 2) {
            return mobileNumber; 
        }
        
        const visiblePart = mobileNumber.slice(-2); 
        const maskedPart = 'X'.repeat(mobileNumber.length - 2); 
        return `${maskedPart}${visiblePart}`;
    };
    const verifyRequest=(verify)=>{
        // console.log(verify)
        setOtpNotMatch(false)
        setOtp("")
        const payload={id:id,email:emailid,verify:verify,mobileno:mobileNO,dialcode:selectedCountry?.dialCode,whatsapp:isWhatsappNotificationEnabled}
        // console.log(payload)
        CustomAxios.post("verify/",payload).then(data=>
            {
                if (data.data.error){
                    dispatch(updateSnackBarMessage(data.data.error))
                    dispatch(updateSnackBarSeverity("error"))
                    dispatch(updateSnackBarOpen(true))
                   

                }
                else{
                    setVerification(true)
                }
                // console.log(data)
                //
            }
            
          )

    }
   useEffect(()=>{
    if (userreponse?.user?.dialcode+mobile==selectedCountry?.dialCode+mobileNO){
        setMobileVerified(true)
        setErrors({})
    }

   },[mobileNO,selectedCountry])
   useEffect(()=>{
    if (email==emailid){
        setEmailVerified(true)
        setErrors({})
    }

   },[emailid])

    useEffect(() => {
        if (emailid) {
            setMaskedEmail(maskEmail(emailid));
        }
        if (mobileNO) {
          setMaskedMobile(maskMobileNo(mobileNO));
      }
    }, [emailid,mobileNO ]);
    const handleResendOtp =(e) => {
        verifyRequest(verify)
        setTime(30)
      }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            
            
        }
      };
      const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
      };
      const handleComplete = async(value) => {
        const payload={
            id:id,
            email:email,
           
            otp:otp
        }
        CustomAxios.post('otp-verification/',payload).then(data=>{
            if (data.data.message=="Congratulations, your account have been successfully verified"){
                const decode=jwtDecode(data.data.access_token)
                localStorage.setItem('authtoken', data.data.access_token);
                localStorage.setItem('refreshToken', data.data.refresh_token)
                dispatch(updateFirstName(decode.first_name))
            dispatch(updateLastName(decode.last_name))
            dispatch(updateEmail(decode.email))
            dispatch(updateRole(decode.role))
            dispatch(updateConrtol(decode.control))
            dispatch(updateId(decode.id))
                if (verify=="email"){
                    setEmailVerified(true)
                    setOtpNotMatch(false)
                    setErrors({})
                }
                if(verify=="mobile"){
                    setMobileVerified(true)
                    setOtpNotMatch(false)
                    setErrors({})
                }
                setVerification(false)
            }
            else{
                setOtpNotMatch(true)

            }
            
        }
            
        )
       }
    //    console.log("console values",mobileEdit)
useEffect(()=>{
    // console.log(mobileEdit)
    if (mobileEdit){
        setMobileVerified(false)
    }
    else{
        setMobileVerified(true)
    }
    

},[mobileEdit])
useEffect(()=>{
    setErrors({})
    if (emailEdit){
        setEmailVerified(false)
    }
    else{
        setEmailVerified(true)
    }
    

},[emailEdit])
const verifyHandler=(verify)=>{
    if (verify=="email" && !emailVerified){
        setVerify("email");
    setTime(30);
   
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
        if( emailPattern.test(emailid)){
          
            verifyRequest(verify)

           }
           else{
            const newErrors={
                email:"Please enter valid Email"
            }
            setErrors(newErrors)
           }


    }
    else if((verify=="mobile" && !mobileVerified )){
        setVerify("mobile");
        setTime(30);
        // console.log(mobileNO)
        if( (selectedCountry?.dialCode === "+91" && mobileNO.length!==10) || (selectedCountry?.dialCode !== "+91" && ( mobileNO.length<4 || mobileNO.length>15 )) ){
            const newErrors={
                mobile_no:"Please enter valid Mobile No"
            }
            setErrors(newErrors)
        }
        else if( (mobileNO.length==10 && selectedCountry?.dialCode === "+91") || selectedCountry?.dialCode ){
            setErrors({})
            verifyRequest(verify)
        }

    }
    else if(verify === "identity_number" && !isIdentityNumberVerified){
        const formData = {
            first_name: first_name,
            last_name: last_name,
            // bio: bio,
            email: emailVerified&&emailid? emailid:email,
            mobile_no:mobileVerified&&mobileNO? mobileNO:mobile,
            username:username,
            country:selectedCountry?.label,
            dialcode:selectedCountry?.dialCode,
            whatsapp:isWhatsappNotificationEnabled,
            identity_number : identity_number,
            authority:selectedCountry?.value === "IN"
            ? "AADHAR"
            : "PASSPORT"

        }
        let isValidate = profileValidate(formData)
        if(isValidate){

            let payload = {};
            if (selectedCountry?.value === "IN") {
                payload = {
                id: userid,
                aadhaar_number: identity_number,
                mobileNumber: mobile,
                verifyOtpFor: "aadhaar",
                };
            } 
            else {
                payload = {
                id: userid,
                identity_number: identity_number,
                mobileNumber: mobile,
                authority_dob: IdentityDob,
                verifyOtpFor: "passport",
                };
            }
            setIsloading(true)
            CustomAxios.post("verifykyc/", payload).then((res)=>{
                if(res.data.status === "SUCCESS"){
    
                    if (payload.verifyOtpFor === "aadhaar") {
                        dispatch(updateRefID(res.data.ref_id))
                        setOtpIdentityModal({
                          value: true,
                          type: "aadhaar"
                        });
                    }
                    // dispatch(updateVerifyIdentity(true))
                }else {
                    setErrors((prev) => ({
                      ...prev,
                      identity_number: res.data.error,
                    }));
                }
                setIsloading(false)
    
            }).catch((error)=>{
                setErrors((prev) => ({
                    ...prev,
                    identity_number: error ? error.response.data.message: error.response.data.error,
                  }));
                  setIsloading(false)
            })
        }
    }
}
    const handleCancel = (event) => {
        event.preventDefault();
        setTranspinPresent(true)
        setValidations({transPin:"",resubmitPin:"",equalPin:"",numberCheck:""})
    };

    const handleChangeTranspin = (e,pin) => {
        // console.log(pin)
        const newValue = e.target.value;
        if (/^[0-9*]*$/.test(newValue)) {
            setValidations(prev => ({...prev, numberCheck: ""}));
            if(pin == 'transpin'){
                setMaskedValue(newValue);
                dispatch(updateTransactionPin(newValue))
            }
            if(pin == 'retranspin'){
                dispatch(updateReconfirmPin(newValue))
            }
            
        }else{

            setValidations(prev => ({...prev, numberCheck: "Only numbers are allowed"}));
        }
      };
    //   useEffect(()=>{
    //     // console.log(addresslist)
    //     const home=address.filter(item=>item.address_type=="billing")
        
    //     if(home.length<=0){
    //         dispatch(updateTypeEnable(true))
    //         // dispatch(updateAddress({...address,address_type: "billing",title:"Home"}))
    //     }
    //     else{
    //         // console.log("enable dfalse")
    //         dispatch(updateTypeEnable(false))
    //         // dispatch(updateAddress({...address,address_type: "shipping"}))

    //     }
    // },[address])
    // useEffect(()=>{
    //     const fetchuserdetails = async () => {
    //         // // console.log("id", id)
    //         if(userid){

    //             const payload = {
    //                 user_id: userid
    //             }
    //             await CustomAxios.post(`get_details/`, payload).then(response => {
    //                 dispatch(updateMemberResponse(response.data))
    //                 dispatch(updateGetBank(response.data.bankdetails))
    //                 dispatch(updateWithdrawRequest(response.data.withdrawRequest))
                    
    //             }
    //             ).catch(error => {
    //                 // console.log(error)
    //             })
    //         }
    //     }
    // fetchuserdetails()
    // },[transactionpinSuccess])

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
      const handlePlaceholder = (val,placeholder)=>{
        // console.log(val,placeholder)
        // const newDate = new Date(date);
        // newDate.setDate(date.getDate() + days);
        
        if(!val){
            // dispatch(updateInventoryForm(...inventoryFormData,inventoryFormData.expiry_alert:))
          return <TranslateText sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</TranslateText>
        }
        
        return val
      }
    //   useEffect(()=>{
    //     if (!kyc_details?.id){
    //         setKycDetails({...kyc_details,mobileNo:mobile})
    //     }
       
    //   },[mobile])
      useEffect(()=>{
        setKycDetails({...kyc_details,type:selectedCountry?.value=='IN'?'pan':"passport"})
      },[selectedCountry])

    return (

        <Box sx={{overflowY:cartSidebar?"hidden":"auto",height:"100vh", bgcolor:{ xs:"#fff",sm:"grey.100"}, minHeight: "100vh", display: "flex", alignItems: "start", justifyContent: "start" }}>
            <Layout />
            <MessageBar/>
            <Box sx={{ alignSelf: "start", marginTop:{xs:"70px", sm:"140px"}, marginBottom: "100px", justifySelf: "center", display: "flex", justifyContent: "center", width: "100%", gap: {xs:"16px",md:"36px"} }}>
                {isTabletHori && <Paper sx={{ height: "max-content",width:"20%",position:"sticky",top:"140px" }} elevation={1}>
                    {SideBarList('left')}
                </Paper>}
                
                <Box  id="mobileno" elevation={1} sx={{ width:  {xs:"100%",md:"95%",lg:"65%"}, display: "flex", flexDirection: "column", gap: "36px" }}>


                    <Box component={isLaptop ? Paper : Box} sx={{ padding: "20px"}}>
                    <Stack direction={"row"} sx={{justifyContent:"space-between",alignItems:"center",marginBottom:"16px"}}>
                            <Stack direction="row" sx={{gap:"10px",alignItems:"center"}}>
                                <TranslateText sx={{fontFamily:"Poppins,Roboto",fontWeight:500,lineHeight:"24px",fontSize:"16px",letterSpacing:"0.15px",color:"#000000"}}>Overview</TranslateText>
                                {/* <Box component="img" src={security_png} sx={{width:"20px",height:"20px"}}/> */}
                            </Stack>
                            {!isOverviewSecReload && <EditOutlined sx={{color:profileEdit?"grey.700":"primary.light",cursor:profileEdit?"not-allowed":"pointer"}} onClick={()=>setProfileEdit(true)} />}
                        </Stack>
                        
                        {!isOverviewSecReload ?<Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" ,maxWidth:"703px" }}>
                        <Box>
                        
                                <TranslateText sx={formStyle.label} color="grey.900"> User Name <span component="span" sx={{color:"#EF4444"}}>*</span> </TranslateText>
                                <Box>
                                    {/* <TranslateText sx={formStyle.label} color="grey.700">Email</TranslateText> */}
                                    <InputBase disabled sx={formStyle.inputBase} value={username} onChange={(e) => dispatch(updateUserName(e.target.value))} />
                                    {/* <TranslateText sx={{marginTop:"10px"}}>Maximum 8 character. You can share this with friends for referrals.</TranslateText>
                                    {errors.username && <TranslateText sx={{color:"error.light"}}>{errors.username}</TranslateText>} */}
                                </Box>
                                {/* <TranslateText sx={formStyle.label} color="grey.700">Email</TranslateText> */}
                                {/* <InputBase sx={formStyle.inputBase} value={email} onChange={(e) => dispatch(updateUserName(e.target.value))} /> */}
                                {/* {errors.userName && <TranslateText sx={{color:"error.light"}}>{errors.userName}</TranslateText>} */}
                            </Box>
                            
                           
                            <TranslateText sx={formStyle.label} color="grey.900">Profile image</TranslateText>
                             {selectedImage!="" && imagestatus ? <Box onClick={() => { fileRef.current && fileRef.current.click() }} component="img" src={selectedImage} sx={{width:isLaptop?"172px":"120px",height:isLaptop?"172px":"120px",objectFit:"cover",background:"#CBD5E1",border:"none",cursor:!profileEdit?"default":"pointer"}} ></Box>:(profile) && imagestatus  && <img  onClick={() => { fileRef.current && fileRef.current.click() }} src={profile} style = {{width:"172px",height:"172px",objectFit:"cover",background:"#CBD5E1",border:"none",cursor:!profileEdit?"default":"pointer"}} alt='Profile' onError={(e) => {setImageStatus(false)}}/>}
                            {/* {} */}
                            <Box sx={{display:"flex", flexDirection:"row", gap:"8px",marginTop:"10px"}}>
                            <Box sx={{display:"flex",alignItems:"center",gap:"8px"}}>
                            {/* <InsertPhotoIcon color='primary'/> */}
                           {!selectedImage!="" &&  (!profile) &&<Box onClick={() => { fileRef.current && fileRef.current.click() }} sx={{ cursor:!profileEdit?"default":"pointer",width:"185px",height:"185px",bgcolor:"grey.300",display:"flex",alignItems:"center",justifyContent:"center",fontSize:"24px" }}> 
                                <TranslateText>+</TranslateText>
                           </Box>}
                           <input disabled={!profileEdit}  ref={fileRef}
                                id="image-upload"
                                type="file"
                                accept="image/*" // Allow only image files
                                style={{padding: '10px 20px',
                                marginTop:"8px",
                                marginBottom:"18px",
                                color: 'white',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                display:"none",
                                color:"var(--pallate-green-500, #22C55E)"
                            }}
                                onChange={uploadImage}
                            />
                            </Box>
                        </Box>

                            
                            <Box sx={{ display:"flex" ,flexDirection:{xs:"column",sm:"row"} ,gap:"16px"}}>
                            <Box sx={{ width:"100%"}}>
                                <TranslateText sx={formStyle.label} color="grey.900">First Name <span component="span" sx={{color:"#EF4444"}}>*</span></TranslateText>
                                <InputBase disabled={!profileEdit} sx={formStyle.inputBase} value={first_name} onChange={(e) => dispatch(updateFirstName(e.target.value))} />
                                {errors.firstname && <TranslateText sx={{color:"error.light"}}>{capitalize(errors.firstname)}</TranslateText>}
                            </Box>
                            <Box  sx={{ width:"100%"}}>
                                <TranslateText sx={formStyle.label} color="grey.900">Last Name <span component="span" sx={{color:"#EF4444"}}>*</span></TranslateText>
                                <InputBase disabled={!profileEdit} sx={formStyle.inputBase} value={last_name} onChange={(e) => dispatch(updateLastName(e.target.value))} />
                                {errors.lastname && <TranslateText sx={{color:"error.light"}}>{capitalize(errors.lastname)}</TranslateText>}
                            </Box>
                            </Box>
                            {/* <Box>
                                <TranslateText sx={formStyle.label} color="grey.900">Bio</TranslateText>

                                <InputBase multiline rows={5} sx={formStyle.textBox} value={bio} onChange={(e) => dispatch(updateBio(e.target.value))} /> */}

                                {/* {errors.userName && <TranslateText sx={{color:"error.light"}}>{errors.userName}</TranslateText>} */}
                            {/* </Box> */}

                            {/* <Button sx={formStyle.button} variant="contained" onClick={updateProfile} >Update</Button> */}
                            <Box sx={{ display:"flex" ,flexDirection:{xs:"column"} ,gap:"16px"}}>
                            <Box sx={{ width:"100%" ,}}>
                                <TranslateText sx={formStyle.label} color="grey.900">Email ID {member=='member'&&<span component="span" sx={{color:"#EF4444"}}>*</span> }</TranslateText>
                                <Box sx={{display:"flex",gap:"16px",flexDirection:{xs:'column',md:'row'}}}>
                                    {/* <TranslateText sx={formStyle.label} color="grey.700">Email</TranslateText> */}
                                    {/* onKeyDown={(e)=>{if(e.key=='Enter'){verifyHandler('email')}}} */}
                                    <InputBase disabled={!profileEdit} sx={formStyle.inputBase}  onKeyDown={(e)=>{if(e.key=='Enter'){verifyHandler('email')}}}  value={emailid || emailEdit ? emailid:email} endAdornment={emailVerified&& email&&<VerifiedIcon sx={{color:"secondary.light"}} />} onChange={(e) =>{ setEmailid(e.target.value);setEmailEdit(true);setEmailVerified(false)}} />
                                    {
                                        email!=emailid&& emailid!="" && !emailVerified&&<Box sx={{display:"flex",gap:"16px"}}>
                                    <Button sx={formStyle.button} variant="contained" onClick={()=>verifyHandler("email") }>{isloading? <CircularProgress color="inherit" size={30}/> : <TranslateText>Verify</TranslateText>}</Button>
                                    <Button sx={formStyle.outlinedButton} variant="outlined" onClick={()=>{setEmailid("");setEmailEdit(false);setErrors({})}} >  <TranslateText>Cancel</TranslateText></Button></Box>}
                                    
                                </Box>
                                {errors.email && <TranslateText sx={{color:"error.light"}}>{capitalize(errors.email)}</TranslateText>}
                                {/* <TranslateText sx={formStyle.label} color="grey.700">Email</TranslateText> */}
                                {/* <InputBase sx={formStyle.inputBase} value={email} onChange={(e) => dispatch(updateUserName(e.target.value))} /> */}
                                {/* {errors.userName && <TranslateText sx={{color:"error.light"}}>{errors.userName}</TranslateText>} */}
                            </Box>
                            {/* <Box sx={{ width:"100%"}}>
                                <Stack>
                                    <TranslateText sx={formStyle.label}>Identification Number ({selectedCountry?.value === "IN"? "Aadhar":"Passport"}) <span component="span" sx={{color:"red"}}>*</span></TranslateText>
                                    <Stack direction={{xs:'column',md:'row'}} sx={{gap:"16px"}}>
                                        {selectedCountry?.value !== "IN" && (
                                            <DatePicker
                                            value={IdentityDob}
                                            onChange={(e) => {setIdentityDob(e)}}
                                            disabled={  profileEdit ? 
                                                IdentityDob? 
                                                    IdentityDob.format("YYYY-MM-DD") === userreponse?.user?.authority_dob ? true : false : false : true}
                                            // disabled={!iskyceditable}
                                            className="identity-datepicker"
                                            format="DD/MM/YYYY"
                                            sx={{
                                                "&.identity-datepicker .MuiInputBase-root.MuiOutlinedInput-root":
                                                { ...inputStyles.inputBase, border: "none",maxWidth:{xs:"100%",md:"auto"} },
                                            }}
                                            maxDate={dayjs()} 
                                            />
                                        )}
                                        <InputBase disabled={userreponse.user?.identitynumber || isIdentityNumberVerified?true:!profileEdit} sx={{...formStyle.inputBase,width:"100%"}} value={identity_number} onChange={(e) => {
                                                let cleanedValue = e.target.value;
                                                if (selectedCountry?.value === "IN") {
                                                    cleanedValue = cleanedValue
                                                    .replace(/\D/g, "")
                                                    .slice(0, 12); // Aadhaar: only digits, max 12
                                                } else {
                                                    cleanedValue = cleanedValue.slice(0, 15); // Passport: max 15 characters (alphanumeric)
                                                }
                                                setIdentityNumber(cleanedValue)
                                            }} 
                                            placeholder={selectedCountry?.value === "IN"? "Please enter your aadhar number":"Please enter your passport number"}
                                            endAdornment={identity_number && isIdentityNumberVerified && <VerifiedIcon sx={{color:"secondary.light"}} />}
                                        />
                                        {
                                            selectedCountry?.value === "IN" && !isIdentityNumberVerified && (identity_number != "" )  &&
                                                <Stack direction={'row'} sx={{gap:"16px"}}>
                                                    {
                                                        isloading ?
                                                        <Button sx={formStyle.button} variant="contained" ><CircularProgress color="inherit" size={30}/></Button>
                                                        :
                                                        <Button sx={formStyle.button} variant="contained" onClick={()=>verifyHandler("identity_number") } >{isloading? <CircularProgress color="inherit" size={30}/> : <TranslateText>Verify</TranslateText> }</Button>
                                                    }
                                                    <Button sx={formStyle.outlinedButton} variant="outlined" onClick={()=>{dispatch(updateVerifyIdentity(false)); setIdentityNumber(checkForNullUndefined(userreponse?.user?.identitynumber)); setErrors({}) }} ><TranslateText> Cancel</TranslateText></Button>
                                                </Stack>
                                        }
                                    </Stack>
                                    {errors?.identity_number && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", mt: "4px" }}>{errors.identity_number}</TranslateText>}
                                </Stack>
                            
                            </Box> */}
                            <Box  sx={{ width:"100%"}}>
                                    <Stack>
                                        <TranslateText sx={formStyle.label}>Mobile Number <span component="span" sx={{color:"red"}}>*</span></TranslateText>
                                        <Stack direction={{xs:"column",sm:'row'}} gap={"16px"}>
                                            <Dropdown filter style={{...formStyle.inputBase,padding:"6px 14px",display:"flex",alignItems:"center",borderColor:"#bdbdbd",width:"auto"}} value={selectedCountry?.value} options={countries} optionLabel="label" optionValue="value" itemTemplate={(option)=>{
                                                let e = option
                                                return <Box onClick={()=>{setSelectedCountry(e);setIsWhatsappNotificationEnabled(e.dialCode!="+91"?true:false);}}><img src={e.flag} width={'20px'} height={'15px'} style={{marginRight:"15px"}}/>{e.label}</Box>
                                            }} 
                                            onChange={()=>{setMobileEdit(true);setMobileVerified(false)}}
                                            // disabled={!profileEdit || userreponse.account?.role !=="user" || member!=='member' || userreponse.user?.mobile}
                                            disabled
                                            placeholder="Select the country"
                                            dropdownIcon={<ArrowDropDown/>}
                                            valueTemplate={(option,props)=>{
                                                    let e = option
                                                    return(
                                                        e ?<Stack direction={'row'} sx={{gap:"6px",alignItems:"center"}}>
                                                            <img src={e?.flag} width={'20px'} height={'15px'} style={{marginRight:"15px"}}/>
                                                            <TranslateText>{e?.label}</TranslateText>
                                                        </Stack> : "Select the country"
                                                    )
                                                
                                            }}
                                                                                        />
                                            {/* <Select disabled={!profileEdit} value={selectedCountry?.dialCode || ''} inputProps={{style:{"&:hover":{border:"none !important",outline:"none !important"}}}} sx={{...formStyle.inputBase,width:{xs:"100%",sm:"max-content"},py:0,border:"none","&:hover":{border:"none !important",outline:"none !important"},"& .Mui-:hover":{border:"none"}}} onChange={(e)=>{setMobileEdit(true);setMobileVerified(false)}}>
                                                {
                                                    countries.map((e)=>{
                                                        return <MenuItem value={e.dialCode} onClick={()=>{setSelectedCountry(e);setIsWhatsappNotificationEnabled(e.dialCode!="+91"?true:false);}}><img src={e.flag} width={'20px'} height={'15px'} style={{marginRight:"15px"}}/>{e.label}</MenuItem>
                                                    })
                                                }
                                                    
                                            </Select> */}
                                            <Box sx={{display:"flex", flexDirection:{xs:'column',md:'row'},gap:"16px",width:"100%"}}>
                                            {/* onKeyDown={(e)=>{if(e.key=='Enter'){verifyHandler('mobile')}}} */}
                                            <InputBase disabled={!profileEdit || userreponse.account?.role !=="user"} autoComplete="tel-national"   onKeyDown={(e)=>{if(e.key=='Enter'){verifyHandler('mobile')}}} value={mobileNO || mobileEdit ?mobileNO:mobile} sx={{...formStyle.inputBase}} 
                                            startAdornment={<Typography sx={{pr:"5px",fontSize:"16px"}} >{selectedCountry?.dialCode}</Typography>} 
                                            endAdornment={mobileVerified&&mobile&& <VerifiedIcon sx={{color:"secondary.light"}} />}  placeholder={selectedCountry?.dialCode === "+91"?"Enter your 10 Digit mobile number":"Enter your mobile number" }
                                            onChange={(e)=>{setMobileNo(e.target.value.replace(/\D/g, ''));setMobileEdit(true);setMobileVerified(false)}}  />
                                            {
                                                userreponse?.user?.dialcode+mobile!=selectedCountry?.dialCode+mobileNO&& mobileNO!=""&& !mobileVerified &&<Box sx={{display:"flex",gap:"16px"}}>
                                    
                                            <Button sx={formStyle.button} variant="contained" onClick={()=>verifyHandler("mobile") } >{isloading? <CircularProgress color="inherit" size={30}/> :<TranslateText>Verify</TranslateText> }</Button>
                                            <Button sx={formStyle.outlinedButton} variant="outlined" onClick={()=>{setMobileNo("");setMobileEdit(false);setErrors({});setSelectedCountry( countries.filter((e)=>e.dialCode === userreponse?.user?.dialcode)?.[0] )}} > <TranslateText> Cancel</TranslateText></Button></Box>     }
                                            </Box>
                                        </Stack>
                                        {errors?.mobile_no && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", mt: "4px" }}>{capitalize(errors.mobile_no)}</TranslateText>}
                                    </Stack>
                            </Box>
                            
                            <Box sx={{width:"100%"}}>
                                <Stack direction={'row'} sx={{gap:"8px",alignItems:"center"}}>
                                    <Checkbox disabled={!profileEdit || selectedCountry?.dialCode!="+91"?true:false || (userreponse.account?.role!=="user" || userreponse.account?.role!=="sc")} checked={isWhatsappNotificationEnabled || userreponse.account?.role !=="user" ||userreponse.account?.role !=="sc"} disableTouchRipple disableRipple sx={{pl:0}} onChange={(e)=>setIsWhatsappNotificationEnabled(e.target.checked)}/>
                                    <TranslateText>Notify me on WhatsApp.</TranslateText>
                                                
                                </Stack>
                            </Box>
                            </Box>
                            {
                                profileEdit&&  <Box  sx={{alignSelf:"flex-start",display:"flex",gap:"16px"}}> 
                                <Button disabled={!emailVerified||!mobileVerified } sx={formStyle.button} variant="contained" onClick={updateProfiles} >{isloading? <CircularProgress color="inherit" size={30}/> : <TranslateText>Update</TranslateText>}</Button>
                                <Button sx={formStyle.outlinedButton} variant="outlined" onClick={()=>{setProfileEdit(false);setErrors({});setMessage("");setEmailid("");setEmailEdit(false);setMobileNo("");setMobileEdit(false);setIsWhatsappNotificationEnabled(userreponse?.user?.whatsapp); setSelectedCountry( countries.filter((e)=>e.dialCode === userreponse?.user?.dialcode)?.[0] ) ; setIdentityNumber(checkForNullUndefined(userreponse?.user?.identitynumber)) ; setIdentityDob(checkForNullUndefined(userreponse?.user?.authority_dob) ? dayjs(userreponse?.user?.authority_dob) : null) ;}} ><TranslateText>Cancel</TranslateText> </Button>
    
                                </Box>
                            }
                            

                            

                            {message !== "" && <TranslateText sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "green" }}>{message}</TranslateText>}
                        </Box>
                        :
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" ,maxWidth:"703px" }}>
                            <Skeleton sx={{minWidth:0,height:"48px",transform:"none"}}></Skeleton>
                            <Skeleton sx={{minWidth:0,width:isLaptop?"172px":"120px",height:isLaptop?"172px":"120px",transform:"none"}}></Skeleton>
                            <Skeleton sx={{minWidth:0,height:"48px",transform:"none"}}></Skeleton>
                            <Skeleton sx={{minWidth:0,height:"48px",transform:"none"}}></Skeleton>
                            <Skeleton sx={{minWidth:0,height:"48px",transform:"none"}}></Skeleton>
                            <Skeleton sx={{minWidth:0,height:"48px",transform:"none"}}></Skeleton>
                        </Box>
                        }
                       

                    </Box>
                    {/* <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: "20px", }}>
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                            
                            {/* {errors.userName && <TranslateText sx={{color:"error.light"}}>{errors.userName}</TranslateText>} 
                            <Button sx={formStyle.button} variant="contained"  onClick={updateProfile} >Update</Button>
                        </Box>

                        
                    </Box> */}
                    {userreponse.account?.role !=="mkt" && <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: "20px", }}>
                    <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <TranslateText sx={{fontFamily:"Poppins,Roboto",fontWeight:500,lineHeight:"24px",fontSize:"16px",letterSpacing:"0.15px",color:"grey.900"}}>Delivery and Billing address</TranslateText>
                            {/* <TranslateText sx={[formStyle.label, { cursor: "pointer" }]} color="primary" onClick={() => { dispatch(updateAddressModal(true)); dispatch(updateAddress({address_type:typeEnable?"billing":null,title:typeEnable?"Home":null })); }}>+ Add</TranslateText> */}



                            {/* {errors.userName && <TranslateText sx={{color:"error.light"}}>{errors.userName}</TranslateText>} */}
                        </Box >
                        <Grid container sx={{ display: "flex",alignItems:"center"}}>


                            {address?.map((item,item_id) => {
                                return <Grid key={item_id} xs={12} md={4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%", padding: "10px" } }} onClick={() => editAddress(item)}><Box sm={3} item sx={{ border: "1px solid", padding: "12px", borderColor: "grey.500", borderRadius: "10px" }}>
                                    {/* <TranslateText sx={{ fontWeight: "600",textTransform:"capitalize" }}>{item.address_type}</TranslateText> */}
                                    <TranslateText sx={{ fontWeight: "600",textTransform:"capitalize" }}> {item.title},</TranslateText>
                                    <TranslateText sx={{ wordBreak: "break-all" }}>{item.address}</TranslateText>
                                    <TranslateText sx={{ whiteSpace: "pre-wrap" }}>{item.town_city}, {item.state}, {item.country}, {item.pin}.</TranslateText>
                                    <TranslateText > Mobile No:{item.mobileno} </TranslateText>

                                </Box>
                                </Grid>

                            })}
                            <Grid xs={12} md={4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%",padding: "10px" },flexDirection:"columm" }}><Box onClick={() => { dispatch(updateAddressModal(true)); dispatch(updateAddress({address_type:typeEnable?"billing":"shipping",title:typeEnable?"Home":null,id:'',town_city:"",mobileno:"",pin:"",address:"", state:"" })); }} sm={3} item sx={{border: "1px solid", padding: "12px", borderColor: "primary.light", borderRadius: "10px", display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer",height:"100px"}}>
                                    <TranslateText sx={{ fontWeight: "600",textTransform:"capitalize",color:"primary.light" ,textAlign:"center"}} >+</TranslateText>
                                    

                                </Box>
                                </Grid>
                        </Grid>

                        {/* <Button sx={formStyle.button} variant="contained" >Update</Button> */}
                    </Box>

                </Box>}
                {member=='non_member'&&
                <ReferralUpdate/>}
                {(userreponse.account?.role ==="user"||userreponse.account?.role ==="sc") &&member=='member'&& <Box id="kyc-details" ref={kyc_ref} component={isLaptop? Paper :Box} elevation={1} sx={{padding:{xs:"20px",md:"24px"},display:"flex",flexDirection:"column",gap:"16px", animation: isLaptop && !userreponse?.account?.kyc_status && urlParams?.get('scrollto') === "kyc" ? `${glowingBorder} 2s infinite ease-in-out` : '',}}>
                    <Stack sx={{gap:"4px"}}>
                        <Stack direction={"row"} sx={{justifyContent:"space-between",alignItems:"center"}}>
                            <Stack direction="row" sx={{gap:"10px",alignItems:"center"}}>
                                <TranslateText sx={{fontFamily:"Poppins,Roboto",fontWeight:500,lineHeight:"24px",fontSize:"16px",letterSpacing:"0.15px",color:"#000000"}}>KYC Details</TranslateText>
                                <Box component="img" src={security_png} sx={{width:"20px",height:"20px"}}/>
                            </Stack>
                            <EditOutlined sx={{color:iskyceditable?"grey.700":"primary.light",cursor:iskyceditable?"not-allowed":"pointer"}} onClick={()=>{!kyc_details.mobileNo && setOtpmodal({value:true}) ; kyc_details.mobileNo && setIskyceditable(true) }} />
                        </Stack>
                        <TranslateText sx={{fontFamily:"Poppins,Roboto",fontWeight:400,fontSize:"14px",lineHeight:"20px",letterSpacing:"0.25px",color:"#000000",width:{xs:"100%",md:"auto"}}}>Complete KYC to verify your identity and enable future edits. Phone number used here will be for KYC/bank updates.</TranslateText>
                    </Stack>
                        <Stack sx={{width:{xs:"100%",lg:"703px"},gap:"16px"}}>
                            <Stack direction={{xs:"column",md:"row"}} sx={{gap:"16px",justifyContent:"space-between"}}>
                                <Stack sx={{gap:"8px",width:"100%"}}>
                                    <TranslateText sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>Full Name <span component="span" sx={{color:"#EF4444"}}>*</span></TranslateText>
                                    <InputBase value={kyc_details.fullName} disabled={!iskyceditable} sx={formStyle.inputBase} placeholder="Enter your full name" onChange={(e)=>setKycDetails({...kyc_details,fullName:e.target.value})}/>
                                    {kyc_errors?.fullName && <TranslateText color="error">{kyc_errors.fullName}</TranslateText>}
                                </Stack>
                                <Stack sx={{gap:"8px",width:"100%"}}>
                                    <TranslateText sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>DOB <span component="span" sx={{color:"#EF4444"}}>*</span></TranslateText>
                                    {/* <Calendar style={formStyle.inputBase} icon/> */}
                                    <DatePicker disableFuture  value={kyc_details.dob} onChange={(e)=>{setKycDetails({...kyc_details,dob:e})}} disabled={!iskyceditable} className="pan-datepicker" format="DD/MM/YYYY" sx={{"&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root":{...formStyle.inputBase,border:"none"}}}/>
                                    {kyc_errors?.dob && <TranslateText color="error">{kyc_errors.dob}</TranslateText>}
                                </Stack>
                            </Stack>
                             <Stack sx={{gap:"8px"}}>
                                <TranslateText sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>Mobile Number <span component="span" sx={{color:"#EF4444"}}>*</span></TranslateText>
                                <InputBase value={kyc_details.mobileNo} disabled={true} sx={formStyle.inputBase} startAdornment={<Typography sx={{pr:"5px",fontSize:"16px"}} >{selectedCountry?.dialCode}</Typography>} placeholder="Enter mobile number" onChange={(e)=>setKycDetails({...kyc_details,mobileNo:e.target.value})}/>
                                <TranslateText sx={{fontWeight:400,fontSize:"12px",lineHeight:"16px",color:"#1E293B"}}>Mobile number for OTP verification when updating KYC.
                                {/* </TranslateText> */}
                                 or bank information.</TranslateText>
                                {kyc_errors?.mobileNo && <TranslateText color="error">{kyc_errors.mobileNo}</TranslateText>}
                            </Stack>
                            <Stack direction={{xs:"column",md:"row"}} sx={{gap:"16px",justifyContent:"space-between"}}>
                            <Stack sx={{gap:"8px",width:"100%"}}>
                                <TranslateText sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>{selectedCountry?.value === "IN"? 'PAN':'Passport No'} <span component="span" sx={{color:"#EF4444"}}>*</span></TranslateText>
                                <InputBase inputProps={{style:{textTransform:"uppercase"}}}  value={kyc_details.pan} disabled={!iskyceditable || userreponse?.kyc_details?.pan } sx={{...formStyle.inputBase,textTransform:"uppercase"}}  placeholder={selectedCountry?.value === "IN"?"Enter PAN Number":"Enter Passport No"} onChange={(e)=>setKycDetails({...kyc_details,pan:e.target.value})}/>
                                {kyc_errors?.pan && <TranslateText color="error">{kyc_errors.pan}</TranslateText>}
                            </Stack>
                            {selectedCountry?.value !== "IN"&&
                            <Stack sx={{gap:"8px",width:"100%"}}>
                                    <TranslateText sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>Expiry Date <span component="span" sx={{color:"#EF4444"}}>*</span></TranslateText>
                                    {/* <Calendar style={formStyle.inputBase} icon/> */}
                                    <DatePicker disablePast  value={kyc_details.expirydate} onChange={(e)=>{setKycDetails({...kyc_details,expirydate:e})}} disabled={!iskyceditable} className="pan-datepicker" format="DD/MM/YYYY" sx={{"&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root":{...formStyle.inputBase,border:"none"}}}/>
                                    {kyc_errors?.expirydate && <TranslateText color="error">{kyc_errors.expirydate}</TranslateText>}
                                </Stack>}
                            </Stack>
                            {iskyceditable && <Stack  sx={{gap:"16px",display:"flex",justifyContent:"flex-start",flexDirection:{xs:"column",sm:"row"}}}>
                                <Button type="submit" sx={{...formStyle.button,color:"#FFFFFF"}} onClick={(e)=>handleKycSubmit(e)}>{isloading? <CircularProgress color="inherit" size={30}/> :<TranslateText>Submit</TranslateText>}</Button>
                                <Button type="reset" variant="outlined" sx={{...formStyle.button,bgcolor:"unset","&:hover":{bgcolor:"unset"},color:"FFFFFF"}} onClick={()=>handleKycCancel()}><TranslateText>Cancel</TranslateText></Button>
                            </Stack>}
                        </Stack>
                    <Stack></Stack>
                </Box>}

                {(userreponse.account?.role ==="user"||userreponse.account?.role ==="sc") &&member=='member' && <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: "20px", }}>
                   
                <BankComponent/>
                </Box>}
                {(userreponse.account?.role ==="user"||userreponse.account?.role ==="sc") && member=='member' && <Box id='transaction_pin' component={isLaptop ? Paper : Box} ref={transpin_ref} elevation={1} sx={{display:"flex", flexDirection:"column", padding: "24px", justifyContent:isLaptop?"space-between":"normal" ,background:"#FFFFFF",gap:"16px", animation: isLaptop && !userreponse?.account?.transaction_pin && urlParams?.get('scrollto') === "transaction_pin" ? `${glowingBorder} 2s infinite ease-in-out` : ''}}>
                    <Box sx={{ display:"flex", gap:"12px",flexDirection:"column"}}>
                        <Box sx={{display:"flex",gap:"10px"}}>
                             <TranslateText sx={{ fontWeight: 500, fontSize: "16px",lineHeight:"24px",letterSpacing:"0.15px" }} color="grey.900">Security Code</TranslateText>
                             <Box component='img' src={SheildIcon}></Box>
                        </Box>
                        <Box>
                             <TranslateText sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px",letterSpacing:"0.25px" }} color="grey.900">Set the 6 digit security code for secured transaction</TranslateText>
                        </Box>
                    </Box>
                    <Box sx={{ display:"flex", gap:"12px",flexDirection:"column"}}>
                        <Box sx={{ display:"flex", gap:"8px",flexDirection:"column"}}>
                             <TranslateText sx={{ fontWeight: 400, fontSize: "16px",lineHeight:"24px"}} color="grey.900">Transaction PIN <span component="span" sx={{color:"red"}}>*</span></TranslateText>
                             <InputBase inputProps={{ maxLength: 6  }}  disabled = {transPinPresent?true:false} sx={formStyle.inputBasePin} value={maskedValue} onChange={(e) =>handleChangeTranspin(e,'transpin')} onBlur={(e)=>handleCheckCharacterLength(e)}  onFocus={handleFocus} onCopy={handleCopy}/>
                             {validations.transPin!=="" && <TranslateText component="span" sx={{color:"red"}}>{validations.transPin}</TranslateText>}
                        </Box>
                         {!transPinPresent && <Box sx={{ display:"flex", gap:"8px",flexDirection:"column"}}>
                             <TranslateText sx={{ fontWeight: 400, fontSize: "16px",lineHeight:"24px"}} color="grey.900">Reconfirm PIN <span component="span" sx={{color:"red"}}>*</span></TranslateText>
                             <InputBase inputProps={{ maxLength: 6 }}  sx={formStyle.inputBasePin} value={reconfirmPin} onChange={(e) => handleChangeTranspin(e,'retranspin')} onBlur={(e)=>handleCheckCharacterLength(e)} onCopy={handleCopy}/>
                              {validations.resubmitPin!=="" && <TranslateText component="span" sx={{color:"red"}}>{validations.resubmitPin}</TranslateText>}
                              {validations.equalPin!=="" && <TranslateText component="span" sx={{color:"red"}}>{validations.equalPin}</TranslateText>}
                             {validations.numberCheck!=="" && <TranslateText component="span" sx={{color:"red"}}>{validations.numberCheck}</TranslateText>}
                        </Box>}
                        {/* {transPinPresent && <TranslateText onClick={(e)=>sendOTP(e,'reSubmit')}  sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px",cursor:"pointer"}} color="primary">Forgot Secured Transaction PIN?</TranslateText>} */}
                        {transPinPresent && <TranslateText onClick={(e)=>changePin(e)}  sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px",cursor:"pointer"}} color="primary">Forgot Secured Transaction PIN?</TranslateText>}
                    </Box>
                   {!transPinPresent&& <Box sx={{ display:"flex", gap:"16px"}}>
                        <Button variant="contained" disabled={transactionPin&&reconfirmPin?false:true} sx={{bgcolor:transactionPin&&reconfirmPin?"primary.light":"secondary",color:"primary.contrastText",padding:"8px 12px",textTransform: "none"}} onClick={(e)=>sendOTP(e,'transactionpin')}><TranslateText sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px"}}>Submit</TranslateText></Button>
                        <Button variant="outlined" sx={{padding:"8px 12px",textTransform: "none"}} onClick={(e)=>retransClose?handleCancel(e):handleClearPin(e)}><TranslateText sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px"}} >Cancel</TranslateText></Button>
                    </Box>}
                </Box>}
                <Box component={isLaptop ? Paper : Box} elevation={1} sx={{display:"flex", flexDirection:isLaptop?"row":"column", padding: "20px", justifyContent:isLaptop?"space-between":"normal" }}>
                <Box sx={{ display:"flex", flexDirection:"column", width:"100%", gap:"21px"}}>
                <TranslateText sx={{ fontWeight: 500, fontSize: "22px" }} color="grey.900">Data Privacy and Security</TranslateText>
                <TranslateText sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">Delete Profile</TranslateText>
                <Box sx={{ display:"flex", flexDirection:"column",gap:"8px" }}>
                <TranslateText sx={{ fontWeight: 500, fontSize: "14px" }} color="grey.900">Reason for deletion</TranslateText>
                <Box sx={{ display:"flex", flexDirection:{sm:"row",xs:"column"},alignItems:"center",justifyContent:"space-between",gap:"16px"}}>
                {/* <Box> */}
                {/* <FormControl fullWidth> */}
                {/* <InputLabel id="demo-simple-select-label">Select Reason</InputLabel>  */}
                    <Select displayEmpty sx={{ gap: "8px", borderRadius: "4px",padding:"4px", height: "48px", width:isMobile?"100%":"300px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end",padding:"5px"  } ,"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"}}}  value={reasonSelect} disabled={allow?true:false} renderValue={(val) => handlePlaceholder(val, 'Select Reason')} onChange={(e) => handleReasonSelect(e)}>
                    <MenuItem value={"No longer using the service"}><TranslateText> No longer using the service</TranslateText></MenuItem>
                    <MenuItem value={"Privacy concerns"}><TranslateText>Privacy concerns</TranslateText></MenuItem>
                    <MenuItem value={"Too many notifications"}><TranslateText>Too many notifications</TranslateText></MenuItem>
                    <MenuItem value={"User experience issues"}><TranslateText>User experience issues</TranslateText></MenuItem>
                    <MenuItem value={"Found a better alternative"}><TranslateText>Found a better alternative</TranslateText></MenuItem>
                    <MenuItem value={"Other Reason"}><TranslateText>Other Reason</TranslateText></MenuItem>
                    </Select>
                {/* </FormControl> */}
                
                {/* </Box> */}
                <Button variant="contained" sx={{ padding:"8px 12px", textTransform: "none", fontWeight: 600, fontSize: "14px","&:hover":{bgcolor:allow?"grey.700":"primary.light",color:"primary.contrastText"}, background:allow?"grey":"primary.light", borderRadius:"5px",fontSize:"",textTransform: 'none'}} disable={allow?true:false} onClick={handleAccDelRequest}>{isDloading? <CircularProgress color="inherit" size={30}/> : <TranslateText>Submit for request</TranslateText>}</Button>
                </Box>
                
                </Box>
                {
                // message!=""? <Box sx={{ display:"flex", flexDirection:"row", width:"100%", background:"#37BE5F", padding:"12px", gap:"12px"}}>
                // <img src={CheckCirleIcon} style={{width:"32px", height:"32px"}}/>
                // <TranslateText sx={{ fontWeight: 500, fontSize: "14px" }} color="#FFF">{pmessage}</TranslateText>   
                // </Box>:
                reasonSelect!=""? <Box sx={{ display:"flex", flexDirection:"row", width:"100%", gap:"12px"}} >
                        <img src={ExcIcon} style={{ width:"24px", height:"24px" }}/>
                        <TranslateText sx={{ fontWeight: 500, fontSize: "16px" }} color="grey.900">Deleting your profile permanently erases all associated data, including rankings, teams, and levels, and this action cannot be undone.</TranslateText>
                </Box>:
                allow ? <Box sx={{ display:"flex", flexDirection:"row", width:"100%", gap:"12px"}} >
                        <img src={ExcRedIcon} style={{ width:"24px", height:"24px" }}/>
                        <TranslateText sx={{ fontWeight: 500, fontSize: "16px" }} color="grey.900">Profile deletion cannot be completed at this time. Please withdraw funds from your MAYI - Wallet and ensure all orders are received before proceeding.</TranslateText>
                </Box>:<></>}
                </Box>
                
                </Box>
                
             <OtpTransPin otpModal={otpModal} setOtpmodal = {setOtpmodal}  reTransPinOtp = {reTransPinOtp} setReTransPinOtp={setReTransPinOtp} handleSubmitPin={handleSubmitPin} transPinPresent={transPinPresent} setTranspinPresent={setTranspinPresent} handleClearPin={handleClearPin} sendOTP={sendOTP} time={time} setTime = {setTime} otpNotmatch={otpNotmatch} setOtpNotMatch={setOtpNotMatch} retransClose={retransClose} handleKycSubmit={handleKycSubmit} setkycPresent={setkycPresent} kycPresent={kycPresent} kyc_details={kyc_details} fetchuserdetails={fetchuserdetails} scrollToSection={scrollToSection}
                extra_data={{
                   "kyc_linked_data": kyc_linked_data
                }}
             />
                </Box>
                {/* <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: "20px", }}>
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                            <Box>
                                <TranslateText sx={formStyle.label} color="grey.700">Contact Number</TranslateText>
                                <Box sx={{ display: "flex", alignItems: "center" }}>

                                    {/* <TranslateText sx={{}} color="">+91</TranslateText> 
                                    <InputBase value="+91" disabled sx={{ border: "1px solid", borderColor: "grey.400", width: "60px", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" }}> </InputBase>
                                    <InputBase sx={formStyle.inputBase} value={mobile} onChange={(e) => dispatch(updateUserName(e.target.value))} />
                                </Box>
                                {/* {errors.userName && <TranslateText sx={{color:"error.light"}}>{errors.userName}</TranslateText>} 
                            </Box>

                            <Button sx={formStyle.button} variant="contained" onClick={updateMobile}>Update</Button>
                        </Box>

                    </Box> */}
                


                {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                    
                </Box> */}
                
            </Box>
            <Modal open={verification} sx={{}}>
            <Box sx={{ width: isMobile ? "calc(100% - 64px)" : "380px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "32px", border: "none",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center" }}>
                <Box sx={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                <TranslateText sx={{fontWeight:"600",color:"primary.light",lineHeight:"24px"}}>Verify Your Mobile Number</TranslateText>
                <CloseIcon onClick={()=>setVerification(false) } sx={{cursor:"pointer"}} />
                </Box>
                {
                    verify=="mobile"?
               
                <TranslateText sx={{fontSize:"14px",lineHeight:"20px"}}>   {whatsapp || isWhatsappNotificationEnabled ?"A verification code has been sent to your changed Whatsapp number":"A verification code has been sent to your changed Mobile no"} {maskedmobileno}</TranslateText>:
                <TranslateText sx={{fontSize:"14px",lineHeight:"20px"}}>A verification code has been sent to your changed Email {maskedEmail}</TranslateText> }
                {verify !== "mobile" && verify!== "email" &&<TranslateText sx={{fontSize:"14px",lineHeight:"20px",marginTop:"16px"}}>Please enter the code below to add your bank account.</TranslateText>}
                <MuiOtpInput gap={isMobile?.1:1} sx={{marginTop:"16px"}} value={otp} length={6} onKeyDown={(e)=>handleKeyPress(e)} onChange={(value)=>setOtp(value)}/>
               {/* {otpNotmatch  ?<TranslateText sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px"}} color="error">Invalid code. Please enter the valid code.</TranslateText>: !time==0&&<TranslateText sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px",textAlign:"start",width:"100%",}} color="error">Resend Code ({formatTime(time)} remaining)</TranslateText>} */}
                    <Box sx={{margin:"4px 0px",alignSelf:"flex-start"}}>
                    {otpNotmatch  ?<TranslateText sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px"}} color="error">Invalid code. Please enter the valid code.</TranslateText>: !time==0&&<TranslateText sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px",textAlign:"start",width:"100%",}} color="error">Resend Code ({formatTime(time)} remaining)</TranslateText>}

                    </Box>
                
               <Box sx={{ display:"flex", justifyContent:"space-between",width:"100%",alignItems:"center",flexDirection:{xs:"column",sm:"row"}}}>
                        <Button variant="contained" sx={{bgcolor:"primary.light",color:"primary.contrastText",height:"48px",padding:"8px 12px",textTransform: "none"}} onClick={handleComplete}><TranslateText sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px"}}>{isloading? <CircularProgress color="inherit" size={30}/> : <TranslateText>Submit</TranslateText>}</TranslateText></Button>
                        <Box disabled={!resend || time!==0 ?true:false} sx={{bgcolor:"transparent",border:"none",outline:"none",textTransform:"none"}} onClick={(e)=>handleResendOtp(e)}><TranslateText sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px",}} color={resend&& time==0 ?"primary":"#BFC7D4"}>Resend OTP</TranslateText></Box>
            </Box>
               
            </Box>
            </Modal>
            <OtpSignPin otpModal={otpIdentityModal}
        
                  setOtpmodal={setOtpIdentityModal}
                  aadhaar_number={identity_number?.replace(/\s/g, "")}
                  mobileNumber={mobile}
                  identityLabel={
                    selectedCountry?.value === "IN"
                      ? "Aadhaar Number"
                      : "Passport Number"
                  }
                  identityNumber={identity_number}
            />
        </Box>
      
           
    )

}
export default Profile