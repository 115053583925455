import { Box, Paper, Stack, Typography, InputBase,Button } from "@mui/material"
import { useMediaQuery } from "react-responsive"
import { TranslateText } from "../utils/googletranslator";
import { formStyle } from "../modules/profile";
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
import { useSelector } from "react-redux";
import CustomAxios from "../utils/CustomAxios";
import { useDispatch } from "react-redux";
import { updateSnackBarMessage,updateSnackBarSeverity,updateSnackBarOpen } from "../redux/snackbar";
import { updateIsProfileReloaded,updateMemberResponse } from "../redux/user_reducer";


const ReferralUpdate = () => {
    const isLaptop = useMediaQuery({ minWidth: 900 });
    const [isloading,setIsloading]=useState(false)
    const [referral,setReferral]=useState()
    const [introducer,setIntroducer]=useState()
    const id = useSelector(state=>state.user.id)
    const member=useSelector(state=>state.user.member)
    const [errors,setErrors]=useState({})
    const dispatch=useDispatch()

    const updateReferral=()=>{
        if (!isloading){
        setIsloading(true)
        const payload={
          referral:referral,
          introducer:introducer,
          id:id
        }
        CustomAxios.post ("update-eferral/",payload).then(({data})=>{
            if(data.message=="Successfully Updated!"){
                // console.log(data.user)
      
                dispatch(updateMemberResponse(data.user))

                dispatch(updateSnackBarMessage("Sponsor Updated Successfully"))
            dispatch(updateSnackBarSeverity("success"))
          setIsloading(false)
          dispatch(updateSnackBarOpen(true))
          dispatch(updateIsProfileReloaded(true))
        //   dispatch(updateCartSideBar(false))
        window.location.reload();
            }
            else{
                setErrors({referralCode:data.ref_message,introducerCode:data.intro_message})
                setIsloading(false)
            }
        }
        )
        }
      }
    return <Box id="kyc-details" component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: { xs: "20px", md: "24px" }, display: "flex", flexDirection: "column", gap: "16px", bgcolor: "#ECFEFF", border: "1px solid #67E8F9" }}>

        <Stack sx={{ gap: "4px" }}>
            <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Stack direction="row" sx={{ gap: "10px", alignItems: "center" }}>
                    <TranslateText sx={{ fontFamily: "Poppins,Roboto", fontWeight: 500, lineHeight: "24px", fontSize: "16px", letterSpacing: "0.15px", color: "#000000" }}>Unlock member benefits</TranslateText>
                    {/* <Box component="img" src={security_png} sx={{ width: "20px", height: "20px" }} /> */}
                </Stack>
                {/* <EditOutlined sx={{ color: iskyceditable ? "grey.700" : "primary.light", cursor: iskyceditable ? "not-allowed" : "pointer" }} onClick={() => { !kyc_details.mobileNo && setOtpmodal({ value: true }); kyc_details.mobileNo && setIskyceditable(true) }} /> */}
            </Stack>
            <TranslateText sx={{ fontFamily: "Poppins,Roboto", fontWeight: 400, fontSize: "14px", lineHeight: "20px", letterSpacing: "0.25px", color: "#000000", width: { xs: "100%", md: "auto" } }}>Product savings and income potential. Start your journey today and thrive with our support. </TranslateText>
        </Stack>
        <Stack sx={{ width: "100%", gap: "16px" }}>


        <Stack sx={{ gap: "8px" }}>
                <Box sx={{display:"flex",gap:"24px",flexDirection:{xs:"column",sm:"row"}}}>
                    <Box>
                        <TranslateText sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "24px", color: "#1E293B" }}>sponsor Username / Referral code <span component="span" sx={{ color: "#EF4444" }}>*</span></TranslateText>
                        
                        <InputBase sx={formStyle.inputBase} value={referral}  onChange={(e)=>setReferral(e.target.value.replace(" ", ''))}  placeholder="Enter Sponsor Username / referral code" />
                        <TranslateText sx={{ fontWeight: 400, fontSize: "12px", lineHeight: "16px", margin: "5px 0px" }} color="error">{errors.referralCode}</TranslateText>
                    </Box>
                    <Box>
                    <TranslateText sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "24px", color: "#1E293B" }}>Introducer Username / Referral code <span component="span" sx={{ color: "#EF4444" }}>*</span></TranslateText>
                        <InputBase sx={formStyle.inputBase} value={introducer} onChange={(e)=>setIntroducer(e.target.value.replace(" ", ''))}  placeholder="Enter Introducer Username / referral code" />
                        <TranslateText sx={{ fontWeight: 400, fontSize: "12px", lineHeight: "16px", margin: "5px 0px" }} color="error">{errors.introducerCode}</TranslateText>
                    </Box>
                </Box>
                {/* {kyc_errors?.mobileNo && <TranslateText color="error">{kyc_errors.mobileNo}</TranslateText>} */}
                <TranslateText sx={{ fontWeight: 400, fontSize: "12px", lineHeight: "16px", color: "#1E293B" }}>Once submitted, your membership application is final and cannot be reverted or edited.</TranslateText>
            </Stack>
             <Stack sx={{ gap: "16px", display: "flex", justifyContent: "flex-start", flexDirection: { xs: "column", sm: "row" } }}>
            
                <Button disabled={!referral||!introducer||isloading} variant="contained" sx={{ bgcolor: "primary.light", color: "primary.contrastText", height: "48px", padding:"8px 12px", textTransform: "none"}} onClick={updateReferral} >{isloading ? <CircularProgress color="inherit" size={30} /> : <TranslateText sx={{color:"#fff"}} >Submit</TranslateText>}</Button>
                {/* <Button type="reset" variant="outlined" sx={{ ...formStyle.button, bgcolor: "unset", "&:hover": { bgcolor: "unset" }, color: "FFFFFF" }} ><TranslateText>Cancel</TranslateText></Button> */}
                
            </Stack>
            

        </Stack>

    </Box>
}

export default ReferralUpdate
