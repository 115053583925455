import { Box, Button, InputBase, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import {useState} from 'react'
import CustomAxios from "../utils/CustomAxios";
import newsletterBackground from "./landing_assets/mayi_newsletter.jpg"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { landingPage_styles } from "../landing_page/landing_page";
import { TranslateText } from "../utils/googletranslator";
import { useSelector } from "react-redux";
export function NewsLetter() {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ maxWidth: 900 });
    const [email,setEmail]=useState("")
    const [errors,setErrors]=useState({})
    const language= useSelector(state=>state.user.language)
    const handleContact=()=>{
        
        const formData = {
            email: email,
           
        };
        const validate=formValidation(formData)

        if(validate){
           CustomAxios.post("/contact_us/",formData).then(res=>{
            if (res.data.message=="Successfully sent message"){
                setEmail("")

            }
               
            })

        }
    }
    const formValidation=(formData)=>{
        let valid = true;
        const newErrors = {};
        // console.log(formData)
        

        const emailPattern = /^\S+@\S+\.\S+$/;
        if (!formData.email.trim()) {
            newErrors.email = 'Enter your email address.​';
            valid = false;
        } else if (!emailPattern.test(formData.email)) {
            newErrors.email = 'Please enter valid email address';
            valid = false;
        }
        setErrors(newErrors)
        return valid

    }


    return (<Box sx={{ width: "100%", display: "flex", justifyContent: "center",alignItems:"center",zIndex:2}}>
        <Box component={'img'} src={newsletterBackground}  sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",height: {xs: language=='en' ? "252px":"280px", md:"216px"},objectFit:"cover", }}/>
                <Box sx={{position:"absolute",width:"100%",bgcolor:"#005225",opacity:"0.75",minHeight: {xs:"252px", md:"216px"},height: {xs: language=='en' ? "252px":"280px", md:"216px"}}}></Box>

        <Box sx={{padding: {xs:"24px 16px", md:"64px 120px"}, display: "flex", gap: {xs:"24px",md:"20px"}, alignItems: "center",position:"absolute",width: "100%", flexDirection: isMobile || isLaptop? "column" : "row" ,justifyContent:"space-between",zIndex:5}}>
            <Box sx={{ width: {sm:"100%",md:"50%"},display: "flex", alignItems:"center"}}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <TranslateText sx={{ color: "primary.contrastText", fontSize: isMobile ? "28px" : "32px", fontWeight: "600", lineHeight: isMobile ? "33.6px" : "44px", fontFamily: "Poppins", marginBottom: "10px", textAlign: "left" }}>Subscribe To Our Newsletter</TranslateText>
                    <TranslateText sx={{ color: "primary.contrastText", fontSize: isMobile ? "16px" : "18px", fontWeight: "400", lineHeight: isMobile ? "24px" : "44px", fontFamily: "Poppins", marginBottom: "10px", textAlign: "left" }}>Get all the latest information on Events, Sales and Offers.</TranslateText>
                </Box>
            </Box>
            <Box sx={{ width:{sm:"100%",md:"50%"} }}>
                <Box sx={{ display: "flex", flexDirection: "row"}}>
                <InputBase value={email} onChange={(e)=>setEmail(e.target.value)} sx={{width: "100%",padding:"10px 12px",bgcolor:"#006D33" ,color:"#FFFFFF",borderColor:"1px solid grey", fontFamily: "Poppins",}} placeholder="Enter your email id"></InputBase>
                <Box sx={{display:"flex",flexDirection:"column",gap:"5px"}}>
                        <Button variant="contained" sx={{width:isMobile?"100%":"220px",height:"54px",padding: "24px" ,borderRadius:"0px",bgcolor:"primary.contrastText",textTransform:"none", fontFamily: "Poppins",overflow:"hidden","&:hover":{bgcolor:"primary.contrastText"},...landingPage_styles.buttonAnimation}} onClick={handleContact}> <TranslateText sx={{ fontWeight:"500",fontSize:"20px",color:"#000",display:"flex",alignItems:"center",justifyContent:"center"}} >{!isMobile&& "Submit"}</TranslateText> <ArrowForwardIcon sx={{ fontWeight:"500",fontSize:"20px",color:"#000",marginLeft:"10px"}} /></Button>
                    </Box>
                </Box>
                {errors.email && <Typography sx={{ fontWeight: 600, fontSize: "14px", color: "error.light", marginBottom: "-20px",  fontFamily: "Poppins",}}>{errors.email}</Typography>}
            </Box>

        </Box>
    </Box>)
}