
import { Box, Button, Divider, InputBase, InputLabel, Paper, Typography , InputAdornment, IconButton,RadioGroup,Tooltip,FormControlLabel,Radio } from "@mui/material"
import { alpha, styled } from '@mui/material/styles'
import { updateEmail, updateMobile, updatePassword, updateFirstName, updateLastName, updateRole,updateId, updateProfile, updateMemberResponse, updateOtpCheck, updateUserName,updateWhatsapp,updateMembership,updateConrtol, updateFormType, updateSigninForm ,updateOtpVerifyPopup} from "../redux/user_reducer"
import { updateSnackBarOpen,updateSnackBarSeverity,updateSnackBarMessage } from "../redux/snackbar"
import mayi_logo from "../assets/mayi_logo.png"
import {inputStyles} from "./signup_form"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMediaQuery } from "react-responsive"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { useState,useRef } from "react"
import CustomAxios from '../utils/CustomAxios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {jwtDecode} from 'jwt-decode';
import loginImage from "../assets/loginHero.png"
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import { GoogleLogin } from '@react-oauth/google'; // Import GoogleLogin component
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import MessageBar from "../modules/messageBar"
import { TranslateText } from "../utils/googletranslator"
import { updateOpen } from "../redux/congrats"
// import jwt from "jsonwebtoken"
const LoginForm = () => {
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false);
const visibility=()=>{
    // // console.log("password visible")
}
const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
};
const isMobile = useMediaQuery({ maxWidth: 600 });
const email = useSelector(state => state.user.email )
const password = useSelector(state => state.user.password )
const userName=useSelector(state=>state.user.user_name)
const [response,setResponse]=useState("")
const [isloading, setIsloading] = useState(false)
const [errors,setErrors]=useState({})
const { executeRecaptcha } = useGoogleReCaptcha();
const navigate=useNavigate()
const [openMember, setOpenMember] = useState(false);
const [openNonMember, setOpenNonMember] = useState(false);
const member=useSelector((state)=>state.user.member)
const tooltipRef = useRef(null);
const clientId = "521447616048-relr5c80sac2rudgmfub8kantovfc5pe.apps.googleusercontent.com"; // Replace with your actual client ID
const [platform,setPlatform]=useState("web")
const accessControl=useSelector(state=>state.user.accessControl)
const location=useLocation()
let [pages,setPages]=useState([])
    let [module,setModule]=useState([])
const verifyCaptcha = ()=>{
    if (!executeRecaptcha) {
        return;
    }
  
    executeRecaptcha('login_page').then((token)=>{
        handleSummit(token)
        // console.log("////////////////token////////////",token)
    })
    // return token
}

useEffect(()=>{
setErrors({})
setResponse("")
},[member])

const memberTooltip = () => {
    setOpenMember(true);
  };
  const memberTooltipClose = () => {
    setOpenMember(false);
  };
  const nonMemberTooltip = () => {
    setOpenNonMember(true);
  };
  const nonMemberTooltipClose = () => {
    setOpenNonMember(false);
  };
const handleSummit = () => {
    const formData = {
        email_or_username: userName,
        password: password
    };
    const isValid = validateForm(formData);
    if (isValid) {
        login();
    }
};
const authtoken=localStorage.getItem("authtoken")
useEffect(()=>{
    dispatch(updateOtpVerifyPopup(false))
    if (authtoken){
        const decode=jwtDecode(authtoken)
        if (decode.role=='admin'){
       
    const access=accessControl.filter(item=>item.role==decode.control)[0]
    // console.log("????????????????????access",authToken)
    // decode.control
    // console.log(access.access)
    let module=[]
    let pages=[]
    access.access.map(item=>{
        // console.log(item,typeof(item))
        if(typeof(item)=='object' ){
           module.push(Object.keys(item)[0])
           pages=[...pages,...Object.values(item)[0]]
        }
        else if(typeof(item)=='string' ){
            pages.push(item)

        }
    })
    // console.log("??????????????? params",location,id)
    setPages(pages)
    setModule(module)
    navigate(pages[0])
}
else{
    if (localStorage.getItem("redirect")){
        navigate(localStorage.getItem("redirect"))
    }
    else{
        navigate("/")
    }
}
      
    }

},[])

const login=async()=>{
    setIsloading(true)
    await CustomAxios.post(`login/`,
        {
            email_or_username:userName,
           
            password:password,
            member:member,
            cart:JSON.parse(localStorage.getItem('cart'))
            // recaptcha_token: recap_token
        }
    ).then(({data})=>{
        // const decoded=jwt.decode()
        // console.log(data)
        setIsloading(false)
        if(data.message=='Successfully identified user'){
            dispatch(updatePassword(""))
            dispatch(updateUserName(""))
            const decode=jwtDecode(data.access_token)
            localStorage.setItem("address",JSON.stringify(data.add))
            localStorage.setItem('authtoken', data.access_token);
            localStorage.setItem('refreshToken', data.refresh_token);
            // console.log(data.add)
            // console.log(decode)
            dispatch(updateMemberResponse(data))
            dispatch(updateFirstName(decode.first_name))
            dispatch(updateLastName(decode.last_name))
            dispatch(updateEmail(decode.email))
            dispatch(updateRole(decode.role))
            dispatch(updateConrtol(decode.control))
            dispatch(updateId(decode.id))
            dispatch(updateMobile(data.user.mobileno))
            // dispatch(updateWhatsapp(data.user.whatsapp))
            if (data.att.length>0){
                dispatch(updateProfile(data.att[0].original))
            }
            dispatch(updateEmail(decode.email))

            // const decode=jwtDecode(authtoken)
    if (decode.role=='admin'){
           
        const access=accessControl.filter(item=>item.role==decode.control)[0]
        // console.log("????????????????????access",authToken)
        // decode.control
        // console.log(access.access)
        let module=[]
        let pages=[]
        access.access.map(item=>{
            console.log(item,typeof(item))
            if(typeof(item)=='object' ){
               module.push(Object.keys(item)[0])
               pages=[...pages,...Object.values(item)[0]]
            }
            else if(typeof(item)=='string' ){
                pages.push(item)
    
            }
        })
        // console.log("??????????????? params",location,id)
        setPages(pages)
        setModule(module)
        navigate(pages[0])
    }
    else{
        if (localStorage.getItem("redirect"  ) && location.pathname==='/login'){
            navigate(localStorage.getItem("redirect"))
        }
        else if(location.pathname==='/login'){
            navigate("/")
        }
        else{
            // dispatch(updateOpen(true))
            dispatch(updateSigninForm(false))
        }
       
    }
        }
        else if (data.message == "Account is not verified"){
            dispatch(updateOtpCheck("login"))
            dispatch(updateEmail(data.data.email))
            dispatch(updateMobile(data.data.mobileno))
            dispatch(updateId(data.data.id))
            dispatch(updateWhatsapp(data.data.whatsapp))
            if (location.pathname=="/login"){
                navigate("/otp-verification");}
                else{
                    // console.log("??????????????????????????new popup",)
                    dispatch(updateSigninForm(false))
                    dispatch(updateOtpVerifyPopup(true))
                    
                }
        }
        else{
            dispatch(updateOtpCheck('login'));
            setResponse(data.message )
        }           
        
    }).catch(error=>{
        setIsloading(false)
        // console.log(error)
    })



}
const validateForm = (formData) => {
    let valid = true;
    const newErrors = {};

    if (!formData.email_or_username.trim()) {
        newErrors.email_or_username = 'Please enter an username.';
        valid = false;
    }

    if (!formData.password.trim()) {
        newErrors.password = 'Enter your password.';
        valid = false;
    }

    setErrors(newErrors);
    return valid;
};
  const homePage=()=>{
    navigate("/")
}

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSummit(); 
    }
  };
  useEffect(()=>{
    dispatch(updateEmail(""))
    dispatch(updatePassword(""))
  },[])
  useEffect(() => {
    // setReferralCode(queryParameters.get('code'));
    // if (queryParameters.get('code')){
    //   setPlatform('referred')
    // }
    // else if(queryParameters.get('signup_via')){
    //   localStorage.setItem('signup_via',queryParameters.get('signup_via'))
    //   navigate('/products')
      
    //   // setPlatform(queryParameters.get('signup_via'))
    // }
    if(localStorage.getItem('signup_via')){
      // console.log(localStorage.getItem('signup_via'))
      setPlatform(localStorage.getItem('signup_via'))
    }
    
  }, []);
//   const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });

    const handleGoogleSuccess = async (credentialResponse) => {
        const details = jwtDecode(credentialResponse.credential);
        // console.log(details);
        const { given_name, family_name, email } = details;
        // console.log(given_name, family_name, email);
    
        const payload = {
            email: email,
            first_name: given_name,
            last_name:  "family_name" in details?family_name:"",
            referralcode: "",
            is_active: true,
            registration_type: "google",
            platform:platform,
            cart:localStorage.getItem("cart")
        };
    
        // console.log(given_name);
        // console.log(family_name);
        // console.log(email);
    
        setIsloading(true);
        try {
            const response = await CustomAxios.post(`signup/`, payload);
            setIsloading(false);
    
            if (response.data.message === "Registration Successful!") {
                const decode = jwtDecode(response.data.access_token);
                localStorage.setItem("address", JSON.stringify(response.data.add));
                localStorage.setItem('authtoken', response.data.access_token);
                localStorage.setItem('refreshToken', response.data.refresh_token);
                // console.log(response.data.add);
                // console.log(decode);
                dispatch(updateMemberResponse(response.data));
                dispatch(updateFirstName(decode.first_name));
                dispatch(updateLastName(decode.last_name));
                dispatch(updateEmail(decode.email));
                dispatch(updateRole(decode.role));
                dispatch(updateConrtol(decode.control))
                dispatch(updateId(decode.id));
                if (response.data.att.length > 0) {
                    dispatch(updateProfile(response.data.att[0].original));
                }
                dispatch(updateEmail(decode.email));
                if (localStorage.getItem("redirect"  ) && location.pathname==='/login'){
                    navigate(localStorage.getItem("redirect"))
                }
                else if(location.pathname==='/login'){
                    navigate("/")
                }
                else{
                    // dispatch(updateOpen(true))
                    dispatch(updateSigninForm(false))
                }
            } else {
                setErrors({ passworderror: response.data.message });
                console.log(response.data)
                // setErrors({googleSignin:response.data.error})
                if(response.data.error){
                    dispatch(updateSnackBarMessage(response.data.error))
                    dispatch(updateSnackBarSeverity("error"))
                  
                  dispatch(updateSnackBarOpen(true))
                }
            }
        } catch (error) {
            setIsloading(false);
            // console.error(error);
        }
    };
    



    // const handleGoogleSuccess = async (credentialResponse) => {
    //     const details = jwtDecode(credentialResponse.credential);
    //     // console.log(details);
    //     const { given_name, family_name, email } = details;
    //     // console.log(given_name, family_name, email);
    
        
    //         const payload = {
    //             email: email,
    //             first_name: given_name,
    //             last_name: family_name,
    //             // password: '', 
    //             referralcode: "",
    //             is_active: true,
    //             registration_type: "google"
    //         };
    
    //         // console.log(given_name);
    //         // console.log(family_name);
    //         // console.log(email);
    
    //         setIsloading(true);
    //         try {
    //             const response = await CustomAxios.post(`signup/`, payload);
    //             setIsloading(false);
    //             if (response.data.message === "Registration Successful!") {
    //                 // console.log("hii")
    //                 navigate("/");
    //             } else {
    //                 setErrors({ passworderror: response.data.message });
    //             }
    //         } catch (error) {
    //             setIsloading(false);
    //             console.error(error);
    //         }
                
    // };

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <MessageBar/>
        <Box component={!isMobile&&Paper} sx={{ left: "50%", top: "50%", position:(location.pathname!=='/checkout'||isLaptop)&& "absolute", transform:(location.pathname!=='/checkout'||isLaptop)&&'translate(-50%, -50%)',display:"flex",alignItems:{xs:"center",sm:"initial"},flexDirection: {sm:"row", xs:"column"},width: { md: "440px", xs: "100%",sm:"440px" }, maxWidth:{xs:"100%" ,md:"600px",sm:"600px"},height:{xs:"100%",sm:"auto",md:"auto"},minHeight:{xs:location.pathname!=="/checkout"&&!isMobile&&"100vh",md:"auto",sm:"auto"}}} >
        {location.pathname!=="/checkout"&&<Box src={loginImage} sx={{display:{md:"none",sm:"none",xs:"block"},width: {md:"50%", xs:"100%",sm:"50%"},height:{xs:"128px",sm:"100vh",md:"80vh"}, overflowY:"scroll",backgroundSize:"auto",objectFit:"cover",minHeight:{xs:"128px"}}} component="img" />}
            
            <Box sx={{width:"100%",padding:"32px",display:"flex",flexDirection:"column",alignItems:"center"}}>

           
            {location.pathname!=="/checkout"&& <Box src={mayi_logo} component='img' sx={inputStyles.logo} onClick={homePage}/>}
                <TranslateText sx={inputStyles.subHeading} color="grey.900"> Welcome back!</TranslateText>
                <TranslateText sx={inputStyles.constent} color="grey.900"> Sign in to access your account and explore.</TranslateText>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px",width:"100%" }}>
            {/* <Box sx={{alignSelf:"start"}}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={member}
                            onChange={(e)=>dispatch(updateMembership(e.target.value))}
                            >
                                 <Tooltip PopperProps={{  sx: {"& .MuiTooltip-tooltip": { backgroundColor: "#001F38",  color: "#fff",  }, "& .MuiTooltip-arrow": { color: "#001F38",   }, },   }} title={  <Box ref={tooltipRef}  sx={{ backgroundColor: "#001F38", color: "white",  fontSize: "12px", textAlign: "center", padding: "8px", borderRadius: "4px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", maxWidth: "240px",}} ><TranslateText sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} >MAYI IQ Member:</TranslateText>  <TranslateText sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} > Unlock earning opportunities and special offers. Need to complete KYC verification.    </TranslateText>   </Box>}arrow open={openMember} onOpen={memberTooltip} onClose={memberTooltipClose}  placement="bottom" >
                            <FormControlLabel sx={{height:"20px",}} value="member" control={<Radio />} label={<TranslateText>Member</TranslateText>} />
                            </Tooltip>
                            <Tooltip PopperProps={{  sx: {"& .MuiTooltip-tooltip": { backgroundColor: "#001F38",  color: "#fff",  }, "& .MuiTooltip-arrow": { color: "#001F38",   }, },   }} title={  <Box ref={tooltipRef}  sx={{ backgroundColor: "#001F38", color: "white",  fontSize: "12px", textAlign: "center", padding: "8px", borderRadius: "4px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", maxWidth: "240px",}} ><TranslateText sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} >MAYI IQ Basic user:</TranslateText>  <TranslateText sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} >This option is for casual users who want to explore the platform. No KYC is required.    </TranslateText>   </Box>}arrow open={openNonMember} onOpen={nonMemberTooltip} onClose={nonMemberTooltipClose}  placement="bottom" >
                            <FormControlLabel sx={{height:"20px",}} value="non_member" control={<Radio />} label={<TranslateText>Non Member</TranslateText> } />
                            </Tooltip>
                            
                            </RadioGroup>  
                        </Box> */}
                
                <Box>
                <TranslateText sx={inputStyles.label} color="grey.700">Username <span style={{ color: "red" }}>*</span></TranslateText>
                            <InputBase
                                sx={inputStyles.inputBase}
                                value={userName}
                                placeholder="Enter Username"
                                onKeyDown={handleKeyPress}
                                onChange={(e) => dispatch(updateUserName(e.target.value.replace(" ", "")))}
                            />
                            {errors.email_or_username && <TranslateText sx={{ color: "error.light" }}>{errors.email_or_username}</TranslateText>}

                </Box>
                <Box>
                    <Box sx={{display:"flex",justifyContent:"space-between"}}>
                    <TranslateText sx={inputStyles.label} color="grey.700">Password <span style={{color:"red"}}>*</span></TranslateText>
                   
                    </Box>
                    <InputBase placeholder="Enter password" type={showPassword ? 'text' : 'password'} sx={inputStyles.inputBase} onKeyDown={(e)=>handleKeyPress(e)} onChange={(e) => dispatch(updatePassword(e.target.value))} endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    } />
                    {errors.password && <TranslateText sx={{color:"error.light"}}>{errors.password}</TranslateText>}
                    
                    <TranslateText sx={{color:"error.light"}}>{response}</TranslateText>

                </Box>
                <Box sx={{width:"100%",display:"flex",justifyContent:"center"}}>
                <Link to="/forgot-password" style={{textDecoration:"none"}}><TranslateText sx={{fontSize:"14px",textDecoration:"none",textDecorationLine:"underline",textAlign:"center"}} color="primary">Forgot password?</TranslateText></Link></Box>
                
                <Button variant="contained" sx={inputStyles.button} onClick={handleSummit}>{isloading? <CircularProgress color="inherit" size={30}/> : <TranslateText>Sign in</TranslateText>}</Button>             
                <Box sx={{display:'flex',justifyContent:"center"}}>
                <TranslateText sx={{textAlign:"center",fontSize:"14px"}} > Don't have any account?&nbsp;</TranslateText><Link onClick={()=>location.pathname!=='/login'&&dispatch(updateFormType('signup') )} to={ location.pathname=='/login'&& "/signup"} style={{textDecoration:"none",fontSize:"14px"}}><TranslateText sx={{textDecoration:"none",textDecorationLine:"underline",fontSize:"14px"}} color="primary">Sign up</TranslateText></Link>

                </Box>
                
                <Divider sx={{fontSize:"14px"}} ><TranslateText>Or</TranslateText></Divider>
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection:"column",alignItems:"center" }}>
                                <GoogleLogin
                                    onSuccess={handleGoogleSuccess}
                                    // onError={handleGoogleError}
                                />
                            </Box>
                        
                
              
                {/* <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                            <GoogleLogin
                                onSuccess={handleGoogleSuccess}
                                // onError={handleGoogleError}
                            />
                        </Box> */}
                {/* </Box> */}
            </Box>
            </Box>
        </Box>
        </GoogleOAuthProvider>
    )


}

export default LoginForm