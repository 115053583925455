import { useSelector } from "react-redux"


export const CalculateProductPrice = (props)=>{
    const authtoken=props.authtoken || localStorage.getItem('authtoken')
    const member=props.member
    const item = props.item
    const strike=props.lineStrike
    // console.log("-------",item.product)
    // if(authtoken && !strike){
    if(!strike){

        return (parseFloat(item.product?.selling_price) + (parseFloat(item.product?.selling_price) * (parseFloat(item.product?.tax)/100)  )).toFixed(2)
    }
    return (parseFloat(item.product?.price) + (item.product?.price * (item.product?.tax/100) )) .toFixed(2)
}