import { Box, Button, Dialog, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import CustomAxios from "../utils/CustomAxios";
import CircularProgress from "@mui/material/CircularProgress";
import {
  updateSnackBarMessage,
  updateSnackBarOpen,
  updateSnackBarSeverity,
} from "../redux/snackbar";
import MessageBar from "../modules/messageBar";
import { updateisVerifyTick ,updateRefID, } from "../redux/user_reducer";
import { TranslateText } from "../utils/googletranslator";
export default function OtpSignPin({
  otpModal = { value: false, type: "" },
  setOtpmodal,
  // ref_id,
  aadhaar_number,
  mobileNumber,
  identityNumber,
}) {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resend, setResend] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60); // Timer starts from 60 seconds
  const dispatch = useDispatch();
  const id = useSelector((state) => state.user.id);
  const ref_id=useSelector(state=>state.user.ref_id)


  useEffect(() => {
     dispatch(updateSnackBarMessage(""));
     dispatch(updateSnackBarOpen(false));
  }, [])
  
  const handleClose = () => {
    setOtpmodal({ value: false, type: "" });
  };

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleComplete = async () => {
    setIsLoading(true);
    try {
      const response = await CustomAxios.post(`verifyotp/`, {
        ref_id,
        otp,
        verifyOtpFor: otpModal.type,
      });

      if (response.data.status === "SUCCESS") {
        dispatch(updateisVerifyTick(true));
        // dispatch(updateVerifyIdentity(true))
        dispatch(updateSnackBarMessage(response.data.message));
        dispatch(updateSnackBarSeverity("success"));
        dispatch(updateSnackBarOpen(true));
        handleClose(); // Close the OTP modal
      } else {
        dispatch(
          updateSnackBarMessage(response.data.error || "Failed to verify OTP.")
        );
        dispatch(updateSnackBarSeverity("error"));
        dispatch(updateSnackBarOpen(true));
      }
    } catch (error) {
      dispatch(updateSnackBarMessage("Error verifying OTP."));
      dispatch(updateSnackBarSeverity("error"));
      dispatch(updateSnackBarOpen(true));
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOtp = async () => {
    if (resend && timeLeft === 0) {
      try {
        setIsLoading(true);
        const response = await CustomAxios.post("verifykyc/", {
          ref_id,
          verifyOtpFor: otpModal.type,
          aadhaar_number,
          mobileNumber,
        });

        if (response.data.status === "SUCCESS") {
          dispatch(updateRefID (response.data.ref_id));
          dispatch(updateSnackBarMessage("OTP resent successfully."));
          dispatch(updateSnackBarSeverity("success"));
          dispatch(updateSnackBarOpen(true));
          setTimeLeft(60);
          setResend(false);
        } else {
          dispatch(
            updateSnackBarMessage(
              response.data.error || "Failed to resend OTP."
            )
          );
          dispatch(updateSnackBarSeverity("error"));
          dispatch(updateSnackBarOpen(true));
        }
      } catch (error) {
        dispatch(updateSnackBarMessage(error));
        dispatch(updateSnackBarSeverity("error"));
        dispatch(updateSnackBarOpen(true));
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (otpModal.value) {
      setOtp("");
      setTimeLeft(60); // Reset timer when modal opens
      setResend(false);
    }
  }, [otpModal]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setResend(true);
    }
  }, [timeLeft]);

  return (
    <>
      <MessageBar />
      <Box>
        
        <Dialog
          open={otpModal.value}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Box
            sx={{
              maxWidth: "383px",
              width: "100%",
              padding: { xs: "16px", xl : "24px"},
              gap: "16px",
              minHeight: "320px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <TranslateText
                sx={{ fontWeight: 600, fontSize: {xs: "14px", xl :"16px" }}}
                color="primary"
              >
                Verify Mobile Number
              </TranslateText>
              <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
            </Box>
            <TranslateText
              sx={{ fontWeight: 400, fontSize:  {xs: "12px", xl : "14px"} }}
              color="grey.900"
            >
              A verification code has been sent to your Aadhaar Linked mobile
              number.
            </TranslateText>
            <MuiOtpInput
              gap={1}
              value={otp}
              length={6}
              onChange={handleChange}
            />
            <TranslateText
              sx={{ fontWeight: 400, fontSize: "12px" }}
              color="error"
            >
              Resend Code ({Math.floor(timeLeft / 60)}:
              {("0" + (timeLeft % 60)).slice(-2)} remaining)
            </TranslateText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // gap: "20px",
                alignItems: "center",
                flexDirection:{xs:"column",sm:"row"}
              }}
            >
              <Button
                disabled={otp.length < 6}
                variant="contained"
                sx={{
                  bgcolor: "primary.light",
                  color: "primary.contrastText",
                  // height: "48px",
                  padding:"8px 12px",
                  // width: "3rem",
                  textTransform: "none",
                }}
                onClick={handleComplete}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  <TranslateText>Submit</TranslateText>
                )}
              </Button>
              <Button
                disabled={!resend}
                onClick={handleResendOtp}
                sx={{
                  color: resend ? "primary.main" : "#BFC7D4",
                  textTransform: "none",
                }}
              >
                <TranslateText>Resend OTP</TranslateText>
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </>
  );
}
