import { Box, Button, Stack, Typography } from "@mui/material"
import { TranslateText } from "../utils/googletranslator"
import { ArrowForward, ArrowRight } from "@mui/icons-material"
import Slider from "react-slick"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import CustomAxios from "../utils/CustomAxios"
import { updateBPIntialState } from "../redux/bundleProduct"
import { useNavigate } from "react-router-dom"
import { Galleria } from "primereact/galleria"
import { motion } from "framer-motion";
import { landingPage_styles } from "./landing_page";


const BundlesSection = ()=>{
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const bundles = useSelector(state=>state.bundles.bundles)
    const language= useSelector(state=>state.user.language)

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900, 
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    speed: 500,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    infinite: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    speed: 500,
                }
            }
        ]
    };

    const getBundles = ()=>{
        
        CustomAxios.get('bundles/').then((response)=>{
            let res = response.data
            dispatch(updateBPIntialState({key:"bundles",value:res}))
        }).catch((err)=>{

        })
    }

    useEffect(()=>{
        getBundles()
    },[])

    return(
        <Box gap={'32px'} sx={{...landingPage_styles.whoWeAre.page_prop, zIndex: 2,bgcolor:"#FFC27B"}}>
            <Stack sx={{textAlign:"center"}}>
                <motion.div  viewport={{ once: true, amount: 0.8 }}  initial={{ opacity: 0, y: 100 }} whileInView={{ y: 0.5, opacity: 1 }} transition={{ easings: "ease-in-out", duration: .8 }}>
                    <TranslateText sx={{fontFamily:"Poppins",fontSize:"32px",fontWeight:600,lineHeight:"56px",wordBreak:'break-word'}} >Introducing Our</TranslateText>
                </motion.div>
                <motion.div  viewport={{ once: true, amount: 0.8 }}  initial={{ opacity: 0, y: 100 }} whileInView={{ y: 0.5, opacity: 1 }} transition={{ easings: "ease-in-out", duration: .8 }}>
                    <TranslateText sx={{fontFamily:"Poppins",fontSize:"48px",fontWeight:600,lineHeight:"56px",wordBreak:'break-word'}} >Expertly Crafted Health Bundles</TranslateText>
                </motion.div>
            </Stack>
            <TranslateText sx={{fontFamily:"Poppins",fontSize:"20px",fontWeight:400,lineHeight:"30px",textAlign:"center",width:"calc(100% - 120px)",wordBreak:'break-word'}}>
                Discover our curated health bundles, each tailored to target specific wellness challenges.From high cholesterol and heart issues to digestive concerns, our bundles support your overall health.Embrace a holistic approach with solutions for liver care, bone health, cognitive support, and more—elevate your health journey today
            </TranslateText>
            <Box className='scroll_product' sx={{ padding:"32px 0px",display:"flex",alignItems:"center",justifyContent:"center",gap:"10px",width: '100%',"&:hover":{overflow:"scroll"},overflow:'hidden','&:hover > div' :{   } }}>
                {bundles.map((bundle, index)=>{return bundle.status && <Box className='prod_scroll' sx={{minWidth: { xs: "216px"},'&:hover':{transform:"scale(1.1)"}  }}key={bundle.id}>
                   
                    <Box  sx={{ display: "flex", height: {xs:language=='en' ? "308px":"375px",md:language=='en' ? "308px":"350px"}, flexDirection: "column", gap: "20px", boxShadow: "4px 4px 20px 0px #0000001A", padding: { xs: "16px 20px", md: "16px 20px" }, alignItems: "center", minWidth: { xs: "216px"}, width: "216px", bgcolor: "white",cursor:"pointer"}} onClick={() => navigate(`bundle_details/${bundle.id}`)}>
                        <Box sx={{ maxWidth: { xs: "198px", md: "180px" }, minWidth: "127px" }} component="img" src={bundle.cover ? bundle.cover_details?.original : bundle.products?.[0]?.product?.cover_details?.original} alt={bundle.name}></Box>
                        <TranslateText sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "600", textAlign: "center", width: "100%",textWrap:"wrap",wordBreak:'break-word' }}>{bundle.name}</TranslateText>
                    </Box>
                   </Box>
                })}
            </Box>
            <motion.div  viewport={{ once: true, amount: 0.8 }}  initial={{ opacity: 0, y: 100 }} whileInView={{ y: 0.5, opacity: 1 }} transition={{ easings: "ease-in-out", duration: .8 }}>
                <Button variant="contained" onClick={() => navigate('/products')} sx={{ ...landingPage_styles.contactUs.button, ...landingPage_styles.buttonAnimation }}>
                    <TranslateText sx={landingPage_styles.contactUs.buttonText}>Explore Products </TranslateText><ArrowForward/>
                </Button>
            </motion.div>
            <style>
        {`
        //   .scroll_product {
        //     width: 300px;
        //     height: 200px;
        //     overflow-y: scroll;
        //   }

          .scroll_product::-webkit-scrollbar {
            display: none; /* Chrome, Safari */
          }

          .scroll_product {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none;    /* Firefox */
          }


            /* Scroll content */
            .bundle_scroll{
            display: inline-block;     /* Ensure content scrolls horizontally or vertically */
            white-space: nowrap;       /* Prevent content from wrapping */
            animation: scroll 10s linear infinite; /* 10 seconds for one scroll cycle */
            }

            /* Keyframes for vertical scrolling */
            @keyframes scroll {
            0% {
                transform: translateX(0); /* Start position */
            }
            100% {
                transform: translateX(-100%); /* End position (content scrolled up) */
            }
            }
            .scroll_product:hover>div{
             animation-play-state: paused;
                transform: 'scale(1.5)';
                animation:none;
            }
        `}
      </style>
        </Box>
    )
}

export default BundlesSection