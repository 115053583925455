import { Box } from "@mui/material"
import AdminSidebar from "../components/adminSideBar"
import Header1 from "../components/header1"
import AddBundles from "../components/addBundles"
import UpdateBundles from "../components/updateBundles"


const BundleProducts_ORL = ()=>{
    const urlId = window.location.pathname.split('/')[2]

    return <>
       
        <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 120px)" }}>
                <Header1 />
                <Box sx={{ display: "flex", paddingTop: "144px",gap:"24px",mx:"24px" , position:"relative"}}>
                    <AdminSidebar />
                    {
                        isNaN(urlId) === false?
                        <UpdateBundles/>
                        :
                        <AddBundles/>
                    }

                </Box>
            
           
            

        </Box>

    </>
}

export default BundleProducts_ORL