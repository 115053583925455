
import { Box } from "@mui/material"
import SignupForm from "../forms/signup_form"
import SignupStepperForm from "../forms/signup_stepper"
import background from "../assets/Background.jpg"
import { useMediaQuery } from "react-responsive"
import { useSelector } from "react-redux"
const Signup=()=>{
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const isTablet= useMediaQuery({ maxWidth: 900,minWidth:600 });
    const member=useSelector((state)=>state.user.member)
    // console.log("????????????????????????/",member)
    return( <Box sx={{width:"100%",bgcolor:{xs:"#fff",md:"black"},height:{xs:"100vh",md:"unset"},display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column'}}>
    {!isMobile&&<Box  sx={{height:"100vh",width:"100%"}} component='img' src={background} />} 
    {/* {member=='member'?
     <SignupStepperForm />:  */}
     <SignupForm/>
     {/* } */}
     </Box>
     )
}

export default Signup