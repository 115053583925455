
import { Box, Paper, Table, Typography, TableContainer, TableHead, TableRow, TableBody, TableCell, Divider, Button,Breadcrumbs,Link,Stack,InputBase,MenuItem,Select } from "@mui/material"
import Header1 from "../components/header1"
import AdminSidebar from "../components/adminSideBar"
import CustomAxios from "../utils/CustomAxios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateInventoryData, updateInventoryForm, updateInventoryHistory, updateSelectedInventoryProduct,updateInventoryFormData } from "../redux/admin"
import { useParams } from "react-router-dom"
import InventoryModal from "../components/inventoryModal"
import { Delete, DeleteOutline, Edit, EditNote, Filter, Search } from '@mui/icons-material';
import dayjs from "dayjs"
const Stockbatch = () => {
    const params = useParams()
    // console.log(params)
    const inventoryData = useSelector(state => state.admin.inventoryData)
    const selectedInventoryProduct = useSelector(state => state.admin.selectedInventoryProduct)
    const inventoryHistory=useSelector(state=>state.admin.inventoryHistory)
    const inventoryForm=useSelector(state=>state.admin.inventoryForm)
    const dispatch = useDispatch()
    const [expired,setExpired]=useState(0)
    const [available, setAvailable]=useState(0)
    const [filteredProducts,setFilteredProducts] = useState(null)
    const [Filters,setFilters] = useState({
        search:"",
        status:"",
        category:""
      })
    useEffect(() => {
        // console.log(inventoryData)
        if (inventoryData.length > 0) {
            // console.log(inventoryData)
            const selecetedData = inventoryData.filter(item => item.inventry_details?.id == params.id)
            // console.log(selecetedData)
            dispatch(updateSelectedInventoryProduct(selecetedData[0]))
        //    console.log( selecetedData[0])
        }
    }, [inventoryData, params])
    useEffect(()=>
        {

            CustomAxios.get(`inventory-history/${selectedInventoryProduct?.id}`).then(result=>{
                // console.log(result)
               dispatch(updateInventoryHistory(result.data))
            }).catch(e=>console.log(e))

    },[inventoryForm,selectedInventoryProduct])

    const formateDate=(dateString)=>{
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
    }
    useEffect(()=>{
        let isFiltered = false
        let data = ""
        // console.log("??????????????????????????????????/filterred data",Filters,inventoryData,Filters.status)
        // if(Filters.category && Filters.status){
        //   data = inventoryHistory?.filter((e)=>e.status === Filters.status && e.category === Filters.category)
        //   isFiltered = true
        // }
        // else if(Filters.category){
        //   data = inventoryHistory?.filter((e)=>e.products.category === Filters.category)
        //   isFiltered = true
        // }
        if(Filters.status){
           if( Filters.status=='Expired'){
            data = inventoryHistory?.filter((e)=> new Date( e.expiry_date)<new Date())
           }
           else if(Filters.status=='Available'){
            data = inventoryHistory?.filter((e)=> new Date( e.expiry_date)>new Date())
           }

          
          isFiltered = true
        }
        else if(Filters.search?.trim()){
          data = inventoryHistory.filter((e)=>e.invoice_no.toLowerCase().includes(Filters.search.toLowerCase())  )
        }
        else if( (!Filters.category && !Filters.status) || (!Filters.search  && !Filters.status) ){
          data = null
        }
        if(Filters.search?.trim() && isFiltered && filteredProducts?.length >=1 ){
          data = data.filter((e)=>e.invoice_no.toLowerCase().includes(Filters.search.toLowerCase()) )
        }
        setFilteredProducts(data)
    
      },[Filters])
    
      const handlePlaceholder = (val,placeholder)=>{
        if(!val){
          return <Typography sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</Typography>
        }
        return val
      }
      const calculateExpired=()=>{
        let data = inventoryHistory?.filter((e)=> new Date( e.expiry_date)<new Date())
        return setExpired(data.reduce((a,b)=> parseFloat(a)+parseFloat(b.inward_quantity),0))
      }
      const calculateAvailable=()=>{
        let data = inventoryHistory?.filter((e)=> new Date( e.expiry_date)<new Date())
        return setAvailable(selectedInventoryProduct.available_quantity-data.reduce((a,b)=> parseFloat(a)+parseFloat(b.inward_quantity),0))
      }
      useEffect(()=>{
        calculateExpired()
        calculateAvailable()
      },[inventoryHistory])
      const handleDeleteHistory=(item)=>{
        // console.log(item)

        

      }
      const editEnable=(item)=>{
        dispatch(updateInventoryForm(true))
        // console.log(item)
        const expity_date = dayjs(item.expiry_date)
        // console.log(expity_date)
        const invoice_date = dayjs(item.invoice_date)
        const purchase_date=dayjs(item.purchase_date)

        dispatch(updateInventoryFormData({...item,products:selectedInventoryProduct.id,expiry_date:expity_date,invoice_date:invoice_date,purchase_date:purchase_date}))
      }

    return <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 120px)" }}>
        <Header1 />
        <Box sx={{ display: "flex", marginTop: "120px" }}>
            <AdminSidebar />
            <Box className="background" sx={{ width: { xs: "calc(100% )", sm: "100%" }, p: { xs: "0px", sm: "20px" } }}>
                <Paper sx={{ padding: "24px",display:"flex",flexDirection:"column",gap:"36px" }}>
                    <Box sx={{display:"flex",flexDirection:"column",gap:"16px"}}>
                        <Typography sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: "500", color: "#6F7883" }}>Inventory Management</Typography>
                        <Breadcrumbs separator=">" sx={{pr:"8px"}}>
                            <Link underline="none" href="/stocklist" color="inherit" sx={{fontSize:"14px",fontWeight:400,lineHeight:"20px"}}>Stock List</Link>
                            <Typography sx={{color:"#1099F4",fontWeight:700,fontSize: "14px", lineHeight: "20px"}}>View Batch</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box sx={{display:"flex",alignItem:"center" ,flexDirection:"column " ,gap:"36px"}}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box sx={{ maxWidth: "300px" }}>
                                <Typography sx={{ size: "20px", lineHeighr: "28px", fontWeight: "700",textTransform:"capitalize" }}>
                                    {selectedInventoryProduct.name}
                                </Typography>
                                <Typography sx={{ size: "16px", lineHeighr: "24px", color: "#3F4752" }}>
                                    SKU:{selectedInventoryProduct.sku}
                                </Typography >
                                {/* <Typography sx={{ size: "16px", lineHeighr: "24px", color: "#3F4752" }}>
                                    Syrup
                                </Typography> */}
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "50%", border: "1px solid #E2E8F0", padding: "16px", borderRadius: "5px" }}>

                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <Typography sx={{fontSize:"24px",fontWeight:"500",lineHeight:"32px"}}>{selectedInventoryProduct.inventry_details?.total_quantity}</Typography>
                                    <Typography sx={{fontSize:"14px",fontWeight:"400",lineHeight:"20px",total_quantity:"#3F4752"}}>Total Quantity</Typography>
                                </Box>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <Typography sx={{fontSize:"24px",fontWeight:"500",lineHeight:"32px"}}>{selectedInventoryProduct.inventry_details?.soldout_quantity}</Typography>
                                    <Typography sx={{fontSize:"14px",fontWeight:"400",lineHeight:"20px",color:"#3F4752"}}>Sold Out</Typography>
                                </Box>                       
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <Typography sx={{fontSize:"24px",fontWeight:"500",lineHeight:"32px"}}>{selectedInventoryProduct.inventry_details?.available_quantity}</Typography>
                                    <Typography sx={{fontSize:"14px",fontWeight:"400",lineHeight:"20px",color:"#00C561"}}>Available</Typography>
                                </Box>
                                <Divider orientation="vertical" variant="middle" flexItem />                      
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <Typography sx={{fontSize:"24px",fontWeight:"500",lineHeight:"32px"}}>{selectedInventoryProduct.inventry_details?.expired_quantity}</Typography>
                                    <Typography  sx={{fontSize:"14px",fontWeight:"400",lineHeight:"20px",color:"#FF5449"}}>Expired </Typography>
                                </Box>
                            </Box>
                        </Box>
                        
                        <Box sx={{display:"flex",flexDirection:"column",gap:"20px"}}>
                        <Box sx={{display:"flex",justifyContent:"space-between" }}> 
                        <Stack direction={'row'} gap={'20px'}>
                                        <InputBase inputProps={{ className: 'product-search' }} value={Filters.search} startAdornment={<Search sx={{ color: "#53AEFF",borderRadius:"4px" }} />} placeholder='Batch#' onChange={(e) => { setFilters({ ...Filters, search: e.target.value }) }} sx={{ p: "8px 12px", gap: "8px", border: "1px solid #CBD5E1", borderRadius: "4px", height: "36px", width: "298px" }} />
                                        <Select displayEmpty value={Filters.status} sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "140px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end" } ,"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"} }} onChange={(e) => setFilters({ ...Filters, status: e.target.value })} renderValue={(val) => handlePlaceholder(val, 'Status')}>
                                            <MenuItem value="">All</MenuItem>
                                            <MenuItem value="Available">Available</MenuItem>
                                            <MenuItem value="Expired">Expired</MenuItem>
                                            {/* <MenuItem value="draft">Draft</MenuItem> */}
                                        </Select>
                                        {/* <Select displayEmpty value={Filters.category} placeholder='Category' sx={{ gap: "8px", borderRadius: "8px", height: "36px", width: "140px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end" } }} onChange={(e) => setFilters({ ...Filters, category: e.target.value })} renderValue={(val) => handlePlaceholder(val, 'Category')} >
                                            <MenuItem value="">All</MenuItem>
                                            {
                                                productCategory?.map((type, type_id) => {
                                                    return (
                                                        <MenuItem key={type_id} value={type} sx={{ textTransform: "capitalize" }}>{type}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select> */}
                                    </Stack>
                            <Button variant="contained"sx={{alignSelf:"end",textTransform:"none"}} onClick={()=>dispatch(updateInventoryForm(true))}>
                               + Add Batch
                            </Button>
                        </Box>
                            <TableContainer sx={{ bgcolor: "#fff" ,maxHeight: "50vh"}}>
                                <Table sx={{ minWidth: 650 ,}}  stickyHeader aria-label="sticky table">
                                    <TableHead sx={{ background: "#E2E8F0" }}>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: "600", textAlign: "start" }}>Batch</TableCell>
                                            {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>SKU</TableCell> */}
                                            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Quantity</TableCell>
                                            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Purchased On</TableCell>
                                            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Expired On</TableCell>
                                            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Actions</TableCell>
                                            {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Date Added</TableCell> */}

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(filteredProducts? filteredProducts: inventoryHistory).map((item) => <TableRow sx={{ textDecoration: "none" }} >
                                            <TableCell sx={{ textAlign: "start" }}>{item.batch_number}</TableCell>
                                            {/* <TableCell sx={{ textAlign: "center" }}>{item.prod.sku}</TableCell> */}
                                            <TableCell sx={{ textAlign: "center" }}>{item.inward_quantity}</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>{formateDate(item.purchase_date)}</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}> {formateDate(item.expiry_date)}</TableCell>
                                            <TableCell sx={{ textAlign: "center" }}><Stack direction={'row'} gap={'16px'} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                <EditNote sx={{color:"#53AEFF",cursor:"pointer"}} onClick={()=>{editEnable(item)}}/>
                                                {/* <DeleteOutline sx={{color:"#FF5449",cursor:"pointer"}} onClick={(e)=>handleDeleteHistory(item)}/> */}
                                                </Stack>
                                            </TableCell>
                                            {/* <TableCell sx={{ textAlign:"center" }}>12/12/2024</TableCell> */}
                                        </TableRow>)}



                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>



                </Paper>
            </Box>
        </Box>
        <InventoryModal/>
    </Box>

}

export default Stockbatch