import { Box, Typography } from "@mui/material";
import { landingPage_styles } from "./landing_page";
import ayurvedha from "./landing_assets/ayuir.svg"
import CountUp from 'react-countup';
import { useInView } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import {motion} from "framer-motion"
import { TranslateText } from "../utils/googletranslator";
import { useSelector } from "react-redux";

export function PowerOfAyurvedha() {
    const language=useSelector(state=>state.user.language)
    const isLaptop = useMediaQuery({ minWidth: 900 });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (<Box sx={{...landingPage_styles.ayur.page_prop,...landingPage_styles.positionAnimation,bgcolor:isMobile?"grey.50":"white"}}>
        <Box sx={{ display: "flex", gap: "40px", width: "100%" }}>
            {isLaptop&&
            <Box sx={{width:"35%"}}>
               <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}> <Box component="img" sx={{ maxWidth: "640px",width: "100%" }} src={ayurvedha}></Box></motion.div>
            </Box>}
            <Box sx={{width:{xs:"100%",md:"60%"},display: "flex", flexDirection: "column",gap: "30px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.power.first_heading}>Unveiling the Power of{!isLaptop&&<br/>} Ayurveda</TranslateText></motion.div>
                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.headingBold}>Ancient Wisdom for a Modern World</TranslateText></motion.div>
                    </Box>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.power.content}>5,000-year-old wisdom offers a holistic approach to wellness, balancing mind, body, and spirit. Natural remedies and preventative care are its hallmarks. As dissatisfaction with conventional medicine grows, Ayurveda is emerging as a leader in the booming global wellness market, projected to reach a staggering $4.5 trillion by 2025. This ancient Indian system is the future of traditional medicine.</TranslateText></motion.div>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{ display: "flex",gap: {xs:"16px",md:"80px"}, flexDirection:{xs:"row",md :"column"},overflow:"hidden",overflowX:"scroll",'::-webkit-scrollbar': {display: 'none'} }}>
                        <Box sx={{ display: "flex", gap:{xs:"16px", md:"5%"} }}>
                            <Box  sx={{ boxShadow:!isLaptop&&" 4px 4px 20px 0px #0000001A",minWidth:{xs:"189px",md:"0px"}, maxWidth: "500px", width: "100%",padding:{xs:"12px",md:"0px"},bgcolor:"white"}}>
                               {language=='en'? <TranslateText sx={{ ...landingPage_styles.power.BoxHeading, color: "#53AEFF" }}>₹
                                <CountUp
                                    end={1.2}
                                    duration={2}
                                    decimals={1}
                                    enableScrollSpy
                                    scrollSpyDelay={500}
                                />
                                tr</TranslateText>:<TranslateText sx={{ ...landingPage_styles.power.BoxHeading, color: "#53AEFF" }}>₹1.2 tr</TranslateText>}
                                <TranslateText sx={landingPage_styles.power.BoxSubHeading}>Indian Market Boom</TranslateText>
                                <TranslateText sx={landingPage_styles.power.BoxContent}>Indian Ayurveda market projected size by FY28</TranslateText>
                            </Box>
                            <Box sx={{ boxShadow:!isLaptop&&" 4px 4px 20px 0px #0000001A", minWidth:{xs:"189px",md:"0px"}, maxWidth: "500px", width: "100%",padding:{xs:"12px",md:"0px"},bgcolor:"white"}}>
                           {language=='en'? <TranslateText sx={{ ...landingPage_styles.power.BoxHeading, color: "#00E471" }}><CountUp
                                    end={15}
                                    duration={2}
                                    enableScrollSpy
                                    scrollSpyDelay={500}
                                />% CAGR</TranslateText>:<TranslateText sx={{ ...landingPage_styles.power.BoxHeading, color: "#00E471" }}>15% CAGR</TranslateText>}
                                <TranslateText sx={landingPage_styles.power.BoxSubHeading}>Soaring Growth Trajectory</TranslateText>
                                <TranslateText sx={landingPage_styles.power.BoxContent}>Our customers have reported an average of ~600% ROI.</TranslateText>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex",gap: "5%" }}>
                            <Box sx={{ boxShadow:!isLaptop&&" 4px 4px 20px 0px #0000001A", minWidth:{xs:"189px",md:"0px"}, maxWidth: "500px", width: "100%",padding:{xs:"12px",md:"0px"},bgcolor:"white"}}>
                               {language=='en'?<Box sx={{display:"flex"}}>                             
<TranslateText sx={{ ...landingPage_styles.power.BoxHeading, color: "#F06700" }}>₹</TranslateText> <TranslateText sx={{...landingPage_styles.power.BoxHeading, color: "#F06700"}}><CountUp
            end={628}
            duration={2}
            enableScrollSpy
            scrollSpyDelay={500}
        /></TranslateText><TranslateText sx={{...landingPage_styles.power.BoxHeading, color: "#F06700"}}> mn</TranslateText></Box>:<Box sx={{display:"flex"}}><TranslateText sx={{ ...landingPage_styles.power.BoxHeading, color: "#F06700" }}>₹628mn</TranslateText></Box>} 
                        <TranslateText sx={landingPage_styles.power.BoxSubHeading}>Global Reach</TranslateText>
                        <TranslateText sx={landingPage_styles.power.BoxContent}>Our app has been downloaded over 10k times.</TranslateText>
                            </Box>
                            <Box sx={{ boxShadow:!isLaptop&&" 4px 4px 20px 0px #0000001A", minWidth:{xs:"189px",md:"0px"}, maxWidth: "500px", width: "100%",padding:{xs:"12px",md:"0px"},bgcolor:"white"}}>
                            {language=='en'?<TranslateText sx={{ ...landingPage_styles.power.BoxHeading }}>
                                    <CountUp
                                    end={5000}
                                    duration={2}
                                    enableScrollSpy
                                    scrollSpyDelay={500}
                                />+ yrs</TranslateText>:<TranslateText sx={{ ...landingPage_styles.power.BoxHeading }}>5000+ yrs</TranslateText>}
                                <TranslateText sx={landingPage_styles.power.BoxSubHeading}>Timeless Tradition</TranslateText>
                                <TranslateText sx={landingPage_styles.power.BoxContent}>This emphasizes the enduring legacy and heritage of Ayurveda.</TranslateText>
                            </Box>
                        </Box>
                    </Box></motion.div>
                </Box>
            </Box>

        </Box>
    </Box>)
}