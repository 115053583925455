import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userDataReducer from "./user_reducer"
import sidebar from "./active_bar"
import products from "./products";
import snackbar from "./snackbar";
import orders from "./orders"
import admin from "./admin";
import bundleProduct from "./bundleProduct";
import congrats from "./congrats";
let rootReducer = combineReducers({
    // Add your reducers here
    user: userDataReducer,
    sideBar:sidebar,
    product:products,
    snackBar:snackbar,
    orders:orders,
    admin:admin,
    bundles:bundleProduct,
    congrats:congrats
  });

let store = configureStore({
    reducer:rootReducer,
});

export default store;