import Footer from "../components/footer"
import { Box, Menu, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { useEffect } from "react";
import Header1 from "../components/header1";
import { TranslateText } from "../utils/googletranslator";
export const style = {
    title: { color: "grey.900", fontSize: "24px", fontWeight: "500", letterSpacing: "-1px", fontFamily: "'Poppins',sans-serif", lineHeight: "1.2em", marginBottom: "26px" },
    content: { color: "grey.700", fontSize: "14px", fontFamily: "'Poppins',sans-serif", lineHeight: "26px" }
}

const ShippingPolicy = () => {
    useEffect(()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

    },[])
    const navigate = useNavigate()
    return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", overflow: "hidden" }}>
        <Header1 />
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Box sx={{ marginTop: {xs:"77px",md:"118px"}, height: "150px", bgcolor: "grey.200", width: "100%", display: "flex", justifyContent: "center" }}>
                <Box sx={{ padding: { xs: "16px", sm: "24px" }, display: "flex", alignItems: "center", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", maxWidth: "1440px", width: "100%" }}>
                    <TranslateText sx={{ fontSize: "30px", fontWeight: "500" }}>Shipping Policy</TranslateText>
                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                        <TranslateText component={Link} to="/" sx={{ fontSize: "12px", fontWeight: "400", color: "#ea4d2a", cursor: "pointer","&:hover":{ textDecoration:"none",color:"#ea4d2a"} }}>Home</TranslateText>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>{">"}</Typography>
                        <TranslateText sx={{ fontSize: "12px", fontWeight: "400", color: "grey.600" }}>Shipping Policy</TranslateText>
                    </Box>

                </Box>

            </Box>
            <Box sx={{ maxWidth: "1440px", padding: { xs: "16px", sm: "24px" } }}>
                {/* <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} /> */}
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "70px", flexDirection: "column", gap: "24px" }}>
                    <Box>

                        <TranslateText sx={style.title}>Overview</TranslateText>
                        <TranslateText sx={style.content}>Thank you for shopping with us at <a style={{color:"rgb(39,162,253,1)",cursor:"pointer",textDecoration:"none"}} href="https://mayiiq.com" target="_blank" >https://mayiiq.com</a>. We strive to provide you with the best products and services, ensuring your satisfaction with every purchase. Please take a moment to review our return policy outlined below.</TranslateText>
                    </Box>
                    <Box>
                        <TranslateText sx={style.title}>Order Processing</TranslateText>
                        <TranslateText sx={style.content}>We aim to process orders within 1-3 business days after receiving your confirmation.</TranslateText>
                    </Box>
                    <Box>

                        <TranslateText sx={style.title}>Shipping Methods</TranslateText>
                        <TranslateText sx={style.content}>We offer several shipping methods to cater to your needs and budget. The available options and estimated delivery times will be displayed during checkout and may vary depending on your location and order weight. Here are some common methods:</TranslateText>
                        <br />
                        <ul>
                            <li style={{marginBottom:"8px"}}><TranslateText component={'span'} sx={{...style.title,fontSize:"18px"}}>Standard Shipping :</TranslateText>  <TranslateText> This is the most economical option with an estimated delivery time of 3-5 business days within India.</TranslateText></li>
                            <li><TranslateText component={'span'} sx={{...style.title,fontSize:"18px"}}>Expedited Shipping :</TranslateText><TranslateText>   This option prioritizes faster delivery, typically reaching you within 1-3 business days within India.</TranslateText></li>
                        </ul>
                    </Box>
                    <Box>

                        <TranslateText sx={style.title}>Shipping Costs</TranslateText>
                        <TranslateText sx={style.content}>Shipping costs are calculated based on your order weight, destination, and chosen shipping method. The exact cost will be reflected at checkout before finalizing your purchase.</TranslateText>
                        <br />
                        {/* <TranslateText sx={style.content}>We offer Free Shipping for orders above Rs. 5000</TranslateText>
                        <br /> */}
                        
                    </Box>
                    <Box>

                        <TranslateText sx={style.title}>Tracking Your Order</TranslateText>
                        <TranslateText sx={style.content}>Once your order ships, you will receive a notification email with a tracking number. This allows you to monitor the progress of your delivery.</TranslateText>
                        <br />
                    </Box>
                    <Box>

                        <TranslateText sx={style.title}>International Shipping</TranslateText>
                        <TranslateText sx={style.content}>We currently ship within India.</TranslateText>
                        <br />
                    </Box>
                   
                    <Box>

                        <TranslateText sx={style.title}>Delivery Exceptions</TranslateText>
                        <TranslateText sx={style.content}>While we strive for timely deliveries, unforeseen circumstances like holidays, carrier delays, or natural disasters may cause occasional disruptions. We will keep you informed in such cases.</TranslateText>
                        <br />
                    </Box>

                    <Box>

                        <TranslateText sx={style.title}>Returns and Exchanges</TranslateText>
                        <TranslateText sx={style.content}>Please refer to our separate Returns and Exchanges Policy for information on handling unwanted or damaged items.</TranslateText>
                        <br />
                    </Box>

                    <Box>

                        <TranslateText sx={style.title}>Contact Us</TranslateText>
                        <TranslateText sx={style.content}>Contact us at<a style={{color:"rgb(39,162,253,1)",cursor:"pointer",textDecoration:"none"}} href="mailto:shipment@mayiiq.com" > shipment@mayiiq.com </a>for questions related to shipping policy OR Submit a ticket at <a style={{color:"rgb(39,162,253,1)",cursor:"pointer",textDecoration:"none"}} href="https://help.mayiiq.com" target="_blank" >https://help.mayiiq.com</a> </TranslateText>
                        <br />
                        {/* <TranslateText sx={style.content}>We will replace the item or issue a refund, depending on the availability of the product and your preference.</TranslateText>
                        <br /> */}
                    </Box>
                </Box>
            </Box>
            <Footer />

        </Box>
    </Box>
}

export default ShippingPolicy